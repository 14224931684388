
.Transcript {
  text-align: center;

  h1 {
    text-transform: none;
    margin-bottom: 25px;
  }

  .no-transcript {
    font-weight: 400;
    font-size: 1.1em;
  }

  .loadMessage {
    display: flex;
    justify-content: center;
    margin-top: 20%; 
  }
}

.Transcript-container {
  width: 70%;
  margin: 0 auto;
  text-align: center;

  @include large-device {
    width: 80%;
  }

  @include medium-device {
    width: 100%;
  }
}

.Transcript-content {
  text-align: left;
}

.TranscriptHeading {
  margin-bottom: 15px;

  .no-name {
    margin-bottom: 5px;
    font-weight: 400;
  }

  .name {
    font-weight: 500;
    font-size: 1.1em;
    margin-bottom: 5px;
  }

  .certificates {
    font-weight: 400;
    color: $primary-color;

    button {
      padding: 0px;
      text-transform: none;
      color: $link-color;
    }

    .Icon {
      margin-right: 5px;
    }
  }

  .certificates-display {
    font-weight: 400;

    h5 {
      font-family: $primary-font;
      font-size: 1em;
      font-weight: 500;
      text-transform: none;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    .certificate {
      margin-bottom: 7px;
    }
  }
}

.Transcript-recordList {
  border-top: 1px solid $light-border-color;
  padding-top: 35px;
}

.Transcript-recordList.no-records {
  border-top: none;
}

.TranscriptRecords {
  margin-bottom: 55px;

  h3 {
    font-size: 1.1em;
    font-weight: 500;
    font-family: $primary-font;
    text-transform: none;
    margin-bottom: 20px;
  }

  .record {
    margin-bottom: 35px;
  }

  .title {
    margin-bottom: 7px;

    .Icon {
      margin-right: 5px;
    }

    span {
      font-size: 1.05em;
      font-weight: 500;
      color: $darker-text-color;
    }

    a {
      text-decoration: none;
      font-size: 1.05em;
      font-weight: 500;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  .details {
    margin-top: -2px;

    @include flex-row();
    align-items: center;

    @include tablet-device {
      display: block;
      margin-top: 0px;
    }
  }

  .progress {
    width: 250px;
    margin-right: 30px;
    font-size: 0.95em;

    @include tablet-device {
      margin-bottom: 2px;
    }

    .label {
      font-weight: 500;
      margin-right: 10px;
    }

    .text {
      font-weight: 400;
    }
  }

  .actions {
    .label {
      font-weight: 500;
      font-size: 0.95em;
      margin-right: 15px;
    }

    button {
      padding: 0px;
      text-transform: none;
      margin-right: 25px;
      font-size: 0.95em;
      color: $secondary-color;
    }
  }

  .description, .date {
    margin-bottom: 5px;

    .label {
      font-weight: 500;
      font-size: 0.95em;
      margin-right: 10px;
    }

    .text {
      font-weight: 400;
      font-size: 0.95em;
    }
  }
}

.TranscriptRecords.no-records {
  display: none;
}

.TranscriptAssessments {
  min-height: 60px;
  padding-left: 10px;
  padding-top: 5px;

  .LoadingMessage {
    font-size: 0.95em;
  }

  .assessment-record {
    margin-bottom: 2px;

    @include small-device {
      margin-bottom: 10px;
    }
  }

  .title {
    display: inline-block;
    width: 175px;
    margin-right: 5px;
    font-weight: 400;

    @include small-device {
      width: 60%;
    }
  }

  .title.link {
    color: $link-color;
    cursor: pointer;
  }

  .score {
    display: inline-block;
    width: 75px;
    margin-right: 5px;
    font-weight: 400;

    @include small-device {
      width: 30%;
    }
  }

  .no-records {
    font-weight: 400;
  }
}

.TranscriptAssessments-popup {
  .LoadingMessage {
    font-size: 0.95em;
    text-align: center;
  }
}

.TranscriptRecords-popup {
  font-weight: 400;

  .what {
    margin-bottom: 10px;
  }

  .course-title {
    font-weight: 500;
    margin-bottom: 35px;
  }

  .consequences {
    margin-bottom: 10px;
  }

  .sure {
    font-weight: 500;
    color: $secondary-color;
  }

  .content-actions {
    .keep {
      margin-right: 20px;

      @include phone-device {
        margin-right: 0px;
      }
    }

    .remove {
      color: $secondary-color;
    }
  }
}