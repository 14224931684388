
.AnnualReport-section.staff.top {
  background-image: url('https://instituteofcatholicculture.net/wp-content/uploads/2022/11/ar-light-blue-decoration.png');
  background-repeat: repeat-y;
  background-size: 300px;

  @include medium-device {
    background-size: 250px;
  }

  @include tablet-device {
    background-size: 200px;
  }

  @include phone-device {
    background: none;
  }
}

.AnnualReport-section.staff.top .AnnualReport-content {
  padding-top: 100px;
  padding-bottom: 100px;

  @include medium-device {
    padding-top: 80px;
  }

  @include breakpoint(680) {
    padding-bottom: 350px;
  }

  @include small-device {
    padding-bottom: 50px;
  }

  .SectionHeader {
    h3 {
      color: $ar-dark-blue;
    }
  }

  .staff-cards {
    display: grid;
    grid-template-columns: 22% 22% 22% 22%;
    grid-gap: 3.5%;
    width: 100%;
    margin-bottom: 100px;

    @include medium-device {
      grid-template-columns: 32% 32% 32%;
      grid-gap: 2%;
      margin-bottom: 150px;
    }

    @include breakpoint(680) {
      grid-template-columns: 48% 48%;
      grid-gap: 4%;
    }

    @include small-device {
      display: flex;
      flex-direction: column;
      margin-bottom: 0px;
    }

    .ImageStoryCard {
      margin-bottom: -10px;
    }

    .ImageStoryCard, .ImageStoryCard-overlay, .ImageStoryCard-story {
      height: 411px;
      width: 240px;
      aspect-ratio: inherit;

      @include large-device {
        height: 394px;
        width: 230px;
      }

      @include desktop-device {
        height: 360px;
        width: 210px;
      }

      @include medium-device {
        height: 463px;
        width: 270px;
      }

      @include breakpoint(880) {
        height: 394px;
        width: 230px;
      }

      @include tablet-device {
        height: 360px;
        width: 210px;
      }

      @include breakpoint(680) {
        height: 463px;
        width: 270px;
      }

      @include small-device {
        height: inherit;
        width: 100%;
        aspect-ratio: 1.72;
        margin-bottom: 40px;
      }
    }

    .ImageStoryCard-image {
      @include small-device {
        display: none;
      }
    }

    .ImageStoryCard-image-alt {
      @include small-device {
        position: absolute;
        display: block;
      }
    }
  }
}

.AnnualReport-section.staff.bottom-carousel {
  background-color: $ar-medium-blue;
  padding-top: 50px;
  padding-bottom: 50px;

  h3 {
    font-family: $alt-heading-font;
    font-weight: 600;
    font-size: 1.6em;
    color: $constrast-light-color
  }

  h4 {
    font-family: $alt-heading-font;
    font-weight: 500;
    font-size: 1.6em;
    color: $constrast-light-color;
  }

  .nav-prev, .nav-next {
    .nav-button-bg {
      background-color: $constrast-light-color;
    }

    .Icon {
      color: $ar-dark-blue;
    }
  }

  .BasicCarouselCard-container {
    h5 {
      font-weight: 500;
      color: $constrast-light-color;
    }

    p {
      color: $constrast-light-color;
    }
  }
}