
.snapshot-top-marker {
  height: 0px;
  visibility: hidden;
}

.AnnualReport-section.snapshot {
  background-image: url('https://instituteofcatholicculture.net/wp-content/uploads/2022/11/ar-light-blue-decoration.png');
  background-repeat: repeat-y;
  background-size: 300px;

  @include medium-device {
    background-size: 250px;
  }

  @include tablet-device {
    background-size: 200px;
  }

  @include phone-device {
    background: none;
  }
}

.AnnualReport-section.snapshot .AnnualReport-content {
  padding-top: 130px;
  padding-bottom: 150px;

  @include phone-device {
    padding-top: 110px;
  }

  .SectionHeader {
    h3 {
      color: $ar-dark-blue;
    }
  }

  .stat-cards {
    @include flex-row();
    justify-content: space-between;
    flex-wrap: wrap;

    @include small-device {
      @include flex-col();
      align-items: center;
      justify-content: inherit;
      flex-wrap: inherit;
    }
  }

  .NumericFigure {
    margin-bottom: 45px;
    width: 48%;

    @include small-device {
      width: 90%;
    }

    @include phone-device {
      width: 100%;
    }

    .NumericFigure-inner {
      width: 100%;
    }

    .number {
      font-family: $alt-heading-font;
      font-weight: 500;
      font-size: 5.5em;
      margin-top: -20px;
    }
  }

  .countless-container {
    text-align: center;
    margin-top: -25px;

    @include small-device {
      margin-top: 0px;
    }

    .AnimatedNumber {
      font-family: $alt-heading-font;
      font-weight: 500;
      font-size: 6em;
      color: $ar-dark-blue;

      @include small-device {
        font-size: 4em;
      }

      @include small-phone-device {
        font-size: 3em;
      }
    }

    .countless {
      font-family: $alt-heading-font;
      font-weight: 500;
      text-transform: uppercase;
      font-size: 6em;
      color: $ar-dark-blue;

      @include small-device {
        font-size: 3.5em;
      }

      @include small-phone-device {
        font-size: 2.8em;
      }
    }

    .subtext {
      font-size: 1.2em;
      font-weight: 500;
      color: $ar-dark-blue;

      @include small-device {
        font-size: 1.1em;
      }
    }
  }
}

/*
Interstitial
*/

.AnnualReport-section.interstitial.snapshot {
  background-color: $ar-dark-blue;
  @include angle('before', false, 2.0deg);
  @include angle('after', false, 2.0deg);
  background-image: none;
  z-index: 10;
}

.AnnualReport-section.interstitial.snapshot .AnnualReport-content {
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.AnnualReport-section.interstitial.snapshot .AnnualReport-content .container {
  .Quote {
    padding-top: 60px;
    
    .icon {
      svg {
        path {
          fill: $ar-light-blue;
        }
      }
    }
  }
}