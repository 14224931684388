.TitleWithSubtextContainer {
  padding-left: 100px;
  padding-right: 100px;
  text-align: center;

  @include large-device {
    padding-left: 60px;
    padding-right: 60px;
  }

  @include desktop-device {
    padding-left: 25px;
    padding-right: 25px;
  }

  @include phone-device { 
    padding-left: 15px;
    padding-right: 15px;
  }

  h1, h3 {
      font-size: 1.3em;
      margin-bottom: 30px;
      letter-spacing: 2px;
      max-width: $free-floating-max-width;
      margin: 0 auto;
      text-align: center;
  }

  div.section-description {
      font-size: 1.2em;
      font-weight: 400;
      margin-bottom: 60px;
      max-width: $free-floating-max-width;
      margin: 0 auto;
      text-align: center;
    }
    
    div.section-description-no-divider {
      font-size: 1.2em;
      font-weight: 400;
      max-width: $free-floating-max-width;
      margin: 25px auto 60px;
      text-align: center;
    }

    .divider-bar {
      margin: 0 auto;
      width: 100px;
      margin-top: 20px;
      margin-bottom: 20px;
      border-bottom: 2px solid $primary-light-color;
    }
 
    .divider-bar-hidden {
      margin: 0 auto;
      width: 100px;
      margin-top: 20px;
      margin-bottom: 20px;
      border-bottom: 2px solid $primary-light-color;
      display: none;
    }
}