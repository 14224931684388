
.Instructors-container {
  text-align: left;

  h1, h2 {
    font-size: 1.4em;
    letter-spacing: 2px;
    color: $heading-color;
    margin-top: 30px;
    margin-bottom: 80px;
    text-align: center;
  }

  .Instructor-list {
    @include flex-row();
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .Instructor {
    @include flex-row();
    width: 49%;
    padding: 5px 0px;
    margin-bottom: 20px;
    cursor: pointer;

    @include tablet-device {
      width: 100%;
    }

    .image {
      width: 60px;
      height: 60px;
      margin-right: 15px;
  
      img {
        height: 60px;
        width: 60px;
      }
    }

    .info {
      @include flex-col();
      margin-top: 5px;
    }
  
    .name {
      font-size: 1.1em;
      font-weight: 500;

      a {
        text-decoration: none;
      }
    }
  }

  .Instructor:hover {
    background-color: $offset-background-color;
  }
}

.Instructor-container {
  text-align: left;

  .heading {
    @include flex-row();
    margin-bottom: 80px;

    @include small-device {
      margin-bottom: 40px;
    }

    .thumbnail {
      height: 96px;
      width: 96px;
      margin-right: 20px;

      @include small-device {
        height: 70px;
        width: 70px;
      }
    }

    img {
      height: 96px;
      width: 96px;

      @include small-device {
        height: 70px;
        width: 70px;
      }
    }

    h2 {
      color: $primary-color;
      font-size: 1.5em;
      margin-bottom: 20px;

      @include small-device {
        margin-top: 5px;
        margin-bottom: 0px;
      }
    }

    .bio {
      @include small-device {
        display: none;
      }
    }
  }

  .bio-alt {
    display: none;

    @include small-device {
      display: block;
      margin-bottom: 50px;
    }
  }

  .EventList h2 {
    font-size: 1.3em;
  }

  .EventCard .instructor {
    display: none;
  }
}