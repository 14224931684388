.Magdala-Landing {
  text-align: center;
    
    .container {
      padding-left: 100px;
      padding-right: 100px;
      text-align: center;
  
      @include large-device {
        padding-left: 60px;
        padding-right: 60px;
      }
  
      @include desktop-device {
        padding-left: 25px;
        padding-right: 25px;
      }
  
      @include phone-device { 
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  
    .container-content {
      max-width: 1240px;
      margin: 0 auto;
      text-align: left;
    }
}

.Magdala-About {
  margin-top: 60px;

  iframe {
    height: 360px;
    width: 640px;
    margin-top: 50px;
    margin-bottom: 80px;
    
    @include large-device {
      max-width: 70%;
      margin-top: 50px;
    }
    
    @include desktop-device {
      max-width: 70%;
      margin-top: 50px;
    }
    
    @include medium-device {
      max-width: 70%;
      margin-top: 35px;
      height: 320px; 
      margin-bottom: 60px;
    }
    
    @include small-device {
      max-width: 80%;
      margin-top: 35px;
      margin-bottom: 60px;
      height: 200px;
    }
    
    @include phone-device {
      max-width: 90%;
      margin-top: 35px;
      margin-bottom: 40px;
      height: 180px;
    }
  }
}


.Magdala-Actions .introduction {
  @include flex-col();
  padding-top: 100px;
  padding-bottom: 50px;
  margin-bottom: 120px;
  background-color: $pitch-bg-color;
  color: $constrast-light-color;
  width: 100%;
  @include angle('before', true, 1.5deg);
  @include angle('after', true, 1.5deg);

  .cards {
    @include flex-row();
    display: flex;
    flex-direction: row;
    justify-content: center;

    @include medium-device {
        flex-direction: column;
        text-align: column;
    }

    @include phone-device {
      margin-top: 20px; 
      margin-bottom: 20px; 
    }
  }

  .card {
    width: 340px;
    margin-bottom: 70px;
    cursor: pointer;

    @include large-device {
      max-width: 300px;
    }

    @include medium-device {
      max-width: 340px;
      margin: 0 auto;
      margin-bottom: 70px;
      text-align: left;
    }

    @include tablet-device {
      max-width: 320px;
      margin: 0 auto;
      margin-bottom: 70px;
      text-align: left;
    }

    .feature-image {
      width: 100%;
      height: 190px;
      background-color: $default-feature-bg;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }

    h4 {
      padding: 8px;
      color: $primary-color;
      letter-spacing: 0.5px;
    }

    p {
      padding: 0px 8px;
      height: 80px;

      @include large-device {
        height: 100px;
      }

      @include medium-device {
        height: 120px;
      }

      @include tablet-device {
        height: inherit;
        padding-bottom: 30px
      }
    }

    button {
      font-size: 0.85em;
      color: $link-color;
      padding-left: 8px;
    }
  }

  .card.register, .card.program {
    margin-right: 100px;

    @media only screen and (max-width: 1300px) {
      margin-right: 50px;
    }

    @include large-device {
      margin-right: 30px;
    }

    @include tablet-device {
      margin-right: 0px;
      margin: 0 auto;
      margin-bottom: 70px;
    }
  }

  .Magdala-Student-Access-Callout {
    padding: 0px 30px 0px 30px;
    margin-bottom: -60px;
    
    
    .callout {
      display: inline-block;
      background-color: $primary-color;
      padding: 40px;
      margin-top: -30px;
      margin-bottom: -50px;
      border-radius: 3px;
    }
  
    h3 {
      color: $tertiary-light-color;
      font-size: 1.2em;
      font-family: $primary-font;
      margin-bottom: 30px;
      text-align: center;
      text-transform: inherit;
  
      span {
        color: $primary-light-color;
      }
    }
  
    div.join-button {
      text-align: center;
    }
  
    button {
      color: $constrast-light-color;
      border-color: $constrast-light-color;
      margin: 0 auto;
    }
  }
}


.Magdala-Community {
  margin-top: 150px;

  .Magdala-Map {
    margin-top: 70px;
    margin-bottom: 90px;
    height: 50vh;
    min-width: 60%;
    border: none;

    @include large-device {
      width: 70%;
    }

    @include desktop-device {
      width: 80%;
    }

    @include medium-device {
      width: 85%;
    }

    @include small-device {
      width: 90%;
    }

    @include phone-device {
      width: 100%;
    }

  }
}

.Magdala-Landing-Pitch {
    padding: 70px 30px 50px 30px;
    margin-bottom: 60px;
    background-color: $participation-bg-color;
    color: $tertiary-light-color;
    @include angle('before', true, 1.5deg);
    @include angle('after', true, 1.5deg);

    .container-content {
      text-align: center;
    }
  
    h3 {
      color: $tertiary-light-color;
    }
  
    button {
      color: $constrast-light-color;
      border-color: $constrast-light-color;
      margin: 30px 0;
    }

    button:hover {
      border-color: $constrast-light-color;
    }
}

.Magdala-Home-testimonials {
  padding: 60px 30px;

  > div {
    @include flex-col();
    max-width: 800px;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  svg {
    margin: 0 auto;
    margin-bottom: 30px;
    width: 50px;
    height: auto;
  }

  .quote {
    font-size: 1.1em;
  }

  p {
    font-weight: 400;
    margin-bottom: 20px;
    font-style: italic;
    font-family: $heading-font;
    font-size: 1.3em;
    color: $primary-color;

    @include tablet-device {
      padding: 0px
    }
  }

  p.name {
    font-weight: 400;
    font-size: 1em;
    font-family: $primary-font;
    font-style: normal;
    color: inherit;
  }
}