
.Curriculum-container {
  text-align: center;

  h1 {
    font-size: 1.4em;
    margin-bottom: 30px;
  }

  .heading {
    @include flex-row();
    text-align: left;

    @include tablet-device {
      display: block;
    }

    .brochures {
      flex: 1;
      text-align: right;

      @include tablet-device {
        display: none;
      }
    }

    a {
      text-transform: inherit;
      margin-right: 20px;
      font-size: 0.9em;
    }
  }

  .curriculum-description {
    @include flex-row();
    text-align: left;
    margin-top: 20px;
    margin-bottom: 60px;

    @include desktop-device {
      display: block;
    }

    .text {
      flex: 1;
      margin-right: 30px;

      @include desktop-device {
        margin-right: 0px;
      }

      @include small-device {
        display: none;
      }

      h2 {
        margin-bottom: 20px;
      }
    }

    .video {
      width: 40%;
      margin-right: 20px;

      @include large-device {
        margin-top: 40px;
      }

      @include desktop-device {
        display: none;
      }
    }

    .video.displayed {
      @include desktop-device {
        display: block;
        width: 100%;
        margin-right: 0px;
      }
    }

    .welcome-video-btn {
      display: none;
      text-align: center;

      @include desktop-device {
        display: block;
        margin-top: 20px;
      }

      @include small-device {
        text-align: left;
      }

      button {
        text-transform: inherit;
        font-size: 0.9em;

        @include small-device {
          background-color: #fff;
          color: #3E4F7D;
          box-shadow: none;
          border: 1px solid #3E4F7D;
        }
      }
    }

    .welcome-video-btn.not-displayed {
      display: none;
    }
  }

  h2 {
    font-family: $primary-font;
    font-size: 1.15em;
    text-transform: none;
    margin-bottom: 40px;
    text-align: left;

    span {
      display: none;

      @include tablet-device {
        display: inline;
        font-size: 0.9em;
        font-weight: 300;
      }
    }
  }
}

.Curriculum {
  text-align: left;

  .tab-content {
    flex: 1;
    padding: 30px 0px;

    @include tablet-device {
      padding: 30px 0px;
    }

    .quarter-description {
      margin-bottom: 30px;
    }

    .event {
      margin-bottom: 30px;
    }

    h3 {
      font-size: 1.05em;
      margin-bottom: 5px; 
    }

    .title {
      font-weight: 400;
      font-size: 1.05em;
      margin-bottom: 5px;
    }
  }
}