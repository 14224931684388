@import './styles/variables';
@import './styles/mixins';
@import './styles/foundation';
@import './styles/standard';

@import './styles/components/common';
@import './styles/components/authentication';
@import './styles/components/content';
@import './styles/components/member';
@import './styles/components/magdala';
@import './styles/components/layout/Header';
@import './styles/components/layout/Toolbar';
@import './styles/components/layout/MagdalaToolbar'; 
@import './styles/components/layout/LazyLoad';
@import './styles/components/layout/PageMessage';
@import './styles/components/layout/PageBanner';
@import './styles/components/layout/PrivacyPrompt';
@import './styles/components/layout/SystemPopup';
@import './styles/components/layout/menus/ExploreMenu';
@import './styles/components/layout/menus/HeaderMenu';
@import './styles/components/layout/menus/StickyBottomMenu';
@import './styles/components/layout/Footer';
@import './styles/components/Home';


main {
  margin: 0 auto;
  padding: 50px 100px;
  min-height: 350px;
  max-width: 1240px;
  text-align: left;

  @include large-device { 
    padding: 50px 60px;
  }

  @include desktop-device {
    padding: 40px 25px;
  }

  @include phone-device { 
    padding: 30px 15px;
  }

  .MainContent-container {
    text-align: center;
  }
}

.App-floating-toolbar {
  padding: 15px 100px;

  @include large-device { 
    padding: 15px 60px;
  }

  @include desktop-device {
    padding: 15px 25px;
  }

  @include phone-device { 
    padding: 15px 15px;
  }
}

.App {
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: center;
}

.App {
  .MuiButtonBase-root.Mui-checked {
    color: $success-color;
  }
}

.App.display-noboxing {
  main {
    padding: 0px;
    margin: 0px;
    max-width: 100%;
    text-align: center;
  }  
}

.App.home {
  main {
    padding: 0px;
    margin: 0px;
    max-width: 100%;
  }
}

#NotFound {
  text-align: center;
  
  .NotFound-aesthetic {
    display: flex;
    justify-content: center;
    align-items: center;

    .Icon {
      margin: 0px;
      margin-right: 20px;

      svg {
        color: $secondary-color;
      }
    }
  }

  .NotFound-aesthetic-content {
    padding-bottom: 5px;
    color: $secondary-color;

    .NotFound-404 {
      font-size: 4em;
    }

    .NotFound-message {
      font-size: 1.5em;
      margin-top: -10px;
    }
  }

  .NotFound-descriptive {
    margin-top: 30px;
    font-size: 2em;
    font-weight: 300;
  }
}

.NotFound-migrated-suggestion {
  margin-top: 30px;
  font-size: 1.2em;
  font-weight: 400;
}

.NotFound-navigation {
  margin-top: 45px;
}

