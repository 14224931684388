.AnnualReport-section.communities {
  background-image: url('https://instituteofcatholicculture.net/wp-content/uploads/2022/11/ar-light-blue-decoration.png');
  background-repeat: repeat-y;
  background-size: 300px;

  @include medium-device {
    background-size: 250px;
  }

  @include tablet-device {
    background-size: 200px;
  }

  @include phone-device {
    background: none;
  }
}

.AnnualReport-section.communities .AnnualReport-content {
  padding-top: 100px;
  padding-bottom: 200px;

  .SectionHeader {
    margin-bottom: 100px;

    @include medium-device {
      margin-bottom: 60px;
    }

    h3 {
      color: $ar-light-blue;
    }
  }

  h4.sub-head1 {
    font-family: $alt-heading-font;
    font-size: 1.8em;
    font-weight: 600;
    margin-top: -40px;
  }

  .ImageQuoteCard {
    margin-bottom: 90px;

    .ImageQuoteCard-content {
      border-color: $ar-vlight-blue;
      background-color: $constrast-light-color;
    }

    svg {
      .cls-1 {
        fill: $ar-light-blue;
      } 
    }

    .Quote {
      .text {
        font-family: $alt-heading-font;
        font-size: 1.3em;
        color: $ar-dark-blue;
      }

      .name {
        font-size: 0.95em;
        color: $ar-dark-blue;
      }
    }
  }

  h4.sub-head2 {
    font-family: $alt-heading-font;
    font-size: 1.8em;
    font-weight: 600;
    margin-bottom: 30px;
  }

  .StoryNavigator-nav {
    .title {
      background-color: $ar-light-blue;
    }

    .active .title {
      background-color: $ar-medium-blue;
    }

    .nav-item p {
      font-size: 1.1em;
    }

    .nav-item:hover .title {
      background-color: $ar-medium-blue;
    }
  }

  .StoryNavigator-cards {
    background-color: $ar-vlight-blue;
    margin-top: -50px;
    padding-bottom: 20px;

    h5 {
      color: $ar-dark-blue;
      font-size: 1.3em;
      padding-top: 90px;

      @include tablet-device {
        font-size: 1.2em;
      }
    }
  }

  .StoryCard {
    .Quote {
      width: 100%;
      padding-top: 30px;
      padding-bottom: 30px;

      .text {
        font-family: $alt-heading-font;
        font-size: 1.35em;
        font-weight: 600;
        color: $ar-dark-blue;

        @include tablet-device {
          font-size: 1.2em;
        }
      }

      .name {
        font-family: $primary-font;
        font-size: 1em;
        font-weight: 500;
        color: $ar-dark-blue;

        @include tablet-device {
          font-size: 0.95em;
        }
      }

      svg {
        width: 45px;

        @include tablet-device {
          width: 35px;
        }

        .cls-1 {
          fill: $ar-light-blue;
        }
      }
    }

    p {
      font-weight: 400;
    }
  }
}

/*
Interstitial
*/

.AnnualReport-section.interstitial.communities {
  background-color: $ar-light-blue;
  @include angle('before', false, 2.0deg);
  @include angle('after', false, 2.0deg);
}

.AnnualReport-section.interstitial.communities .AnnualReport-content {
  padding-top: 0px;
  padding-bottom: 0px;
}