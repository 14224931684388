

/*
Carousel
*/

.Carousel-heading {
  margin-bottom: 40px;

  h3 {
    font-size: 1.6em;
    margin-bottom: 7px;
  }

  h4 {
    font-size: 1.4em;
    font-style: italic;
    text-transform: none;
  }
}

.Carousel-container {
  @include flex-row();
  align-items: center;

  @include small-device {
    flex-direction: column;
  }

  .nav-prev, .nav-next {
    width: 70px;
    text-align: center;

    .nav-button-bg {
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $primary-color;
      height: 2.5em;
      width: 2.5em;
      border-radius: 50%;
      cursor: pointer;
    }

    .Icon {
      font-size: 1.4em;
      color: $constrast-light-color;
    }
  }

  .nav-prev.disabled, .nav-next.disabled {
    opacity: 0.4;
  }

  .nav-prev {
    .Icon {
      padding-right: 4px;
    }
  }

  .nav-next {
    .Icon {
      padding-left: 4px;
    }
  }

  .nav-prev.large-display, .nav-next.large-display {
    @include small-device {
      display: none;
    }
  }

  .nav-small-display {
    display: none;

    @include small-device {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 100%;
      margin-top: 50px;
    }

    .nav-prev {
      width: 49%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      > div {
        margin: inherit;
        margin-right: 20px;
      }
    }

    .nav-next {
      width: 49%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-left: 20px;

      > div {
        margin: inherit;
      }
    }
  }

  .Carousel-cards {
    @include flex-row();
    flex: 1;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;

    @include small-device {
      width: 100%;
    }
  }

  .Carousel-cards {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .Carousel-cards::-webkit-scrollbar {
      display: none;
  }
}

.BasicCarouselCard {
  scroll-snap-align: start end;
  width: 100%;
}

.BasicCarouselCard-container {
  @include flex-row();

  @include medium-device {
    display: inherit;
  }

  .image {
    width: 40%;
    margin-right: 30px;

    @include medium-device {
      width: 500px;
      margin: 0 auto;
      margin-bottom: 30px;
    }

    @include tablet-device {
      width: 90%;
    }

    @include small-device {
      width: 100%;
    }

    img {
      width: 95%;
      height: auto;

      @include small-device {
        width: 100%;
      }
    }
  }

  .details {
    @include flex-col();
    flex: 1;

    h5 {
      font-family: $primary-font;
      font-size: 1.3em;
      font-weight: 400;
      margin-bottom: 7px;

      @include phone-device {
        font-size: 1.2em;
      }
    }

    p {
      padding-right: 20px;

      @include medium-device {
        padding-right: 0px;
      }

      @include phone-device {
        font-size: 0.95em;
      }
    }
  }
}


/*
Image/Story Card
*/

.ImageStoryCard {
  height: 100%;
  cursor: pointer;
  overflow: hidden;

  h4 {
    font-family: $heading-font;
    text-transform: uppercase;
  }

  h5 {
    font-family: $primary-font;
    font-weight: 500;
    text-transform: none;
  }

  .ImageStoryCard-button {
    position: absolute;
    right: 10px;
    top: 10px;

    .MuiIconButton-root {
      padding: 3px;
    }

    .icon-container {
      height: 25px;
      width: 25px;
    }

    .Icon > div {
      font-size: 1em;
      height: 25px;
      width: 25px;

      svg {
        height: 25px;
        width: 25px;
        vertical-align: inherit;
      }
    }
    
    button {
      background-color: $constrast-light-color;
      color: $constrast-text-color;
      box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.1);
    }
  }

  .ImageStoryCard-container {
    position: relative;
    overflow: hidden;
    height: 100%;
  }

  .ImageStoryCard-image {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 100%;
    aspect-ratio: 0.65;
    transition: all .5s;
    transform: scale(1, 1);
  }

  .ImageStoryCard-image-alt {
    display: none;
    bottom: 0px;
    left: 0px;
    height: 100%;
    aspect-ratio: 1.72;
    transition: all .5s;
    transform: scale(1, 1);
  }

  .ImageStoryCard-container:hover img {
    transform: scale(1.2, 1.2);
  }

  .ImageStoryCard-overlay {
    position: absolute;
    left: 0px;
    height: 100%;
    aspect-ratio: 0.65;
    background-color: rgba(0,0,0,.3);

    .initial-content {
      position: absolute;
      left: 0px;
      bottom: 10px;
      padding: 10px;

      h4 {
        color: $constrast-light-color;
        font-size: 1.3em;
        margin-bottom: 5px;
      }
    
      h5 {
        color: $constrast-light-color;
        font-size: 0.9em;
      }
    }
  }

  .ImageStoryCard-overlay:hover {
    background-color: rgba(0,0,0,.6);
  }

  .ImageStoryCard-story {
    position: absolute;
    left: 100%;
    height: 100%;
    aspect-ratio: 0.65;
    background-color: $primary-light-bg;
    transition: left 0.5s 0s;
    overflow-y: scroll;
    overflow-x: hidden;

    .ImageStoryCard-story-container {
      padding: 10px;
    }

    h4 {
      color: $constrast-text-color;
      font-family: $primary-font;
      font-size: 1.05em;
      font-weight: 600;
      margin-bottom: 5px;
      margin-top: 40px;
    }
  
    h5 {
      color: $constrast-text-color;
      font-size: 0.9em;
    }

    p {
      margin-top: 10px;
      font-size: 0.9em;
      font-weight: 400;
      line-height: 130%;
    }
  }

  .ImageStoryCard-container.story-open .ImageStoryCard-story {
    left: 0;
  }
}

/*
Image Quote Card
*/

.ImageQuoteCard {
  margin-top: 70px;
  margin-bottom: 70px;

  .ImageQuoteCard-container {
    width: 100%;
    display: grid;
    justify-items: start;
    align-items: start;

    @include medium-device {
      display: flex;
      flex-direction: column;
    }
  }

  .ImageQuoteCard-content {
    border: 7px solid $light-border-color;
    width: 100%;
    grid-column-start: 1;
    grid-row-start: 1;
    z-index: 1;

    @include medium-device {
      margin-top: -80px;
      box-sizing: border-box;
    }

    .ImageQuoteCard-content-inner {
      @include flex-row();
    }
  }

  .icon {
    display: inline-block;
    height: 70px;
    width: 90px;
    background-color: $constrast-light-color;
    @include flex-row();
    align-items: center;
    justify-content: center;
    
    svg {
      width: 60px;
      height: auto;
      
      .cls-1 {
        fill: $darker-text-color;
      }
    }
  }

  .icon.right {
    display: none;
  }

  .Quote {
    margin: inherit;
    margin-top: 30px;
    padding-top: 20px;
    padding-bottom: 50px;
    width: 45%;

    @include medium-device {
      width: 100%;
      padding-top: 70px;
      padding-bottom: 40px;
    }
  }

  .image {
    width: 47%;
    grid-column-start: 1;
    grid-row-start: 1;
    z-index: 10;

    @include medium-device {
      width: 100%;
    }

    img {
      width: 100%;
      height: auto;

      @include medium-device {
        width: 75%;
        text-align: center;
      }
    }
  }
}

.ImageQuoteCard.left-orientation {
  @include flex-row();
  justify-content: left;
  padding-left: 14px;

  @include medium-device {
    padding-left: 0px;
  }

  .ImageQuoteCard-container {
    justify-items: end;
    align-items: start;
    padding-right: 50px;

    @include medium-device {
      padding-right: 0px;
    }
  }

  .icon {
    margin-top: -20px;
    margin-left: -20px;
  }

  .Quote {
    @include medium-device {
      padding-right: 20px;
    }
  }

  .image {
    margin-top: -30px;
    margin-right: -50px;

    @include medium-device {
      margin-top: 0px;
      margin-right: 0px;
      text-align: right;
    }

    img {
      @include medium-device {
        margin-right: 50px;
      }

      @include small-device {
        margin-right: 20px;
      }
    }
  }
}

.ImageQuoteCard.right-orientation {
  @include flex-row();
  justify-content: left;
  padding-right: 14px;

  @include medium-device {
    padding-right: 0px;
  }

  .ImageQuoteCard-container {
    justify-items: start;
    align-items: start;
    padding-left: 50px;

    @include medium-device {
      padding-left: 0px;
    }
  }

  .ImageQuoteCard-content-inner {
    padding-left: 41%;

    @include medium-device {
      padding-left: 0px;
    }
  }

  .icon {
    margin-top: -20px;
    margin-left: -25px;
    padding-left: 25px;
  }

  .icon.left {
    @include medium-device {
      display: none;
    }
  }

  .icon.right {
    @include medium-device {
      display: inherit;
      margin-left: 0px;
      margin-right: -25px;
      padding-left: 0px;
    }

    svg {
      @include medium-device {
        transform: scale(-1,1);
      }
    }
  }

  .Quote {
    width: inherit;
    padding-right: 20px;
    flex: 1;

    @include medium-device {
      padding-left: 20px;
      padding-right: 0px;
    }
  }

  .image {
    margin-top: -30px;
    margin-left: -50px;

    @include medium-device {
      margin-top: 0px;
      margin-left: 0px;
      text-align: left;
    }

    img {
      @include medium-device {
        margin-left: 50px;
      }

      @include small-device {
        margin-left: 20px;
      }
    }
  }
}


/*
Quote
*/
.Quote {
  @include flex-row();
  margin: 0 auto;
  width: 65%;
  padding-top: 80px;
  padding-bottom: 50px;

  @include tablet-device {
    width: 80%;
  }

  @include small-device {
    width: 95%;
  }

  .icon {
    margin-top: -25px;
    margin-right: 10px;

    @include tablet-device {
      margin-right: 5px;
    }

    svg {
      width: 60px;
      height: auto;

      @include tablet-device {
        width: 50px;
      }

      path {
        fill: $primary-color;
      }
    }
  }
  
  .Quote-content {
    text-align: center;
    color: $darker-text-color;
  
    .text {
      font-family: $heading-font;
      font-style: italic;
      font-weight: 600;
      font-size: 1.1em;
      margin-bottom: 25px;
    }
  
    .name {
      font-family: $primary-font;
      font-weight: 400;
      font-size: 1em;
    }
  }

  .Quote-content.align-left {
    text-align: left;
  }
}


/*
Scrolling Background
*/

.ScrollingBackground {
  .ScrollingBackground-container {
    position: relative;
    height: 500px;
    overflow: hidden;
  }

  .ScrollingBackground-content {
    position: absolute;
    top: 20rem;
    left: 0;
    overflow-wrap: break-word;
  }
}


/*
Story Navigator
*/

.StoryNavigator {
  .StoryNavigator-nav {
    display: grid;
    grid-template-columns: 22% 22% 22% 22% 1fr;
    gap: 4%;
    padding-left: 3%;
    padding-right: 3%;
    z-index: 10;

    @include tablet-device {
      display: flex;
      flex-direction: column;
      padding: 0px;
    }

    .nav-item {
      overflow: hidden;
      cursor: pointer;

      @include tablet-device {
        display: flex;
        flex-direction: row;
        margin-bottom: 3px;
      }

      img {
        width: 100%;
        height: auto;

        @include tablet-device {
          width: 100px;
          height: 82px;
          margin-right: 1px;
        }
      }

      .title {
        background-color: $base-text-color;
        height: 100%;
        margin-top: -4px;

        @include tablet-device {
          margin-top: 0px;
          height: 82px;
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }

      p {
        font-family: $primary-font;
        font-weight: 500;
        font-size: 1.2em;
        text-transform: uppercase;
        text-align: center;
        padding: 15px 30px;
        color: $constrast-light-color;

        @include medium-device {
          font-size: 1.1em;
          padding: 15px;
        }

        @include tablet-device {
          text-align: left;
        }
      }
    }

    .nav-item.active .title {
      background-color: $darker-text-color;
    }

    div.nav-item:hover .title {
      background-color: $darker-text-color;
    }
  }

  .StoryNavigator-cards {
    z-index: 1;
    background-color: $offset-background-color;
    margin-top: -40px;

    @include tablet-device {
      margin-top: 0px;
    }

    h5 {
      padding-top: 80px;
      padding-left: 30px;
      padding-right: 30px;
      margin-bottom: 20px;
      font-family: $primary-font;
      font-size: 1.3em;
      font-weight: 500;

      @include tablet-device {
        padding-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .StoryCard {
      padding-bottom: 30px;
      padding-left: 30px;
      padding-right: 30px;

      @include tablet-device {
        padding-bottom: 15px;
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .StoryCard.show {
      transition: opacity 0.5s ease-in;
      opacity: 1;
      height: auto;
    }

    .StoryCard.hide {
      opacity: 0;
      height: 0;
      padding: 0px;
      overflow: hidden;
    }
  }
}


/*
Video/Story and Video Popup Cards
*/

.VideoStoryCard {
  position: relative;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;

  .VideoStoryCard-container {
    width: 100%;
  }

  .VideoPopupCard {
    width: 100%;
    height: inherit;
    aspect-ratio: 1.46;

    @include medium-device {
      z-index: 1;
    }
  }
}

.VideoStoryCard.left-orientation {
  padding-left: 170px;

  .VideoStoryCard-story {
    position: absolute;
    left: 0;
    top: 60px;
  }
}

.VideoStoryCard.right-orientation {
  padding-right: 170px;

  .VideoStoryCard-story {
    position: absolute;
    right: 0;
    top: 60px;
  }
}

.VideoStoryCard.left-orientation, .VideoStoryCard.right-orientation {
  @include medium-device {
    padding-left: inherit;
    padding-right: inherit;
    position: inherit;
    text-align: center;
  }

  .VideoStoryCard-story {
    @include medium-device {
      position: relative;
      left: inherit;
      top: inherit;
      right: inherit;
      max-width: inherit;
      width: 85%;
      margin: 0 auto;
      margin-top: -45px;
      z-index: 10;
      text-align: left;
    }

    @include small-device {
      width: 80%;
    }
  }
}

.VideoStoryCard-story {
  max-width: 225px;
  padding: 25px;
  background-color: $constrast-light-color;
  box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.12), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 1px -1px rgba(0,0,0,0.2);

  .title {
    margin-bottom: 15px;
    font-family: $primary-font;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.2em;
  }

  .story-content {
    font-weight: 400;
    font-size: 0.95em;
    line-height: 1.4em;
  }
}

.VideoPopupCard {
  width: 100%;
  height: inherit;
  aspect-ratio: 1.72;
}

.VideoPopupCard-container {
  width: 100%;
  height: 100%;
}

.VideoPopupCard-image {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;

  .image-bg {
    position: absolute;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: #000000;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .overlay {
    position: absolute;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,.3);
  }

  .video-icon {
    position: absolute;
    left: 0px;
    height: 100%;
    width: 100%;

    .icon-container {
      @include flex-row();
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    
    .Icon {
      color: $constrast-light-color;
      font-size: 7em;
      opacity: 0.5;
    }
  }
}

.VideoPopupCard-popup {
  .MuiDialog-paper {
    width: 100%;
    max-width: 900px;
  }

  .title h2 span {
    text-transform: uppercase;
  }

  .video {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
  }
  
  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .story-content {
    margin-top: 25px;
    max-width: 854px;
    font-size: 1.1em;
    font-weight: 400;
    line-height: 1.5em;
  }
}

.Help {
  margin-top: 55px;

  h3 {
    margin-bottom: 10px;
    font-size: 1.3em;
    text-transform: none;
  }

  button {
    padding: 0px;
    margin-top: 20px;
    color: $link-color;
  }
}