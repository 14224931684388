
$signup-bg-color:         #04162D;
$participation-bg-color:  #3e4f7d;
$pitch-bg-color:          #466388;
$free-floating-max-width:   750px;

.Home {
  text-align: center;

  .section-header {
    margin-bottom: 20px;
    letter-spacing: 2px;
    max-width: $free-floating-max-width;
    margin: 0 auto;
    text-align: center;
  }

  p.section-description {
    font-size: 1.2em;
    font-weight: 400;
    margin-bottom: 60px;
    max-width: $free-floating-max-width;
    margin: 0 auto;
    text-align: center;
  }

  .description {
    margin-bottom: 60px;
  }

  .divider-bar {
    margin: 0 auto;
    width: 100px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid $primary-light-color;
  }

  .container {
    padding-left: 100px;
    padding-right: 100px;
    text-align: center;

    @include large-device {
      padding-left: 60px;
      padding-right: 60px;
    }

    @include desktop-device {
      padding-left: 25px;
      padding-right: 25px;
    }

    @include phone-device { 
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .container-content {
    max-width: 1240px;
    margin: 0 auto;
    text-align: left;
  }
}

.Home-banner {
  margin-bottom: 60px;
  height: 300px;
  position: relative;
  overflow: hidden;

  @include small-device {
    height: 260px;
  }

  @include phone-device {
    height: 200px;
  }

  .feature img {
    width: 100%;
    position: relative;
    float: left;
    z-index: 10;
    overflow: hidden;
    left: 0;

    @include large-device {
      width: 120%;
      left: -10%;
    }

    @include desktop-device {
      width: 140%;
      left: -20%;
    }

    @include medium-device {
      width: 160%;
      left: -30%;
    }

    @include tablet-device {
      width: 200%;
      left: -50%;
    }

    @include small-device {
      width: 260%;
      left: -80%;
    }
  }

  .tagline {
    z-index: 100;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);

    h1, h2 {
      text-transform: inherit;
    }

    h1 {
      color: $constrast-light-color;
      font-size: 2.3em;
      font-weight: 500;
      margin-top: 100px;
      font-family: $heading-font;

      @include large-device {
        font-size: 2em;
      }

      @include desktop-device {
        font-size: 1.7em;
      }

      @include small-device {
        max-width: 80%;
        margin: 0 auto;
        margin-top: 75px;
        font-size: 1.3em;
      }

      @include phone-device {
        margin-top: 45px;
      }
    }

    h2 {
      color: $constrast-light-color;
      font-size: 1.6em;
      font-weight: 400;
      font-style: italic;
      margin-top: 5px;
      font-family: $heading-font;

      @include large-device {
        margin-left: 60px;
      }

      @include desktop-device {
        font-size: 1.2em;
        margin-left: 25px;
      }

      @include medium-device {
        margin-left: 0px;
        font-size: 1.1em;
      }

      @include small-device {
        margin-left: 0px;
        padding: 0px 5px;
        font-size: 1em;
      }

      @include phone-device {
        display: none;
      }
    }

    button {
      color: $constrast-light-color;
      border-color: $constrast-light-color;
      margin-top: 20px;
    }
  }
}

.Home-offerings .introduction {
  @include flex-col();
  margin-bottom: 60px;
  padding-top: 0px;
  padding-bottom: 0px;

  .description {
    h3 {
      font-size: 1.4em;
    }
  }

  .cards {
    @include flex-row();
    justify-content: center;

    @include tablet-device {
      flex-direction: column;
      text-align: center;
    }
  }

  .card {
    max-width: 340px;
    margin-bottom: 70px;
    cursor: pointer;

    @include large-device {
      max-width: 300px;
    }

    @include tablet-device {
      max-width: 375px;
      margin: 0 auto;
      margin-bottom: 70px;
      text-align: left;
    }

    .feature-image {
      width: 100%;
      height: 190px;
      background-color: $default-feature-bg;
      background-repeat: no-repeat;
      background-size: 100% auto;

      @include large-device {
        height: 180px;
      }

      @include desktop-device {
        height: 150px;
      }

      @include medium-device {
        height: 140px;
      }

      @include tablet-device {
        height: 190px;
      }
    }

    h4 {
      padding: 8px;
      color: $primary-color;
      letter-spacing: 0.5px;
    }

    p {
      padding: 0px 8px;
      height: 80px;

      @include large-device {
        height: 100px;
      }

      @include medium-device {
        height: 120px;
      }

      @include tablet-device {
        height: inherit;
        padding-bottom: 30px
      }
    }

    button {
      font-size: 0.85em;
      color: $link-color;
      padding-left: 8px;
    }
  }

  .card.courses, .card.lectures {
    margin-right: 100px;

    @media only screen and (max-width: 1300px) {
      margin-right: 50px;
    }

    @include large-device {
      margin-right: 30px;
    }

    @include tablet-device {
      margin-right: 0px;
      margin: 0 auto;
      margin-bottom: 70px;
    }
  }
}

.Home-offerings .participation {
  @include flex-col();
  padding-top: 90px;
  padding-bottom: 50px;
  margin-bottom: 120px;
  background-color: $participation-bg-color;
  color: $constrast-light-color;
  @include angle('before', true, 1.5deg);
  @include angle('after', true, 1.5deg);

  .description {
    h3 {
      font-size: 1.4em;
      color: $constrast-light-color;
    }
  }

  .cards {
    @include flex-row();
    justify-content: center;
    text-align: center;

    @include tablet-device {
      flex-direction: column;
    }
  }

  .card {
    max-width: 500px;
    margin: 0 auto;
    margin-bottom: 70px;

    p {
      padding: 0px 30px;
    }
  }

  .Icon .avatar {
    height: 80px;
    width: 80px;
    font-size: 2em;
    background-color: $tertiary-light-color;
    color: $heading-color;
  }

  h4 {
    color: $constrast-light-color;
    font-size: 1.15em;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.Home-offerings .culture {
  @include flex-col();
  padding-top: 10px;
  padding-bottom: 100px;

  .description {
    max-width: 100%;

    h3 {
      font-size: 1.4em;
    }
  }

  .cards {
    @include flex-row();
    justify-content: space-between;
    text-align: center;
    width: 900px;
    margin: 0 auto;
    margin-bottom: 70px;

    @include medium-device {
      width: 700px;
    }

    @include tablet-device {
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }
  }

  .card {
    max-width: 500px;
    cursor: pointer;

    @include tablet-device {
      margin: 0 auto;
      margin-bottom: 60px;
    }
  }

  .feature-image {
    @include flex-col();
    justify-content: flex-end;
    background-color: $default-feature-bg;
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: 150px;
    width: 250px;
    margin: 0 auto;
    margin-bottom: 10px;
    border-radius: 5px;

    @include medium-device() {
      width: 215px;
      height: 125px;
    }

    @include tablet-device() {
      height: 150px;
      width: 275px;
    }
  }

  .title {
    text-align: center;
    font-size: 1em;
    color: $primary-color;
  }
}

.Home-pitch {
  padding: 0px 30px 70px 30px;
  margin-bottom: 60px;
  background-color: $pitch-bg-color;
  color: $tertiary-light-color;
  @include angle('before', true, 1.5deg);
  @include angle('after', true, 1.5deg);

  .container-content {
    text-align: center;
  }

  .callout {
    display: inline-block;
    background-color: $primary-color;
    padding: 40px;
    margin-top: -60px;
    margin-bottom: 80px;
    border-radius: 3px;
  }

  h3 {
    color: $tertiary-light-color;
    font-size: 1.2em;
    font-family: $primary-font;
    margin-bottom: 30px;
    text-align: center;
    text-transform: inherit;

    span {
      color: $primary-light-color;
    }
  }

  div.join-button {
    text-align: center;
  }

  button {
    color: $constrast-light-color;
    border-color: $constrast-light-color;
    margin: 0 auto;
  }

  .message {
    max-width: 1000px;
    display: inline-block;
    margin: 0 auto;
    margin-bottom: 40px;
    font-size: 1.05;
    font-weight: 400;
    line-height: 22px;

    button {
      display: block;
      margin-top: 30px;
    }
  }

  h5 {
    font-size: 1.1em;
    font-weight: 400;
    margin-bottom: 15px;
    color: $primary-light-color;
  }

  p {
    margin-bottom: 0px;
    font-weight: 300;
  }
}

.Home-testimonials {
  padding: 60px 30px;

  > div {
    @include flex-col();
    max-width: 800px;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  svg {
    margin: 0 auto;
    margin-bottom: 30px;
    width: 50px;
    height: auto;
  }

  .quote {
    font-size: 1.1em;
  }

  p {
    font-weight: 400;
    margin-bottom: 20px;
    font-style: italic;
    font-family: $heading-font;
    font-size: 1.3em;
    color: $primary-color;

    @include tablet-device {
      padding: 0px
    }
  }

  p.name {
    font-weight: 400;
    font-size: 1em;
    font-family: $primary-font;
    font-style: normal;
    color: inherit;
  }
}