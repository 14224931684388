
#MemberRegistration {
  text-align: center;
  
  .PageTitle { 
    text-align: center;
    margin-top: 20px;
  }

  .panels {
    @include flex-row();
    margin-top: 80px;

    @include medium-device {
      display: block;
    }

    @include medium-device {
      margin-top: 50px;
    }
  }
}

#MemberRegistration-information {
  width: 30%;
  padding: 0px 60px;

  @include medium-device {
    width: 100%;
    padding: 0px 10px;
  }

  @include small-device {
    padding: 15px;
    box-sizing: border-box;
  }

  h2 {
    @include centered-flex-row();
    margin-bottom: 20px;
    color: $secondary-color;
    font-weight: 300;

    @include large-device {
      justify-content: left;
      text-align: left;
      font-size: 1.15em;
    }
  }

  .MemberBenefits-marketing {
    @include centered-flex-col();

    @include medium-device {
      @include spaced-flex-row();
      align-items: normal;
    }

    @include small-device {
      @include centered-flex-col();
    }

    @include ie-specific {
      display: block;
    }
  }

  .MemberBenefits-benefit {
    @include mui-no-panel-decoration();
    margin-bottom: 40px;

    @include medium-device {
      width: 32%;
      margin-bottom: 10px;
    }

    @include small-device {
      width: 100%;
      margin-bottom: 30px;
    }

    div.MemberBenefits-benefitIcon {
      background-color: $section-heading;
      color: $constrast-text-color;

      @include small-device {
        display: none;
      }
    }

    div.MemberBenefits-benefitDescription {
      padding: 0px;
      text-align: left;
    }

    img {
      @include medium-device {
        display: none;
      }
    }

    h3 {
      margin-bottom: 5px;
      margin-bottom: 5px;
      font-size: 1em;
      font-weight: 400;
      font-family: $primary-font;
      text-transform: none;
      color: $constrast-text-color;
      background-color: $section-heading-header;
      padding: 10px 5px;

      @include large-device {
        font-size: 1em;
      }

      @include small-device {
        background-color: $invisible-background;
        padding: 0px;
        color: $primary-color;
        font-size: 1.1em;
      }

      .Icon {
        display: none;

        @include small-device {
          display: inline-block;
          margin-right: 5px;
          font-size: 1.1em;
        }
      }
    }

    p {
      padding: 5px;

      @include small-device {
        padding: 0px;
      }

      strong {
        font-weight: 500;
      }
    }
  }
}

#MemberRegistration-formContainer {
  width: 70%;

  @include medium-device {
    width: 100%;
    padding: 20px 40px 40px 40px;
    box-sizing: border-box;
    background-color: $offset-background-color;
    margin-top: 40px;
  }

  @include small-device {
    padding: 0px;
    background-color: $invisible-background;
  }
}

#MemberRegistrationForm {
  h2 {
    margin-top: 10px;
    margin-bottom: 30px;
    text-transform: none;
  }

  h3 {
    text-transform: none;
    font-size: 1.1em;
    margin-bottom: 20px;
  }

  .FormWrapper {
    max-width: 600px;
    margin-bottom: 50px;
  }

  .MemberRegistrationForm-separator {
    margin-top: 30px;

    hr {
      margin: 0px;
      margin-bottom: -20px;
    }

    span {
      display: inline-block;
      text-transform: uppercase;
      font-weight: 500;
      background-color: #ffffff;
      padding: 10px;
    }
  }

  .MemberRegistrationForm-providers {
    button {
      color: $constrast-light-color;
      width: 300px;
      margin-bottom: 10px;

      @include small-phone-device {
        width: 250px;
      }
      
      .Icon {
        display: inline-block;
        margin: 0px;
        margin-right: 10px;
      }
    }

    .GoogleRegistration button {
      background-color: $google-color;
    }

    .FacebookRegistration button {
      background-color: $facebook-color;
    }
  }

  .MemberRegistrationForm-spamCheck {
    padding: 20px;
    margin: 30px 0px;
    background-color: $form-offset-color;
    border-radius: 3px;
    color: $constrast-text-color;

    .MemberRegistrationForm-spamCheckInstruction {
      font-size: 0.85em;
    }

    input {
      background-color: $form-offset-input-color;
      color: $constrast-text-color;
      padding: 5px;
    }

    p {
      font-weight: 400;
    }
  }

  .acknowledge-label {
    span {
      font-size: 1em;
    }
  }
}

.ProviderRegistrationSuccess-snippet {
  margin-top: 45px;
  margin-bottom: 60px;
}

#NewMemberConfirmation {
  .NewMemberConfirmation-followup {
    max-width: 800px;
    margin: 0 auto;

    h2 {
      @include uppercase(1.3em);
      margin-bottom: 35px;
    }
  }

  .NewMemberConfirmation-step {
    margin-bottom: 50px;
  }

  .NewMemberConfirmation-instructions {
    border-bottom: 1px solid $light-border-color;
    background-color: $offset-background-color;

    h3 {
      @include centered-flex-row();
      font-size: 1.3em;
      color: $primary-color;
    }

    p {
      padding: 10px 0px;
    }
  }
}

#MemberActivation {
  text-align: center;

  #MemberActivation-success, #MemberActivation-error {
    margin: 0 auto;
    max-width: 700px;
  }

  .MemberActivation-passwordSetup {
    padding: 20px;
    margin-bottom: 40px;

    h2 {
      margin-top: 10px;
    }

    .MemberActivation-passwordMessage {
      padding-top: 20px;
      padding-bottom: 30px;
    }

    .MemberActivation-passwordBtn {
      margin-right: 10px;
    }
  }

  .MemberActivation-welcome {
    margin-bottom: 30px;
  }

  .MemberActivation-help {
    padding: 20px 0px;
  }

  .MemberActivation-conclusion {
    padding: 15px;
    background-color: $offset-background-color;

    .MemberActivation-finalStatement {
      font-size: 1.4em;
    }

    .MemberActivation-team {
      @include uppercase(1.1em);
      @include centered-flex-row();
      color: $primary-color;
      padding-top: 5px;
    }
  }

  #MemberActivation-error {
    .error-heading {
      font-size: 1.5em;
  
      .Icon {
        margin: 0px;
        margin-right: 20px;
  
        svg {
          color: $secondary-color;
        }
      }
  
      p {
        margin-top: 20px;
      }
    }
  
    .error-content {
      font-size: 1.1em;
      margin-top: 15px;
    }
  }
}

#ProviderRegistration {
  text-align: center;
}

#ProviderRegistrationForm {
	max-width: 700px;
  margin: 0 auto;
  text-align: left;

  h2 {
    text-transform: none;
    font-size: 1.4em;
  }

  .email {
    margin-top: 30px;

    .label {
      font-size: 0.9em;
      font-weight: 500;
    }

    .value {
      font-size: 1.05em;
      font-weight: 500;
    }
  }
  
  .ProviderRegistrationForm-policy {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .Form-buttons {
    text-align: center;
  }
}

.ProviderRegistrationSuccess {
  text-align: center;
  max-width: 900px;
  margin: 0 auto;

  p.success-message {
    font-size: 1.7em;
    font-weight: 400;
  }

  p.message {
    margin-top: 30px;
    font-size: 1.2em;
    font-weight: 300;
    text-align: left;

    strong {
      font-weight: 500;
    }
  }

  p.signature {
    margin-top: 40px;
    font-size: 1.2em;
    font-weight: 500;
  }
}
