@import './content/common/common';
@import './content/common/data';
@import './content/About';
@import './content/AnnualReport';
@import './content/LearnMore';
@import './content/Pilgrimage';
@import './content/Retreat';
@import './content/RestrictedContentPrompt';
@import './content/GospelReflections';
@import './content/PrivacyPolicy';

// Application
@import './content/CourseApplication';

// Classroom
@import './content/Classroom';

// Courses
@import './content/courses/CourseDetail';
@import './content/courses/CourseTopic';
@import './content/courses/CourseAssessment';

// Events
@import './content/events/EventCard';
@import './content/events/EventDetail';
@import './content/events/EventDetailCourses';
@import './content/events/EventList';
@import './content/events/EventMedia';
@import './content/events/EventRegistration';
@import './content/Instructors';
@import './content/Curriculum';
@import './content/Search';
@import './content/Filter';

.Page, .Article {
  text-align: center;

  h1 {
    margin-bottom: 50px;
    font-size: 1.8em;
    color: $primary-color;
    text-align: center;
  }

  p {
    margin-bottom: 20px;
    font-family: $primary-font;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.45em;

    strong, b {
      font-weight: 500;
    }
  }

  .PageContent, .ArticleContent {
    text-align: left;
    margin-bottom: 60px;
  }

  .PageTitle.display-hide {
    display: none;
  }
}

.Article {
  max-width: 1000px;
  margin: 0 auto;
}

.EventList-container.browse {
  .EventList {
    margin-bottom: 100px;
  }
}

/*
Mission Partners page
*/

.page-mission-partners {
  h1 {
    margin-bottom: 85px;
  }
  
  .wp-block-columns {
    margin-bottom: 55px;

    @include tablet-device {
      margin-bottom: 85px;
    }

    div:last-of-type {
      display: flex;
	    flex-direction: column;
	    justify-content: center;
    }

    .wp-block-image {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    
    figure {
        max-width: 200px;

        @include tablet-device {
          margin-bottom: 35px;
        }
    }

    h3 {
      margin-bottom: 15px;

      @include tablet-device {
        text-align: center;
      }
      
      a {
        text-decoration: none;
      }
    }
  }
}
