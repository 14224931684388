
.MagdalaRegistration {

  margin-top: 50px;
  margin-bottom: 50px; 

  .registration-step {
    .title {
      font-size: 1.1em;
      font-weight: 400;

      strong {
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    p {
      margin-bottom: 20px;
    }

    .Mui-error {
      margin-bottom: 0;
    }
  }

  .back {
    text-align: left;
    margin-bottom: 25px;

    button {
      padding-left: 0px;
      padding-right: 0px;
    }

    .Icon {
      margin-right: 10px;
    }
  }

  #MemberRegistrationForm {
    max-width: inherit;
    padding: 0px;
    margin-top: 40px;

    .FormWrapper {
      margin-left: 0px;
      margin-right: 0px;
      max-width: inherit;
      box-shadow: none;
      padding: 0px;
    }

    .Icon {
      display: none;
    }
  }

  .membership-creation {
    max-width: 700px;
    margin: 0 auto;
    
    p.title, p.message {
      text-align: left;
    }
  }

  .completed-message {
    font-size: 1.2em;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .unverified {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 20px auto 120px;
      
      @include small-device {
        margin: 10px;
      }
      
      p {
        margin-top: 25px;
        max-width: 675px;
      }
    }
  }

}

.CommunitySelection, .MagdalaConfirmation {
  max-width: 700px;
  margin: 0 auto;

  p {
    text-align: left;
  }

  .FormWrapper {
    box-shadow: none;
    padding: 0px;
  }

  .FormField-control {
    text-align: left;
  }

  .Form-buttons {
    margin-top: 45px;
  }
  
}

.signout-prompt {
      display: inline-block;
      padding: 10px;
      margin-bottom: 35px;
      color: $disabled-text-color;
      font-weight: 200;
    
      p {
        margin-top: 10px;
      }
    
        a, span {
          text-decoration: underline;
          color: $primary-contrast;
          cursor: pointer;
        }
}

.MagdalaConfirmation {
  .FormWrapper {
    text-align: left;
  }

  label {
    display: flex;
    margin-bottom: 25px;

    .MuiButtonBase-root {
      display: block;
    }

    .MuiTypography-root {
      flex: 1;
      display: block;
      font-size: inherit;
    }
  }

  .Form-buttons {
    text-align: center;
  }
}