@import './magdala/MagdalaRegistration';
@import './magdala/MagdalaLanding'; 
@import './magdala/MagdalaProgram'; 
@import './magdala/MagdalaCourses';
@import './magdala/MagdalaCurriculum'; 

.Magdala-Banner {

  .Magdala-Banner-Container {
    background-color: $primary-alt-color;
    display: flex;
    justify-content: center;
    box-shadow: 0 0.5px 1px 0.25px $primary-color;
    width: 100%;

    .Magdala-Banner-Image {
      max-height: 250px;
      max-width: 100%;
      cursor: pointer; 
    }

    .imgWithLogo {
      display: block;

      @include small-device {
        display: none; 
      }
    }

    .imgNoLogo {
      display: none;

      @include small-device {
        display: block; 
      }
    }

  }
}

.loadMessage {
  display: flex;
  justify-content: center;
  margin-top: 20%; 
  margin-bottom: 20%; 
}