
.EventDetail-course-access {
  margin-top: 20px;
  margin-bottom: 30px;

  @include small-device {
    text-align: center;
  }

  .course-status {
    margin-bottom: 5px;
    color: $info-color;
    font-weight: 500;
  }

  .release-date {
    strong {
      font-weight: 500;
      margin-right: 5px;
    }
  }

  button {
    text-transform: none;
    font-weight: 400;
    margin-top: 5px;

    @include small-device {
      margin: 0 auto;
      width: 100%;
      margin-bottom: 20px;
      padding: 15px;
    }

    .Icon {
      margin-right: 7px;
    }

    .MuiButton-label {
      display: inline-block;
    }
  }
}

.EventDetail-course-access.self-paced.closed,
.EventDetail-course-access.live.application,
.EventDetail-course-access.live.closed {
  text-align: left;
}

.EventDetail-course-application-status {
  display: inline-block;
  padding: 10px;
  margin-bottom: 35px;
  background-color: $success-color;
  color: $constrast-light-color;
  font-weight: 500;

  p {
    margin-top: 10px;

    a, span {
      text-decoration: underline;
      color: $constrast-light-color;
      font-weight: 500;
      cursor: pointer;
    }
  }
}

.EventDetail-course-application {
  margin-top: 20px;
  margin-bottom: 80px;

  .instructions {
    margin-bottom: 20px;
    font-weight: 400;
  }

  .content {
    margin-top: 20px;
    padding: 30px 20px;

    .italic {
      font-style: italic;
    }

    ul {
      padding: 12px;
      margin: 12px;
    }

    li {
      margin-bottom: 10px;
    }

    strong {
      font-weight: 500;
      margin-bottom: 10px;
      display: inline-block;
    }
  }

  .actions {
    text-align: center;
    margin-top: 30px;
  }

  .certificate {
    .note {
      margin-bottom: 20px;
    }
  }
}