
.AppHeader-exploreNav {
  flex: 1;

  .container {
    @include flex-row();
    justify-content: center;

    @include desktop-device { 
      justify-content: flex-end;
      margin-right: 10px;
    }
  }

  .AppHeader-exploreButton {
    color: $constrast-light-color;
    background-color: $primary-color;
    box-shadow: none;
    font-weight: 500;
    font-size: 0.95em;
    margin-right: 10px;

    @include tablet-device {
      margin-right: 0px;
      background-color: $primary-alt-hover;
    }

    @include small-device {
      padding: 6px;
      min-width: 44px;
    }

    > span {
      @include small-device {
        display: none;
      }
    }

    .Icon {
      margin-right: 5px;

      @include small-device {
        margin: 0px;
      }

      > div {
        margin-top: 1px;
      }
    }
  }

  .AppHeader-exploreButton:hover {
    background-color: $primary-alt-hover;
  }

  .AppHeader-search {
    @include flex-row();
    width: 300px;
    padding: 2px 4px;
    align-items: center;
    background-color: $constrast-light-color;
    color: $constrast-text-color;

    @include tablet-device { 
      display: none;
    }
  
    .search-field {
      flex: 1;
      margin-left: 8px;
      font-size: 1em;
      font-weight: 400;
    }

    .search-icon {
      padding: 6px;
    }
  }
}