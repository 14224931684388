@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin flex-col {
  display: flex;
  flex-direction: column;
}

@mixin centered-flex-row {
  @include flex-row();
  align-items: center;
  justify-content: center;
}

@mixin spaced-flex-row {
  @include flex-row();
  justify-content: space-between;
}

@mixin centered-flex-col {
  @include flex-col();
  align-items: center;
  justify-content: center;
}

@mixin block-section-heading {
  min-width: 150px;
  margin-right: 20px;
  background-color: $section-heading;

  @include small-device {
    min-width: 100%;
    margin-right: 0px;
  }
}

@mixin block-section-heading-header {
  font-size: 1em;
  font-weight: 500;
  padding: 10px 0px;
  background-color: $section-heading-header;

  @include small-device {
    max-width: 150px;
  }
}

@mixin text-content-block {
  font-family: $primary-font;
  line-height: 1.35em;
  font-size: 0.95em;
  font-weight: 400;
}

@mixin uppercase($size) {
  font-family: $primary-font;
  font-size: $size;
  text-transform: uppercase;
}

@mixin mui-no-panel-decoration {
  box-shadow: none;
  border: none;
}

@mixin breakpoint($width) {
  @media only screen and (max-width: #{$width}px) {
    @content;
  }
}

@mixin large-device {
  @media only screen and (max-width: #{$breakpoint-large}) {
    @content;
  }
}

@mixin desktop-device {
  @media only screen and (max-width: #{$breakpoint-desktop}) {
    @content;
  }
}

@mixin medium-device {
  @media only screen and (max-width: #{$breakpoint-medium}) {
    @content;
  }
}

@mixin tablet-device {
  @media only screen and (max-width: #{$breakpoint-tablet}) {
    @content;
  }
}

@mixin small-device {
  @media only screen and (max-width: #{$breakpoint-small}) {
    @content;
  }
}

@mixin phone-device {
  @media only screen and (max-width: #{$breakpoint-phone}) {
    @content;
  }
}

@mixin small-phone-device {
  @media only screen and (max-width: #{$breakpoint-small-phone}) {
    @content;
  }
}

@mixin ie-specific {
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) { 
    @content;
  }
}

@mixin angle($pseudo, $flip: false, $angle: 1.5deg) {
  // Possible values for $pseudo are: before, after, both
  @if $pseudo == 'before' or $pseudo == 'after' or $pseudo == 'both' {
    position: relative;
    z-index: 1;
    $selector: if($pseudo == 'both', '&:before,&:after', '&:#{$pseudo}');

    #{$selector} {
      background: inherit;
      content: '';
      display: block;
      height: 50%;
      left: 0;
      position: absolute;
      right: 0;
      z-index: -1;
      -webkit-backface-visibility: hidden; // for Chrome Windows
    }

    @if $pseudo == 'before' {
      #{$selector} {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle);
          transform-origin: 100% 0;
        }
      }
    }

    @if $pseudo == 'after' {
      #{$selector} {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          transform-origin: 0 100%;
        } @else {
          transform: skewY($angle * -1);
          transform-origin: 100%;
        }
      }
    }

    @if $pseudo == 'both' {
      &:before {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle);
          transform-origin: 100% 0;
        }
      }

      &:after {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle * -1);
          transform-origin: 100%;
        }
      }
    }
  }
}