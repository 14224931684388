.AnnualReport-section.welcome .AnnualReport-content {
  @include flex-row();
  padding-top: 20px;
  padding-bottom: 70px;

  @include medium-device {
    @include flex-col();
    align-items: center;
    padding-bottom: 40px;
  }

  .welcome-text {
    flex: 1;
    margin-top: -30px;
    padding-right: 50px;

    @include medium-device {
      margin-top: 0px;
      padding-right: 0px;
    }

    p {
      margin-bottom: 20px;
      font-weight: 400;
      line-height: 1.5em;
    }

    .salutation {
      font-size: 1em;
      font-weight: 500;
    }

    .name {
      font-size: 1em;
      font-weight: 500;
      margin-bottom: 0px;
    }

    .title {
      font-family: $alt-heading-font;
      font-style: italic;
      font-size: 1.1em;
      font-weight: 500;
    }
  }

  .welcome-text-content.large-display {
    @include phone-device {
      display: none;
    }
  }

  .welcome-text-content.small-display {
    display: none;

    @include phone-device {
      display: block;
    }
  }
}

/*
Interstitial
*/

.AnnualReport-section.interstitial.welcome {
  background-color: $ar-dark-blue;
  @include angle('before', true, 2.0deg);
  @include angle('after', true, 2.0deg);
}

.AnnualReport-section.interstitial.welcome .AnnualReport-content {
  padding-top: 0px;
  padding-bottom: 0px;
}

.AnnualReport-section.interstitial.welcome .AnnualReport-content .container {
  .Quote {
    .icon {
      svg {
        path {
          fill: $ar-light-blue;
        }
      }
    }
  }
}