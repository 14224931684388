
.CourseApplication {
  .application-step {
    .title {
      font-size: 1.1em;
      font-weight: 400;

      strong {
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    p {
      margin-bottom: 20px;
    }
  }
}

.ApplicationWarning {
  max-width: 700px;
  margin: 0 auto;

  .warning-icon {
    font-size: 2.5em;
    margin-bottom: 20px;
  }

  .warning-text, .instruction-text {
    font-size: 1.05em;
    margin-bottom: 20px;
    text-align: left;
  }

  .instruction-text {
    h3 {
      font-family: $primary-font;
      font-size: 1.05em;
      font-weight: 500;
      margin-top: 25px;
      margin-bottom: 5px;
      color: $secondary-color;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    div {
      margin-top: 15px;
    }
  }
}

.ApplicationIdentification {
  max-width: 700px;
  margin: 0 auto;

  .back {
    text-align: left;
    margin-bottom: 25px;

    button {
      padding-left: 0px;
      padding-right: 0px;
    }

    .Icon {
      margin-right: 10px;
    }
  }

  h2 {
    text-align: left;
    font-size: 1.4em;
    margin-bottom: 10px;
  }

  #MemberRegistrationForm {
    max-width: inherit;
    padding: 0px;
    margin-top: 40px;

    .FormWrapper {
      margin-left: 0px;
      margin-right: 0px;
      max-width: inherit;
      box-shadow: none;
      padding: 0px;
    }

    .Icon {
      display: none;
    }
  }

  .membership-creation {
    p.title, p.message {
      text-align: left;
    }
  }
}

.ApplicationEmail {
  p {
    text-align: left;
  }

  .field-container {
    margin-bottom: 40px;
  }

  .email-field, .email-confirmation-field {
    width: 100%;
  }

  .email-field-container {
    margin-bottom: 25px;
  }

  .MuiInput-root {
    width: 100%;
  }
}

.ApplicationName {
  p {
    text-align: left;
  }

  .field-container {
    margin-bottom: 40px;
  }

  .salutation-field, .firstName-field, .lastName-field {
    width: 100%;
  }

  .MuiInput-root {
    width: 100%;
  }
}