.AnnualReport-section.abundance.top {
  background-color: $ar-light-green;
  @include angle('after', true, 2.0deg);
  z-index: 1;
}

.AnnualReport-section.abundance.top .AnnualReport-content {
  padding-top: 110px;
  padding-bottom: 60px;

  .SectionHeader {
    h3, p {
      color: $constrast-light-color;
    }
  }

  .stat-cards {
    @include flex-row();
    justify-content: space-between;

    @include medium-device {
      flex-wrap: wrap;
      justify-content: space-around;
    }

    .break {
      display: none;
      height: 0px;
      
      @include medium-device {
        display: inline-block;
        flex-basis: 100%;
      }
    }

    .NumberComparison {
      @include medium-device {
        margin-bottom: 60px;
      }
    }

    .NumberComparison-title {
      color: $constrast-light-color;

      @include medium-device {
        width: 275px;
      }

      @include tablet-device {
        width: 250px;
      }

      @include breakpoint(560) {
        width: 275px;
      }

      span {
        font-family: $alt-heading-font;
        font-weight: 600;

        @include small-device {
          width: 80%;
          margin-bottom: 10px;
        }
      }
    }

    .number {
      font-family: $alt-heading-font;
      font-size: 3.9em;
      font-weight: 500;
    }

    .NumberComparison-container {
      .number-container {
        width: 200px;

        @include medium-device {
          width: 275px;
        }

        @include tablet-device {
          width: 250px;
        }

        @include breakpoint(560) {
          width: 275px;
        }
      }

      .description {
        @include medium-device {
          font-size: 0.95em;
          padding-left: 20px;
          padding-right: 20px;
        }

        @include tablet-device {
          font-size: 1em;
          padding-left: 40px;
          padding-right: 40px;
        }
      }

      .top-number {
        background-color: $ar-green;
      }

      .bottom-number {
        background-color: $ar-very-light-green;
        color: $ar-green;
      }
    }

    .comparison-divider {
      background-color: $ar-green;

      @include medium-device {
        width: 275px;
      }

      @include tablet-device {
        width: 250px;
      }

      @include breakpoint(560) {
        width: 275px;
      }

      .arrow-up {
        background-image:
          linear-gradient(to bottom right, transparent 50%, $ar-very-light-green 0),
          linear-gradient(to top right, $ar-very-light-green 50%, transparent 0);
      }
    }
  }
}

.AnnualReport-section.abundance.bottom {
  background-image: url('https://instituteofcatholicculture.net/wp-content/uploads/2022/11/ar-green-decoration.png');
  background-repeat: repeat-y;
  background-size: 300px;

  @include medium-device {
    background-size: 250px;
  }

  @include tablet-device {
    background-size: 200px;
  }

  @include phone-device {
    background: none;
  }
}

.AnnualReport-section.abundance.bottom .AnnualReport-content {
  padding-top: 120px;
  padding-bottom: 170px;

  @include tablet-device {
    padding-bottom: 120px;
  }

  h4.story-heading {
    margin-top: 80px;
    margin-bottom: 20px;
    font-family: $alt-heading-font;
    font-size: 1.6em;
    font-weight: 600;
    text-transform: uppercase;

    @include tablet-device {
      margin-bottom: 50px;
    }
  }

  .story-cards {
    @include flex-row();
    justify-content: space-between;

    @include tablet-device {
      @include flex-col();
      align-items: center;
    }

    .ImageStoryCard {
      height: 480px;
      width: 310px;

      @include desktop-device {
        height: 460px;
        width: 297px;
      }

      @include medium-device {
        height: 420px;
        width: 271px;
      }

      @include breakpoint(880) {
        height: 390px;
        width: 252px;
      }

      @include breakpoint(840) {
        height: 360px;
        width: 232px;
      }

      @include tablet-device {
        height: inherit;
        width: 650px;
        aspect-ratio: 1.72;
        margin-bottom: 40px;
      }

      @include breakpoint(680) {
        width: 100%;
      }

      .icon-container {
        height: 30px;
        width: 30px;

        @include small-device {
          height: 25px;
          width: 25px;
        }
      }
  
      .Icon > div {
        height: 30px;
        width: 30px;

        @include small-device {
          height: 25px;
          width: 25px;
        }
  
        svg {
          height: 30px;
          width: 30px;

          @include small-device {
            height: 25px;
            width: 25px;
          }
        }
      }

      .ImageStoryCard-image {
        @include tablet-device {
          display: none;
        }
      }
  
      .ImageStoryCard-image-alt {
        @include tablet-device {
          position: absolute;
          display: block;
        }
      }
  
      .ImageStoryCard-overlay {
        @include tablet-device {
          aspect-ratio: 1.72;
        }
      }
  
      .ImageStoryCard-story {
        @include tablet-device {
          aspect-ratio: 1.72;
        }
      }
    }
  }
}

/*
Interstitial
*/

.AnnualReport-section.interstitial.abundance {
  background-color: $ar-green;
  @include angle('before', true, 2.0deg);
  @include angle('after', true, 2.0deg);
}