body {
  margin: 0;
  padding: 0;
  color: $base-text-color;
  font-family: $primary-font;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
}

* {-webkit-font-smoothing: antialiased;}

h1, h2, h3, h4, h5, h6 {
  color: $heading-color;
  font-family: $heading-font;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0px;
  padding: 0px;
}

p {
  font-family: $primary-font;
  font-weight: 300;
  margin: 0px;
  padding: 0px;
}

figure {
  margin: 0px;
}

h1 {
  font-size: $h1-default-size;
}

h2 {
  font-size: $h2-default-size;
}

.clear {
  clear: both;
}

.section-header {
  font-size: 1.3em;
  text-align: left;
  margin-bottom: 30px;
}

a {
  color: $link-color;
}

button.link-display {
  color: $link-color;
  background-color: $invisible-background;
  border: 0;
  cursor: pointer;
  text-decoration: underline;
  font-size: 1em;
  font-weight: 300;
}

p.rh {
  text-indent: -1000px;
  height: 0px;
  margin: 0px;
  padding: 0px;
  line-height: 0px;
}

.center {
  text-align: center;
}

.divider-bar {
  height: 1px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-bottom: 2px solid $light-border-color;
}

.responsive-iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;

  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.responsive-image-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;

  img {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.edge--bottom {
  @include angle(after);
}

.edge--bottom--reverse {
  @include angle(after, true);
}

.edge--top {
  @include angle(before);
}

.edge--top--reverse {
  @include angle(before, true);
}

.edge--both {
  @include angle(both);
}

.edge--both--reverse {
  @include angle(both, true);
}

