
.PrivacyPolicy {
  text-align: center;

  .content {
    max-width: 1000px;
    margin: 0 auto;
    text-align: left;
  }

  h1 {
    text-align: center;
    margin-bottom: 45px;
  }

  h2 {
    margin-bottom: 20px;
  }

  section {
    margin-bottom: 45px;

    p {
      margin-bottom: 20px;
    }

    strong {
      font-weight: 500;
    }
  }
}