
.PrivacyPrompt {
  position: fixed;
  z-index: 9999;
  bottom: 0px;
  width: 100%;
  background-color: $info-color;
  text-align: center;
}

.PrivacyPrompt-content {
  @include flex-row();
  text-align: left;
  margin: 0 auto;
  max-width: 1240px;

  h3 {
    font-family: $primary-font;
    text-transform: none;
    font-size: 1.1em;
    font-weight: 500;
    margin-bottom: 15px;
    color: $constrast-light-color;
  }

  p {
    font-weight: 400;
    color: $constrast-light-color;

    a {
      color: $constrast-light-color;
    }
  }

  .main {
    flex: 1;
    padding: 20px;
    padding-top: 10px;
  }

  .actions {
    margin-top: 15px;

    button {
      padding: 5px;
      margin-right: 10px;
      color: $constrast-text-color;
      font-size: 0.9em;
    }

    button.acknowledge {
      background-color: $constrast-light-color;
    }

    button.view-policy {
      background-color: $primary-light-color;
    }
  }

  .close {
    width: 40px;

    @include small-device {
      display: none;
    }

    button {
      padding: 10px;
      padding-top: 5px;

      svg {
        width: 23px;
        color: $constrast-light-color;
      }
    }
  }
}