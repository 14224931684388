
.registration-container {
  #MemberRegistrationForm {
    text-align: center;
    margin: 40px 0px 60px 0px;
    
    .FormWrapper {
      margin-bottom: 0px;
    }
  }
}

.EventRegistration-button {
  text-transform: none;
  font-weight: 500;

  .Icon {
    margin-right: 7px;
  }

  .MuiButton-label {
    display: inline-block;
  }
}

.EventRegistration-notIdentified, .EventRegistration-loading {
  margin-top: 30px;

  .LoadingMessage {
    font-size: 0.95em;
    font-weight: 400;
  }
}

.EventRegistration-identified {
  margin-top: 30px;

  .EventRegistration-button {
    margin-bottom: 10px;
  }

  .registrant .label {
    font-weight: 400;
  }
}
