
.About {
  p {
    margin-bottom: 15px;
    line-height: 1.4em;
  }

  strong {
    font-weight: 500;
  }

  h2 {
    color: $primary-color;
  }
}

.About-letter {
  margin-top: 60px;

  h2 {
    color: $primary-color;
    margin-bottom: 5px;
  }

  h3 {
    margin-bottom: 20px;
    text-transform: none;
  }

  strong {
    font-size: 1.1em;
    font-weight: 400;
  }

  .signature {
    display: flex;
    margin-top: 25px;
    margin-bottom: 30px;
  }

  img {
    margin-right: 30px;
  }

  .name-and-title {
    margin-top: 20px;
  }

  .name {
    margin-bottom: 5px;
    font-family: $heading-font;
    text-transform: uppercase;
  }

  .title {
    font-family: $heading-font;
  }

  .read-letter {
    margin-top: 20px;
  }

  button {
    font-size: 0.9em;
    padding: 3px 10px;
  }
}

.About-mission {
  margin-top: 60px;

  h2 {
    margin-bottom: 20px;
  }
}

.Staff {
  text-align: center;

  .content {
    text-align: left;
  }

  h1 {
    color: $primary-color;
    margin-bottom: 80px;
    text-align: center;
    letter-spacing: 2px;
  }

  .Staff-director {
    .image {
      width: 30%;
      min-width: 250px;
      margin-right: 50px;
      margin-bottom: 5px;
      float: left;

      @include small-device {
        width: 100%;
        float: none;
        margin-bottom: 30px;
      }
    }

    img {
      width: 100%;
    }

    .description {
      flex: 1;
      //max-width: 780px;
    }

    h3 {
      margin-bottom: 5px;
    }

    h4 {
      margin-bottom: 20px;
      text-transform: none;
    }

    p {
      line-height: 1.4em;
    }
  }

  .Staff-list {
    margin-top: 60px;

    @include small-device {
      margin-top: 80px;
    }

    .staff-member {
      @include flex-row();
      margin-bottom: 50px;
      padding: 30px;

      @include small-device {
        display: block;
        text-align: center;
      }
    }

    .image {
      min-width: 150px;
      margin-right: 30px;

      @include small-device {
        max-width: 150px;
        margin: 0 auto;
        margin-bottom: 30px;
      }
    }

    img {
      border-radius: 50%;
      width: 100%;
    }

    h3 {
      margin-bottom: 5px;
      margin-top: 10px;
    }

    h4 {
      margin-bottom: 15px;
      text-transform: none;
    }

    p {
      line-height: 1.4em;
      text-align: left;
    }
  }
}

.Leadership {
  text-align: center;
}

.LeadershipContent {
  text-align: center;

  h1, h3 {
    color: $primary-color;
    margin-bottom: 45px;
  }

  .Leadership-directors,
  .Leadership-advisors {
    @include flex-row();
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 60px;
  }

  .director, .advisor {
    @include flex-col();
    width: 280px;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 45px;

    img {
      margin: 0 auto;
      margin-bottom: 10px;
      width: 150px;
      height: 150px;
      border-radius: 50%;
    }

    h4 {
      margin-bottom: 5px;
    }

    h5 {
      font-family: $primary-font;
      font-size: 0.95em;
      text-transform: none;
    }

    button {
      margin: 0 auto;
      margin-top: 20px;
      font-size: 0.9em;
      padding: 2px 14px;
      width: 100px;
    }
  }
}

.Leadership-dialog {
  .director-name {
    h2 {
      font-size: 1.2em;
      text-transform: none;
    }
  }

  .director-description {
    font-size: 1em;
  }
}