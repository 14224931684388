
#Login-container {
  text-align: center;
}

#Login {
  margin: 0 auto;
  max-width: 400px;
  padding: 20px;
  box-sizing: border-box;

  .LoadingMessage {
    margin-top: 30px;
  }

  .Heading {
    margin-top: 10px;
    font-family: $heading-font;
    font-size: 1.25em;
  }

  div.Login-tabs {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  div.Login-providers {
    margin-top: 25px;
    
    button {
      color: $constrast-light-color;
      width: 300px;
      margin-bottom: 10px;

      @include phone-device {
        width: 290px;
      }

      @include small-phone-device {
        width: 250px;
      }
      
      .Icon {
        margin: 0px;
          margin-right: 10px;
      }
    }

    .LoginGoogle button {
      background-color: $google-color;
    }

    .LoginFacebook button {
      background-color: $facebook-color;
    }
  }

  .ConditionalMessage {
    margin-top: 20px;
  }

  .Login-resendBtn .Icon {
    margin-right: 5px;
    line-height: 1em;
  }

  .rememberDevice-label {
    margin-top: 10px;
  }
}

#RememberDevice, #ActivationPrompt {
  text-align: center;

  .content {
    max-width: 700px;
    margin: 0 auto;
  }

  p.title {
    font-size: 1.5em;
    font-weight: 400;
  }

  p.message {
    margin-top: 10px;
    font-size: 1.1em;
    font-weight: 300;
    text-align: left;
  }

  .actions {
    margin-top: 40px;
    text-align: center;
  }

  .actions.multiple {
    @include phone-device {
      display: flex;
      flex-direction: column;
    }

    button {
      margin-right: 10px;

      @include phone-device {
        margin-right: 0px;
        margin: 0 auto;
        margin-bottom: 15px;
        width: 80%;
      }
    }
  }
}

#LoginRedirect {
  text-align: center;
}

#Login-passwordReset {
  margin-top: 5px;

  .sign-up-button {
    color: $darker-text-color;
  }
}

#LoginCredentialsForm-rememberme, #LoginPasswordlessForm-rememberme {
  background-color: $secondary-color;
  padding: 5px;
  color: $constrast-light-color;
  border-radius: 3px;
}

#LoginPasswordlessForm {
  .LoginPasswordlessForm-instructions {
    background-color: $section-heading;
    padding: 15px;
    border-radius: 5px;
    color: $constrast-text-color;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .LoginPasswordlessForm-expiredMessage {
    @include centered-flex-row();
    background-color: $error-color;
    color: $error-text-color;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;

    .Icon {
      margin-right: 10px;

      svg {
        height: 1.5em;
        width: 1.5em;
      }
    }

    p {
      text-align: left;
    }
  }
}


#PasswordResetRequest {
  text-align: center;

  .FormWrapper {
    max-width: 400px;

    h1 {
      font-family: $heading-font;
      font-size: 1.25em;
    }
  }

  .PasswordResetRequest-instructions {
    margin-bottom: 20px;
  }

  .PasswordResetRequest-contact {
    margin-top: 25px;
  }
}

.LoginProvider {
  text-align: center;
}

#PasswordReset {
  text-align: center;
  
  .FormWrapper {
    max-width: 500px;

    .ConditionalMessage {
      margin-top: 10px;
    }
  }

  .PasswordReset-instructions {
    margin-bottom: 20px;
  }

  .PasswordReset-contact {
    margin-top: 25px;
  }
}
