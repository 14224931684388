
.CourseAssessment {
  text-align: center;
  margin-bottom: 60px;

  .CourseNavigation {
    margin-top: 100px;
  }

  .loading {
    width: 100%;
    text-align: center;
  }

  .status-message {
    display: inline-block;
    margin-top: 5px;
    padding: 10px;
    background-color: $notification-color;
    color: $constrast-text-color;
    font-weight: 500;
  }
}

.CourseAssessment-main.in-progress {
  .CourseNavigation {
    display: none;
  }
}

.CourseAssessment-heading {
  margin-bottom: 45px;

  h1 {
    font-weight: 500;
    margin-bottom: 3px;
    color: $primary-color;
  }

  h2 {
    margin-bottom: 15px;
    text-transform: none;
  }
}

.CourseAssessment-open {
  .message {
    font-size: 1.1em;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .date {
    font-weight: 500;
    color: $info-color;
  }
}

.CourseAssessmentHistory {
  margin-bottom: 45px;

  h3 {
    font-family: $primary-font;
    text-transform: none;
  }

  .no-records {
    margin-top: 15px;
    font-weight: 400;
  }
  
  ol {
    margin-left: 16px;
    padding-left: 0px;
    font-weight: 400;
  }

  .score {
    display: inline-block;
    font-weight: 500;
    width: 75px;
    margin-left: 5px;
  }

  button {
    padding: 0px;
    font-weight: 400;
    text-transform: none;
    text-decoration: underline;
    color: $link-color;
    padding-bottom: 3px;
  }
}

.CourseQuestion {
  margin-bottom: 45px;

  .question-text {
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .answers {
    label {
      margin-bottom: 5px;
    }

    .MuiFormControlLabel-root {
      margin-left: -1px;
    }

    .MuiButtonBase-root {
      padding: 0px;
      margin-right: 7px;
    }

    .MuiIconButton-root.Mui-checked {
      color: $primary-color;
    }

    .MuiSvgIcon-root {
      width: 0.75em;
      height: 0.75em;
    }
    
    .MuiTypography-root {
      font-size: 0.95em;
      font-weight: 400;
    }
  }

  .Mui-disabled span.MuiFormControlLabel-label {
    color: $base-text-color;
  }

  .scored-result {
    display: inline-block;
    width: 85px;
    text-align: center;
    margin-top: 10px;
    font-weight: 400;
    color: $constrast-text-color;
    padding: 5px;
  }

  .scored-result.correct {
    background-color: #BDE4A9;
  }

  .scored-result.incorrect {
    background-color: #EBBBBB;
  }

  .additional-info {
    margin-top: 15px;

    .label {
      font-weight: 500;
      margin-right: 5px;
    }
  }
}

.MultipleChoiceQuestion {
  label {
    display: block;
  }

  .MuiCheckbox-root {
    margin-top: -3px;
  }
}

.ClozeQuestion, .FreeAnswerQuestion {
  .MuiInputBase-input {
    padding: 0px;
    width: 100px;
    font-size: .9em;
  }
}

.CourseQuestions-status-bar {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: left;
  background-color: $primary-alt-color;
  border-top: 1px solid $constrast-light-color;
  z-index: 1000;
  padding-top: 15px;
  padding-bottom: 15px;

  .LoadingMessage {
    color: $constrast-light-color;
    font-size: 1em;
    font-weight: 500;
  }
}

.QuestionSync {
  @include flex-row();
  align-items: center;
  color: $constrast-light-color;

  .update {
    margin-right: 20px;
  }

  .answered {
    font-weight: 500;
  }

  .saved {
    font-size: 0.95em;
  }

  button {
    @include phone-device { 
      padding: 3px;
      font-size: 0.9em;
    }
  }

  button.save-btn {
    border-color: $constrast-light-color;
    color: $constrast-light-color;
    margin-right: 10px;
  }

  button.submit-btn {
    background-color: $constrast-light-color;
    color: $darker-text-color;
  }

  button.submit-btn.disabled {
    background-color: $light-bg-color;
    color: $disabled-text-color;
  }
}

.CourseAssessmentResults {
  .migrated-answers {
    font-weight: 400;
    background-color: $primary-light-color;
    padding: 10px;
    margin-bottom: 45px;
  }

  .migrated-no-answers {
    font-weight: 400;
    padding-bottom: 25px;
  }
}

.CourseAssessmentResults-score {
  margin-bottom: 25px;

  h4 {
    font-family: $primary-font;
    text-transform: none;
    font-size: 1.15em;
    margin-bottom: 10px;
  }

  .message {
    font-weight: 400;
  }

  .score {
    color: $info-color;
    font-weight: 500;
  }
}

.CourseAssessmentResults-answers {
  margin-top: 45px;

  h4 {
    font-family: $primary-font;
    text-transform: none;
    font-size: 1.15em;
    margin-bottom: 20px;
  }
}