.annotations-toggle {
  .display-btn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 25px;
  
    @include small-device {
      display: none;
    }
  }
  
  .display-btn-mobile {
    display: none; 
    
    @include small-device {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 25px;
    } 
  }

  .completed-margin {
    margin-top: -56px;
  }
  
  .incomplete-margin {
    margin-top: -5px;
  }
}

.annotations-container {
  margin-top: 4px;

  .annotations {
    .annotation-item {
      display: none;
      max-width: 810px;
      
      @include large-device {
        max-width: 525px;
      }
      
      @include desktop-device {
        max-width: 300px;
      }
      
      @include tablet-device {
        max-width: 300px;
      }
      
      @include medium-device {
        max-width: 900px; 
      }
      
      @include small-device {
        max-width: 300px; 
      }
    }
    
    .display-up-to-two {
      display: block;
    }
    
    .display-up-to-five {
      display: block; 
      
      @include small-device {
        display: none;
      }
    }
    
    .display-all {
      display: block; 
    }
    
    .annotation-list-item-text-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
    
    .time {
      margin-right: 15px;
      font-weight: 500;
      font-size: 0.8em;
    }
    
    .title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: $link-color;
      font-size: 0.9em;
    }
  }
  
  .show-more-btn {
    margin-top: 3px;
    text-align: center;
    
    button {
      font-weight: 100;
      text-transform: none;
      color: $primary-color;
      opacity: .6;
    }
  }
  
  .annotations-list-btns {
    margin-top: 20px;
    margin-bottom: 60px; 
    
    button {
      margin-right: 15px;
    }
  }
  
  .add-first {
    margin-top: 20px; 
  }
  
  .desktop {
    display: block; 
  
    @include small-device {
      display: none; 
    }
  }
  
  .mobile {
    display: none; 
  
    @include small-device {
      display: block; 
      margin-top: 25px;
    }
  }
}

.AnnotationDelete-popup {
  font-weight: 400;
  
  .what {
    margin-bottom: 10px;
  }
  
  .course-title {
    font-weight: 500;
    margin-bottom: 35px;
  }
  
  .content-actions {
    .keep {
      margin-right: 30px;
    }
    
    .remove {
      color: $secondary-color;
    }
  } 
}

.AnnotationView {
  margin-top: 20px; 
  margin-bottom: 40px;
  
  .pin {
    margin-top: 20px;
    font-weight: 400;
  }
  
  .link {
    padding: 0;
    color: $link-color;
    margin-bottom: 25px;
  }
    
  .annotation-view-text-container {
    display: flex;
    justify-content: space-between;
  }
  
  .action {
    margin-top: 25px;
    text-align: left;

    button {
      margin: 5px;
    }
  }
}

.AnnotationForm {
  margin-bottom: 40px;

  .Form-buttons {
    text-align: left; 
  }
  
  .heading {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .length {
    margin-top: 5px;
  }

  .long {
    font-weight: 500;
    color: red;
  }

  .MuiTextField-root {
    font-size: 1.9em;
  }
}

