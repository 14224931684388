.EventClassroom, .ReflectionClassroom {
  .LoadingMessage {
    text-align: center;
  }
}

.Classroom {
  @include flex-row();

  @include medium-device {
    display: block;
  }

  .Classroom-content {
    text-align: left;
    margin-right: 100px;
    flex: 1;

    @include medium-device {
      margin-right: 0px;
    }

    .status-message {
      display: inline-block;
      padding: 10px;
      background-color: $notification-color;
      color: $constrast-text-color;
      font-weight: 500;
      margin-bottom: 10px;

      @include small-device {
        font-size: 0.85em;
      }
    }

    .nav-home {
      display: none;
      margin-bottom: 35px;
      text-align: left;
  
      @include medium-device {
        display: block;
      }
  
      button {
        padding: 0px;
        color: $link-color;
  
        .Icon {
          margin-right: 7px;
        }
      }
    }
  }

  .ClassroomNavigation {
    margin-top: 100px;

    hr {
      width: 100%;
      border: 1px solid $light-divider-color;
    }
    
    .nav-actions {
      @include flex-row();
      justify-content: space-between;
      margin-top: 15px;
    }
  
    .nav-action {
      width: 45%;
      
      .nav-title {
        font-weight: 400;
      }
    }
  
    .nav-action.next {
      text-align: right;
    }
  
    button {
      margin-bottom: 10px;
      font-size: 0.8em;
    }
  }

  .Classroom-sidebar {
    text-align: left;
    width: 325px;

    @include large-device {
      width: 275px;
    }

    @include medium-device {
      margin-top: 55px;
      width: 100%;
    }
  }
}

.ClassroomHeading {
  margin-bottom: 45px;

  h1 {
    font-weight: 500;
    margin-bottom: 3px;
    color: $primary-color;
  }

  h2 {
    margin-bottom: 15px;
    text-transform: none;
  }

  .heading {
    @include flex-row();
  }
  
  .Feature-container {
    @include flex-col();
    width: 175px;
    height: 175px;
    margin-right: 20px;
    background-repeat: no-repeat;
    background-size: 100% auto;
    
    @include small-device {
      max-height: 145px;
    }
  }
  
  .Title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    .runtime {
      font-weight: 300;
      margin-top: 10px;
    }
  }
}

.sgrHeading {
  margin-bottom: 30px;

  h1 {
    font-weight: 500;
    margin-bottom: 3px;
    color: $primary-color;
    text-transform: none;
    font-size: 1.35em;
  }
}

.ClassroomOutline {
  .ClassroomOutline-heading {
    @include flex-row();
    margin-bottom: 15px;
    
    h3 {
      text-transform: none;
      font-size: 1.3em;
      flex: 1;
    }

    button {
      font-size: 0.8em;
      text-transform: none;
      padding: 3px 8px;
      margin-right: 5px;
      margin-left: 5px;
    }
  }
}

.ClassroomOutline-list {
  padding-left: 20px;
  margin-left: 5px;
  margin-top: 25px;
  border-left: 1px solid $med-border-color;
  
  .tab {
    @include flex-col;
    cursor: pointer;
  
    .tab-heading {
      @include flex-row();
      
      .title {
        margin-left: -28px;
        margin-right: 12px;
      }
    }
    
    h4 {
      font-family: $primary-font;
      text-transform: none;
      flex: 1;
      font-weight: 500;
    }
    
    h4:hover {
      color: $secondary-color; 
    }
    
    .parts {
      padding-top: 10px; 
      padding-bottom: 7px; 
      
      .items {
        @include flex-row(); 
        
        a {
          font-weight: 400;
        }
        
        a:hover {
          color: $secondary-color;
        }
        
        .active {
          color: $secondary-color; 
          text-decoration: none;
        }
      }
    }
  }
     
  .resource-parts {
    padding-top: 10px; 
    padding-bottom: 7px; 
    
    .items {
      @include flex-row(); 
    }
    
    .resource-item {
      margin-left: 0px;
    }
  }
  
  span {
    margin-left: 10px; 
    margin-right: 10px; 
  }
  
  .Icon {
    display: inline-block;
    min-width: 16px;
    height: 16px;
    margin-right: 5px;
    margin-top: 1px;
    background-color: $constrast-light-color;
  }
  
  .Icon.selected {
    color: $secondary-color;
  }
  
  .selected {
    font-weight: 500;
    color: $primary-color;
    margin-bottom: 7px;
  }
  
  a, span {
    display: block;
    margin-bottom: 7px;
    font-weight: 400;
  }
  
  span {
    color: $disabled-text-color;
  }

  .playing {
    @include flex-row(); 
    width: 100%;
    justify-content: space-between;
  }

  .runtime {
    font-weight: 300;
  } 
}

.ClassroomVideo {
  .video {
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
    margin-bottom: 5px;

    iframe {
      border: 0;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.ClassroomAudio {
  margin-top: 75px;

  .MuiSlider-valueLabelOpen {
    margin-top: 60px;
  }

  .MuiSlider-valueLabel {
    background-color: rgba(0,0,0,0);
    color: black; 
  }

  .runtime-label {
    display: flex;
    justify-content: flex-end;
    margin-top: -4px;
    opacity: .6;
  }

  .audioControls {
    max-width: 100%;
   
    @include breakpoint(1320) {
      max-width: 685px;
    }

    @include large-device {
      max-width: 675px;
    }

    @include breakpoint(1080) {
      max-width: 580px;
    }

    @include desktop-device {
      max-width: 600px;
    }
    
    @include medium-device {
      max-width: 100%;
    }
  }

  .audioControlPanel {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .playBtn {
    width: 250px;
  }

  .audioSkipBtn {
    display: flex; 
    flex-direction: column;
    text-align: center;
    width: 250px;
  }
  
  .audioSubControlPanel {
    margin-top: 25px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    justify-content: right;

    .downloadBtn {
      margin-left: 6px;
      margin-right: 10px;
    }

    .annotationsBtn {
      margin-left: 10px;
    }
  }
  
  .audioFFBtn {
    display: flex; 
    flex-direction: column;
    
    button {
      padding: 5px 0 0 0;
      font-size: 1.1em;
      width: 10px;
    }
  }

  .audioSkipBtnLabel {
    text-transform: none;
  }
}