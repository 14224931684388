
.AppHeader-secondaryNavMenu.authenticated {
  @include flex-row();
  align-items: center;

  .AppHeader-menuButton {
    color: $tertiary-light-color;
    font-size: 3em;
    cursor: pointer;
  }
}

.AppHeader-member {
  @include flex-row();
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 1px;

  @include medium-device { 
    padding: 3px 5px;
  }
}

.AppHeader-memberMenu {
  a {
    text-decoration: none;
  }

  .logout-item a {
    color: $secondary-color;
  }

  div.AppHeader-listItem {
    padding-bottom: 5px;
  }
}