
.FeatureHeading.hidden {
  display: none;
}

.FeatureHeading {
  margin-bottom: 0px;
  height: 300px;
  position: relative;
  overflow: hidden;

  @include small-device {
    height: 260px;
  }

  @include phone-device {
    height: 200px;
  }

  .background img {
    width: 100%;
    position: relative;
    float: left;
    z-index: 10;
    overflow: hidden;
    left: 0;

    @include large-device {
      width: 120%;
      left: -10%;
    }

    @include desktop-device {
      width: 140%;
      left: -20%;
    }

    @include medium-device {
      width: 160%;
      left: -30%;
    }

    @include tablet-device {
      width: 200%;
      left: -50%;
    }

    @include small-device {
      width: 260%;
      left: -80%;
    }
  }

  .background .fill-blue, .background .fill-black, .background .fill-white, .background .fill-default {
    width: 100%;
    height: 300px;
    position: relative;
    float: left;
    z-index: 10;
    overflow: hidden;
    left: 0;

    @include small-device {
      height: 260px;
    }
  
    @include phone-device {
      height: 200px;
    }
  }

  .background .fill-blue, .background .fill-default {
    background-color: $primary-dark;
  }

  .background .fill-black {
    background-color: #000000;
  }

  .background .fill-white {
    height: 299px;
    background-color: #FFFFFF;
    border-bottom: 1px solid $light-border-color;

    @include small-device {
      height: 259px;
    }
  
    @include phone-device {
      height: 199px;
    }
  }

  .content {
    z-index: 100;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .StandardHeading-button, .MediaHeading-button {
    color: $constrast-light-color;
    border-color: $constrast-light-color;
  }

  .content.fill-blue, .content.fill-black, .content.fill-white {
    background-color: inherit;
  }

  .content.fill-white {
    h2, p {
      color: $constrast-text-color;
    }

    .StandardHeading-button, .MediaHeading-button {
      color: $constrast-text-color;
      border-color: $constrast-text-color;
    }
  }
}

.FeatureHeading.media {
  @include small-device {
    height: 500px;
  }

  .background .fill-blue, .background .fill-black, .background .fill-default {
    @include small-device {
      height: 500px;
    }
  }

  .background .fill-white {
    @include small-device {
      height: 499px;
    }
  }
}

.FeatureHeading.donation {
  @include small-device {
    height: 540px;
  }

  @include phone-device {
    height: 580px;
  }

  .background .fill-blue, .background .fill-black, .background .fill-default {
    @include small-device {
      height: 540px;
    }

    @include phone-device {
      height: 580px;
    }
  }

  .background .fill-white {
    @include small-device {
      height: 539px;
    }

    @include phone-device {
      height: 579px;
    }
  }
}

.FeatureHeading .StandardHeading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  h2 {
    color: $constrast-light-color;
    font-size: 1.4em;
    font-weight: 500;
    margin-bottom: 20px;
    font-family: $heading-font;
    text-transform: inherit;

    @include small-device {
      padding: 0 20px;
      font-size: 1.4em;
    }

    @include phone-device {
      margin-bottom: 10px;
    }
  }

  h2.without-desc {
    margin-bottom: 25px;
  }

  p {
    color: $constrast-light-color;
    font-size: 0.9em;
    font-weight: 400;
    margin: 0 auto;
    margin-bottom: 40px;
    font-family: $primary-font;
    max-width: 750px;

    @include medium-device {
      font-size: 0.9em;
      padding: 0 20px;
    }

    @include small-device {
      display: none;
    }
  }
}

.FeatureHeading .MediaHeading {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .MediaHeading-content {
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;

    @include small-device {
      display: block;
    }
  }

  .media {
    width: 350px;

    @include medium-device {
      padding-left: 15px;
    }

    @include tablet-device {
      width: 300px;
    }

    @include small-device {
      width: 80%;
      margin: 0 auto;
      margin-top: 30px;
      margin-bottom: 20px;
      padding-left: 0px;
    }

    @include phone-device {
      width: 90%;
    }

    img {
      object-fit: cover;
    }
  }

  .main {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding-left: 30px;

    @include medium-device {
      padding-left: 15px;
      padding-right: 15px;
    }

    @include tablet-device {
      width: 50%;
    }

    @include small-device {
      text-align: center;
      margin: 0 auto;
      width: 80%;
    }

    @include phone-device {
      width: 90%;
    }

    .action {
      text-align: left;

      @include small-device {
        text-align: center;
        margin-bottom: 30px;
      }
    }
  }

  h2 {
    color: $constrast-light-color;
    font-weight: 500;
    margin-bottom: 10px;
    font-family: $heading-font;
    font-size: 1.4em;
    text-transform: inherit;
  }

  h2.without-desc {
    margin-bottom: 25px;
  }

  p {
    color: $constrast-light-color;
    font-size: 0.9em;
    font-weight: 400;
    margin-bottom: 35px;
    font-family: $primary-font;
  }
}

.FeatureHeading .DonationHeading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  .DonationHeading-content {
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;

    @include small-device {
      display: block;
    }
  }

  .main {
    flex: 1;
    min-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding-left: 30px;
    padding-right: 30px;

    @include desktop-device {
      min-width: 400px;
    }

    @include tablet-device {
      min-width: inherit;
      padding-left: 15px;
      padding-right: 15px;
    }

    @include small-device {
      text-align: center;
      margin: 0 auto;
      width: 80%;
    }

    @include phone-device {
      width: 90%;
    }
  }

  .donation {
    width: 290px;
    height: 220px;
    background-color: $light-bg-color;
    padding: 15px;
    margin-right: 30px;
    text-align: left;

    @include small-device {
      margin: 0 auto;
      margin-top: 30px;
    }

    @include small-phone-device {
      width: 270px;
    }

    .frequency, .amount {
      margin-bottom: 10px;
    }

    .action {
      text-align: center;
    }

    h4 {
      font-size: 1em;
      font-family: $primary-font;
      text-transform: inherit;
      font-weight: 500;
      margin-bottom: 5px;
    }

    .MuiFormGroup-root {
      display: flex;
      flex-direction: row;
    }

    label  {
      margin-bottom: 5px;

      .MuiButtonBase-root {
        padding: 7px;
      }

      .MuiTypography-root {
        font-size: 1em;
        font-family: $primary-font;
      }

      .MuiSvgIcon-root {
        font-size: .7em;
      }
    }

    .other-amount {
      display: block;

      label {
        margin-bottom: 0px;
      }

      .MuiFormControlLabel-root {
        margin-right: 0px;
      }

      .MuiFormControlLabel-label {
        display: none;
      }
    }

    input[type=text] {
      font-size: 0.85em;
      font-family: $primary-font;
      width: 115px;
    }
  }

  .donation.no-frequency {
    height: 150px;

    .frequency {
      display: none;
    }
  }

  h2 {
    color: $constrast-light-color;
    font-weight: 500;
    margin-bottom: 25px;
    font-size: 1.4em;
    font-family: $heading-font;
    text-transform: inherit;
  }

  p {
    color: $constrast-light-color;
    font-weight: 400;
    font-size: 0.9em;
    margin-bottom: 35px;
    font-family: $primary-font;
  }
}

.FeatureHeading.media, .FeatureHeading.donation {
  .background.image {
    img {
      display: block;

      @include small-device {
        display: none;
      }
    }

    .fill-default {
      display: none;

      @include small-device {
        display: block;
      }
    }
  }

  @include small-device {
    .content.overlay {
      background-color: inherit;
    }
  }
}

.FeatureHeading.media.text-center, .FeatureHeading.donation.text-center {
  h2, p, .action {
    text-align: center;
  }
}