
.EventCard {
  flex: 1 1 0px;
  padding: 8px;
  cursor: pointer;

  @include small-device {
    padding: 0px;
  }

  .feature {
    @include flex-col();
    min-width: 200px;
    min-height: 175px;
    background-repeat: no-repeat;
    background-size: 100% auto;
    margin-bottom: 10px;
    position: relative;
    border-radius: 3px;

    @include small-device {
      min-height: 225px;
    }

    @include phone-device {
      min-height: 295px;
    }

    @include small-phone-device {
      min-height: 235px;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $overlay-color;
      opacity: 0.2;
      border-radius: 3px;
    }

    .actions {
      height: 25px;
      width: 100%;
      flex: 1;
      position: relative;

      .favorite {
        font-size: 1.8em;
        position: absolute;
        top: 7px;
        right: 10px;
      }

      .favorite.not-favorite {
        color: $overlay-light-color;
        opacity: 0.7;
      }

      .favorite.is-favorite {
        color: $secondary-color;

        svg {
          path.fa-secondary {
            color: $overlay-light-color;
            opacity: 0.6;
          }
        }
      }
    }

    .progress-bar {
      height: 5px;
      position: relative;

      .progress {
        height: 5px;
        background-color: $progress-color;
        position: absolute;
      }

      .background {
        width: 100%;
        height: 5px;
        background-color: $overlay-color;
        position: absolute;
      }
    }

    .info-bar {
      @include flex-row();
      background-color: $transparent-overlay;
      color: $constrast-light-color;
      align-self: flex-end;
      width: 100%;
      font-size: 90%;
      font-weight: 500;
      text-align: right;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;

      .rating {
        padding: 5px;
        font-size: 80%;
      }

      .type {
        flex: 1;
        padding: 3px 5px;
        font-weight: 500;
      }
    }
  }

  .description {
    
    .title {
      font-weight: 500;
      font-size: 1.05em;
      font-family: $heading-font;
      text-transform: uppercase;
      letter-spacing: 0.2px;
      color: $primary-color;
      margin-bottom: 2px;
      line-height: 22px;
      max-height: 44px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .subtitle {
      font-size: 1em;
      font-weight: 400;
      line-height: 21px;
      max-height: 42px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .instructor {
      margin-top: 12px;
      margin-bottom: 7px;
      font-size: 0.9em;
      color: $heading-color;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .meta-tags {
      font-weight: 500;
      font-size: 0.9em;

      .Icon {
        font-size: 1.2em;
      }

      .highlight {
        color: $secondary-color;
      }
    }
  }
}

.EventCard:hover {
  box-shadow: 0px 1px 1px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);

  @include small-device {
    box-shadow: none;
  }
}

.EventCard.placeholder:hover {
   box-shadow: none;
 }