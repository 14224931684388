
.EventList-container {
  text-align: left;
  
  .spacer {
    margin-top: 60px;
  }
}

.EventList {
  margin-top: 20px;
  margin-bottom: 80px;

  h1, h2 {
    font-size: 1.4em;
    letter-spacing: 2px;
    color: $primary-color;
    text-align: center;
    margin-bottom: 40px;
  }

  .list-description {
    padding: 8px;
    margin-bottom: 20px;

    @include small-device {
      padding: 0px;
    }

    p {
      margin-bottom: 25px;
    }
  }

  .more-link {
    margin-top: 40px;
    margin-right: 10px;
    text-align: right;

    a, span {
      display: inline-block;
      text-decoration: none;
      font-size: 1.15em;
      font-weight: 400;
      color: $base-text-color;
      cursor: pointer;
    }
  }

  .more-link:hover {
    a, span {
      color: $secondary-color;
    }
  }
}

.EventList-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .spacer {
    flex: 1 0 20px;
  }

  .EventCard {
    max-width: 300px;
  }
}

.EventList-row.size-xlg {
  .EventCard {
    min-width: 17%;
  }
}

.EventList-row.size-lg {
  .spacer {
    flex: 1 0 15px;
  }

  .EventCard {
    min-width: 22%;
  }
}

.EventList-row.size-md {
  .spacer {
    flex: 1 0 10px;
  }

  .EventCard {
    min-width: 30%;
  }
}

.EventList-row.size-sm {
  .spacer {
    flex: 1 0 10px;
  }

  .EventCard {
    min-width: 45%;
  }
}

.EventList-row.size-xsm {
  .EventCard {
    min-width: 100%;
    max-width: 100%;
  }
}