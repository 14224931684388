.Magdala-Courses {
    text-align: center;
    max-width: 1240px;
    margin: 70px auto 0;

      @include large-device { 
        padding: 0 60px;
      }

      @include desktop-device {
        padding: 0 25px;
      }

      @include phone-device { 
        padding: 0 15px;
        margin: 10px auto 0; 
      }
      
    .MainContent-container.linked-list {
      margin-top: 45px;
    }

    .section-header {
      margin-bottom: 20px;
      letter-spacing: 2px;
      max-width: $free-floating-max-width;
      margin: 0 auto;
      text-align: center;
    }
  
    p.section-description {
      font-size: 1.2em;
      font-weight: 400;
      margin-bottom: 60px;
      max-width: $free-floating-max-width;
      margin: 0 auto;
      text-align: center;
    }

    div.section-description {
      font-size: 1.2em;
      font-weight: 400;
      margin-bottom: 60px;
      max-width: $free-floating-max-width;
      margin: 0 auto;
      text-align: center;
    }

    div.section-description-no-divider {
      font-size: 1.2em;
      font-weight: 400;
      max-width: $free-floating-max-width;
      margin: 25px auto 60px;
      text-align: center;
    }
  
    .description {
      margin-bottom: 60px;
    }
  
    .divider-bar {
      margin: 0 auto;
      width: 100px;
      margin-top: 20px;
      margin-bottom: 20px;
      border-bottom: 2px solid $primary-light-color;
    }
  
    .container {
      padding-left: 100px;
      padding-right: 100px;
      text-align: center;
  
      @include large-device {
        padding-left: 60px;
        padding-right: 60px;
      }
  
      @include desktop-device {
        padding-left: 25px;
        padding-right: 25px;
      }
  
      @include phone-device { 
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  
    .container-content {
      max-width: 1240px;
      margin: 0 auto;
      text-align: left;
    }

    .Magdala-Courses-CallToAction {
      padding: 0px 30px 40px 30px;
      margin-bottom: 60px;

      .container-content {
        text-align: center;
      }

      button {
        color: white;
        background-color: $primary-alt-color;
        border-color: $constrast-light-color;
        margin: 30px 0;
      }

      button:hover {
        background-color: $primary-alt-hover;
        border-color: $constrast-light-color;
      }
    }
}