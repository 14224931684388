.Magdala-Toolbar {
  margin: 0 auto;
  padding: 40px 100px 10px;
  max-width: 1240px;
  text-align: left;
  
  @include large-device { 
    padding: 40px 60px 10px;
  }
  
  @include desktop-device {
    padding: 40px 25px 10px;
  }
  
  @include small-device { 
    padding: 20px 15px 10px;
    display: none;
  }
  
  > div {
    height: 27px;
  }
}

.Magdala-Toolbar-mainNav {
  @include flex-row();
  flex: 1;
  
  @include small-device { 
    display: none;
  }
  
  .menu-item {
    margin-top: 2px;
    padding-bottom: 5px;
    
    a {
      text-decoration: none;
      color: $base-text-color;
      font-weight: 500;
    }
  }
  
  .menu-item.active {
    a {
      color: $secondary-color;
    }
  }
  
  .vertical-divider {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.mobileNavAlt {
  display: none;
  text-align: left;
  margin-left: 25px; 
  
  @include small-device {
    display: block;  
    margin-top: 40px;
  }
  
  @include phone-device { 
    margin-left: 15px;
  }
  
  button {
    text-transform: none;
  }
}

.MuiMenuItem-root.nav-alt {
  a {
    text-decoration: none;
    color: $primary-color;
  }
}

.MuiMenuItem-root.nav-alt:hover {
  background-color: $ar-dark-blue;
  color: $constrast-light-color;
  
  a {
    color: $constrast-light-color;
  }
}