.AnnualReport-section.closing {
  background-color: $ar-dark-blue;
  margin-bottom: 80px;
  @include angle('before', false, 2.0deg);
  @include angle('after', false, 2.0deg);
}

.AnnualReport-section.closing .AnnualReport-content {
  @include flex-col();
  align-items: center;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 50px;

  .SectionHeader {
    h3 {
      color: $constrast-light-color;
      font-size: 1.8rem;
      margin-bottom: 50px;

      @include small-device {
        font-size: 1.4rem;
      }

      .emphasis {
        color: $ar-gold;
        text-transform: none;
        font-size: 2.5rem;

        @include small-device {
          font-size: 2rem;
        }
      }
    }
  }

  p {
    font-family: $alt-heading-font;
    color: $constrast-light-color;
    font-size: 1.5em;
    margin-bottom: 50px;

    @include small-device {
      font-size: 1.2rem;
    }
  }

  .VideoPopupCard {
    margin-bottom: 30px;
    width: 60%;

    @include medium-device {
      width: 70%;
    }

    @include tablet-device {
      width: 80%;
    }

    @include small-device {
      width: 90%;
    }
  }

  .VideoPopupCard-image {
    .Icon {
      font-size: 4em;
    }
  }

  button {
    margin: 0 auto;
    font-size: 1.4em;
    font-weight: 500;
    width: 250px;
    text-transform: none;
    border-color: $constrast-light-color;
    color: $constrast-light-color;

    @include small-device {
      font-size: 1.2rem;
      width: 215px;
    }
  }
}