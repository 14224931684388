@import './header/ExploreNav';
@import './header/SecondaryAuth';
@import './header/SecondaryNonAuth';

.App.display-content-only {
  .AppHeader {
    display: none !important;
  }

  #HeaderOffset {
    display: none;
  }
}

#HeaderOffset {
  display: block;
  height: 79px;
  
  @include tablet-device {
    height: 68px;
  }
  
  @include small-device {
    height: 62px;
  }
}

header.AppHeader {
  background-color: $app-bar-color;
  color: $constrast-light-color;

  > div {
    padding-left: 5px;
    padding-right: 0px;

    @include tablet-device { 
      min-height: inherit;
    }
  }
}

.AppHeader-logo {
  display: block;
  text-align: left;
  min-width: 170px;
  padding: 12px 0 7px 7px;

  @include small-device { 
    padding: 8px 0 5px 0;
  }

  img {
    max-width: 150px;
    height: auto;
    border: 0px;
    outline: none;

    @include tablet-device { 
      max-width: 120px;
    }
  }
}

.AppHeader-secondaryNavMenu {
  justify-content: flex-end;
}

.AppHeader-navigation {
  @include desktop-device {
    display: none;
  }

  button {
    color: $constrast-light-color;
    background-color: $primary-alt-hover;
    font-weight: 500;
    font-size: 0.95em;
    margin-right: 10px;
  }

  button:hover {
    background-color: $primary-alt-hover;
  }
}
