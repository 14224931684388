
.ManageEmailPreferences {
  text-align: center;

  > div {
    margin: 0 auto;
    max-width: 700px;
  }
}

.EmailConfirmation {
  text-align: left;

  h1 {
    margin-bottom: 15px;
  }

  .EmailConfirmation-email {
    margin-top: 25px;
  }

  .EmailConfirmation-submit {
    margin-top: 35px;
    text-align: center;
  }
}

.ManageEmailPreferences-content {
  text-align: left;

  h1 {
    margin-bottom: 15px;
  }

  h3 {
    font-family: $primary-font;
    font-size: 1.1em;
    margin-bottom: 15px;
  }

  h3.account-notifications-heading {
    text-transform: none;
    font-size: 1.05em;
  }

  .ManageEmailPreferences-heading-description, .ManageEmailPreferences-unsub-description {
    margin-bottom: 45px;
    font-weight: 400;
  }

  .ManageEmailPreferences-unsub-description {
    padding: 10px;
    background-color: $info-color;
    color: $constrast-light-color;
  }

  .ManageEmailPreferences-user {
    margin-bottom: 35px;

    span {
      font-weight: 500;
    }
  }

  .ManageEmailPreferences-account {
    margin-bottom: 55px;

    p.message {
      margin-bottom: 25px;
    }

    p.message.emphasize {
      font-weight: 400;
    }
  }

  .ManageEmailPreferences-account.not-unsub {
    margin-top: 55px;
  }

  .setting {
    @include flex-row();
    margin-bottom: 25px;

    .selection {
      margin-right: 30px;
    }

    .info {
      padding: 8px;
    }

    .title {
      font-size: 1.05em;
      font-weight: 500;
      margin-bottom: 10px;
    }
  }

  .ManageEmailPreferences-save .LoadingMessage {
    margin-top: 10px;
    font-size: 0.95em;
    font-weight: 500;
  }
}