
.App.page-course-assessment, .App.display-content-only {
  .AppFooter {
    display: none !important;
  }
}

.AppFooter {
  background-color: $primary-color;
  color: $constrast-light-color;
}

.AppFooter-main {
  @include spaced-flex-row();
  margin: 0 auto;
  padding: 25px 100px 15px;
  max-width: 1240px;
  text-align: left;

  @include large-device { 
    padding: 25px 60px 15px;
  }

  @include desktop-device { 
    padding: 25px 25px 15px;
  }

  @include small-device { 
    display: block;
  }

  @include phone-device { 
    padding: 25px 15px 15px;
  }

  h3 {
    color: $header-menu-hover;
    font-size: 1.1em;

    @include desktop-device { 
      font-size: 1em;
    }

    @include medium-device { 
      font-size: 1.15em;
    }

    @include tablet-device { 
      font-size: 1em;
    }

    @include small-device { 
      font-size: 1.15em;
    }
  }

  p, ul {
    font-size: 0.95em;

    @include large-device { 
      font-size: 0.9em;
    }

    @include medium-device { 
      font-size: 1em;
    }

    @include tablet-device { 
      font-size: 0.9em;
    }

    @include small-device { 
      font-size: 1em;
    }
  }
}

.AppFooter-branding {
  @include flex-col();
  width: 28%;

  @include large-device { 
    width: 25%;
  }

  @include medium-device { 
    display: none;
  }

  .logo {
    img {
      max-width: 180px;
      height: auto;
      border: 0px;
      outline: none;

      @include large-device { 
        max-width: 150px;
      }
    }
  }

  .description {
    margin-top: 10px;
    margin-bottom: 30px;
    line-height: 20px;
  }
}

.AppFooter-navigation {
  @include flex-col();
  width: 35%;

  @include medium-device { 
    width: 50%;
  }

  @include small-device { 
    width: 100%;
    margin-bottom: 40px;
  }

  .content-links {
    @include spaced-flex-row();
    margin-bottom: 20px;

    > div {
      width: 46%;
    }

    h3 {
      margin-bottom: 20px;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        margin-bottom: 5px;
      }

      a {
        text-decoration: none;
        color: $constrast-light-color;
      }
    }
  }

  .share-links {
    @include flex-row();
    flex-wrap: wrap;

    a {
      margin-top: 5px;
      color: $constrast-light-color;
      text-decoration: none;
      font-weight: 400;
    }

    a:hover {
      text-decoration: underline;
    }
    
    .Icon {
      font-size: 1.3em;
      margin-right: 15px;
    }
  }
}

.AppFooter-contact {
  @include flex-col();
  width: 25%;

  @include large-device { 
    width: 30%;
  }

  @include medium-device { 
    width: 40%;
  }

  @include small-device { 
    width: 100%;
    margin-bottom: 10px;
  }

  h3 {
    margin-bottom: 20px;
  }

  .address, .phone, .email {
    margin-bottom: 10px;
  }

  .Icon {
    width: 25px;
  }

  .AppFooter-contactButton {
    margin-top: 15px;
    
    Button {
      background-color: $tertiary-light-color;
    }
  }
}

.AppFooter-contactButton {
  button {
    padding: 4px 22px;
    font-size: 0.9rem;
    color: $darker-text-color;
  }
}

.AppFooter-copyright {
  margin: 0 auto;
  padding:  20px 100px 15px;
  max-width: 1240px;
  text-align: left;
  font-size: 0.9em;

  @include large-device { 
    padding: 20px 60px 15px;
  }

  @include desktop-device { 
    padding: 20px 25px 15px;
  }

  @include phone-device { 
    padding:  20px 15px 15px;
  }
}