
.App.display-content-only {
  #PageMessage {
    display: none;
  }
}

#PageMessage {
  padding: 20px 100px;
  color: $constrast-light-color;
  font-weight: 400;
  font-size: 1.2em;
  text-align: center;

  @include large-device { 
    padding: 20px 60px;
  }

  @include desktop-device { 
    padding: 20px 25px;
  }

  @include phone-device { 
    padding: 10px 15px;
    padding-top: 20px;
  }
}

#PageMessage.button-hide {
  .action {
    display: none;
  }
}

#PageMessage.button-show {
  @include centered-flex-row();

  @include small-device {
    display: inherit;
  }

  .message {
    margin-right: 20px;

    @include small-device {
      margin-right: 0px;
      margin-bottom: 10px;
    }
  }

  .action {
    @include small-device {
      margin-bottom: 10px;
    }
  }
}

#PageMessage.info {
  background-color: $info-color;
}

#PageMessage.success {
  background-color: $success-color;
}

#PageMessage.warning {
  background-color: $warning-color;
}

#PageMessage.promo {
  background-color: $promo-color;
  color: $constrast-text-color;

  button {
    border-color: $constrast-text-color;
    color: $constrast-text-color;
  }
}

#PageMessage.error {
  background-color: $error-color;
}