
/*
Quote Interstitial
*/

.AnnualReport-section.quote.interstitial .AnnualReport-content .container {
  text-align: center;
  
  .Quote {
    .icon {
      margin-top: -20px;
      margin-left: -75px;

      @include tablet-device {
        margin-left: 0px;
      }

      svg {
        path {
          fill: $constrast-light-color;
        }
      }
    }

    .Quote-content {
      color: $constrast-light-color;

      .text {
        font-size: 1.25em;
        font-family: $alt-heading-font;

        @include tablet-device {
          font-size: 1.15em;
        }
      }
    }
  }

  button {
    margin: 0 auto;
    font-size: 1.2em;
    font-weight: 500;
    width: 230px;
    text-transform: none;
    color: $constrast-light-color;
    border-color: $constrast-light-color;
  }
}


/*
Image Quote Interstitial
*/

.AnnualReport-section.image-quote.interstitial .AnnualReport-content .container {
  @include flex-row();
  justify-content: space-between;

  @include medium-device {
    @include flex-col();
  }

  .col1, .col2, .row1 {
    @include flex-col();
    text-align: center;
    width: 40%;

    @include medium-device {
      width: 100%;
    }

    img {
      width: 100%;
      height: auto;
      margin-top: -75px;
      margin-bottom: 30px;

      @include medium-device {
        width: 50%;
        margin: 0 auto;
        margin-top: -55px;
      }

      @include tablet-device {
        width: 60%;
        margin-top: -45px;
      }

      @include small-device {
        width: 70%;
      }
    }
  }

  .Quote {
    width: 50%;

    @include medium-device {
      width: 70%;
      padding-top: 50px;
    }

    @include tablet-device {
      width: 80%;
    }

    .icon {
      margin-top: -20px;

      @include medium-device {
        margin-left: -75px;
      }

      @include tablet-device {
        margin-left: -50px;
      }

      @include phone-device {
        margin-left: -45px;
      }

      svg path {
        fill: $constrast-light-color;
      }
    }

    .Quote-content {
      color: $constrast-light-color;

      .text {
        font-size: 1.25em;
        font-family: $alt-heading-font;

        @include medium-device {
          text-align: center;
        }
      }

      .name {
        @include medium-device {
          text-align: center;
        }
      }
    }
  }

  button {
    margin: 0 auto;
    font-size: 1.1em;
    font-weight: 500;
    width: 200px;
    text-transform: none;
    color: $constrast-light-color;
    border-color: $constrast-light-color;
  }

  .large-display {
    @include medium-device {
      display: none;
    }
  }

  .small-display {
    display: none;
    text-align: center;

    button {
      margin: 0 auto;
    }

    @include medium-device {
      display: block;
      padding-bottom: 30px;
    }
  }
}


/*
Image Content Interstitial
*/

.AnnualReport-section.image-content.interstitial .AnnualReport-content .container {
  @include flex-row();
  justify-content: space-between;
  margin-bottom: 70px;

  @include tablet-device {
    @include flex-col();
    justify-content: inherit;
    padding-bottom: 40px;
  }

  img {
    width: 100%;
    margin-top: -80px;
    margin-bottom: 30px;

    @include medium-device {
      margin-top: -60px;
    }

    @include breakpoint(880) {
      margin-top: 20px;
    }

    @include tablet-device {
      width: 70%;
      margin-top: -50px;
    }
  }

  p {
    font-family: $primary-font;
    font-size: 1em;
    font-weight: 300;
    text-align: left;
    margin-bottom: 15px;
    color: $constrast-light-color;
  }

  .emphasis {
    font-family: $alt-heading-font;
    font-size: 1.2em;
    font-weight: 500;
    color: $constrast-light-color;
  }

  button {
    margin: 0 auto;
    font-size: 1.1em;
    font-weight: 500;
    width: 200px;
    text-transform: none;
    color: $constrast-light-color;
    border-color: $constrast-light-color;
  }

  .large-display {
    @include tablet-device {
      display: none !important;
    }
  }

  .small-display {
    display: none;

    @include tablet-device {
      display: block;
      text-align: center;
    }

    .action {
      @include tablet-device {
        margin-top: 20px;
      }
    }
  }
}

.AnnualReport-section.image-content.interstitial.left .AnnualReport-content .container {
  .col1 {
    width: 45%;
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .col2 {
    @include flex-col();
    width: 50%;
    margin-bottom: 20px;
    text-align: center; 
  }
}

.AnnualReport-section.image-content.interstitial.right .AnnualReport-content .container {
  .col1 {
    @include flex-col();
    width: 50%;
    margin-bottom: 20px;
    text-align: center; 
  }

  .col2 {
    width: 45%;
    padding-top: 30px;
    padding-bottom: 20px;
  }
}


/*
Video Popup Interstitial
*/

.AnnualReport-section.video-popup.interstitial .AnnualReport-content .container {
  @include flex-row();
  justify-content: space-between;
  margin-bottom: 140px;

  .VideoPopupCard {
    width: 100%;
    margin-top: -80px;
    margin-bottom: 30px;

    @include medium-device {
      margin-top: -65px;
    }

    @include tablet-device {
      width: 70%;
      margin-top: -55px;
    }
  }

  .VideoPopupCard-image {
    .Icon {
      font-size: 4em;
    }
  }

  button {
    margin: 0 auto;
    font-size: 1.2em;
    font-weight: 500;
    width: 230px;
    text-transform: none;
    color: $constrast-light-color;
    border-color: $constrast-light-color;
  }

  p {
    color: $constrast-light-color;
    font-family: $alt-heading-font;
    font-size: 1.25em;
    font-weight: 600;

    @include tablet-device {
      margin-bottom: 20px;
      width: 95%;
    }
  }

  .large-display {
    @include tablet-device {
      display: none !important;
    }
  }

  .small-display {
    display: none;

    @include tablet-device {
      @include flex-col();
      align-items: center;
      text-align: center;
      padding-bottom: 30px;
    }

    .action {
      @include tablet-device {
        margin-top: 20px;
      }
    }
  }
}

.AnnualReport-section.video-popup.interstitial.left .AnnualReport-content .container {
  .col1 {
    width: 40%;
    padding-top: 50px;
    padding-right: 20px;
    padding-bottom: 30px;
  }

  .col2 {
    @include flex-col();
    width: 55%;
    margin-bottom: 20px;
    text-align: center;
  }
}

.AnnualReport-section.video-popup.interstitial.right .AnnualReport-content .container {
  .col1 {
    @include flex-col();
    width: 55%;
    margin-bottom: 20px;
    text-align: center;
  }

  .col2 {
    width: 35%;
    padding-top: 50px;
    padding-right: 20px;
    padding-bottom: 30px;
  }
}
