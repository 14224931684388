.AnnualReportExpired-content {
  margin: 10% auto;
  max-width: 1024px;
  padding: 20px;

  h3 {
    font-family: $alt-heading-font;
    font-size: 2em;
    font-style: italic;
    text-transform: none;
    margin: 15px;
  }
}