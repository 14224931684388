
#MemberProfile {
  text-align: center;

  > div {
    margin: 0 auto;
  }

  .MemberProfile-deletion {
    margin-top: 20px;
    font-size: 0.95em;
    font-weight: 400;
  }
}

#MemberProfile-edit {
  .MuiIconButton-edgeEnd {
    margin-right: 0px;
  }
}

.MemberProfile-section {
  text-align: left;

  .MemberProfile-dataSummary {

    .avatar {
      color: $base-text-color;
      background-color: $invisible-background;
    }

    div[role="button"] {
      margin-right: 0px;
    }
  }

  .MemberProfile-dataType {
    color: $primary-color;
    min-width: 90px;
    font-weight: 500;
  }

  .MemberProfile-dataEditable {
    width: 100%;
  }

  .MemberProfile-submission {
    font-weight: 500;
  }

  .expandable:hover {
    z-index: 100;

    .avatar {
      background-color: $primary-color;
      color: $constrast-light-color;
    }
  }

  .expandable.expanded {
    z-index: 100;
    margin-bottom: 10px;

    .avatar {
      background-color: $close-color;
      color: $constrast-light-color;
    }
  }
}

#MemberProfile-email {
  @include flex-row();
  min-height: 40px;
  padding: 0px 0px 0px 15px;
  margin-bottom: 20px;

  .MemberProfile-dataDisplay {
    @include small-device {
      overflow: hidden;
    }
  }

  .email-message {
    display: block;
    font-weight: 500;
    font-size: 0.9em;
    margin-top: 10px;
  }
}

#MemberProfile-data {
  @include mui-no-panel-decoration();
  width: 70%;
  padding: 20px 80px 40px 80px;
  box-sizing: border-box;
  background-color: $offset-background-color;

  @include medium-device {
    width: 100%;
    padding: 20px 40px 40px 40px;
    margin-bottom: 30px;
  }

  @include medium-device {
    padding: 20px 15px;
  }
  
  h2 {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

#MemberProfilePwdChange {
  text-align: center;

  .FormWrapper {
    max-width: 500px;

    h1 {
      font-family: $heading-font;
      font-size: 1.25em;
    }
  }
}
