
.RestrictedContentPrompt {
  text-align: center;

  .content {
    margin: 0 auto;
    max-width: 700px;
  }

  h3, h4 {
    font-family: $primary-font;
    margin-bottom: 10px;
    font-weight: 500;
  }

  h4 {
    font-size: 1.2em;
    text-transform: none;
  }

  p {
    font-weight: 400;
    font-size: 1.05em;
  }

  .identify {
    @include flex-row();
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 60px;
    border: 1px solid $light-border-color;
    max-width: 375px;

    .browse-id-field {
      flex: 1;
      padding-left: 15px;
    }

    input {
      font-weight: 400;
      color: $constrast-text-color;
    }
  }

  .pitch {
    margin: 0 auto;
    padding: 20px;
    background-color: $light-bg-color;

    p {
      font-size: 1em;
    }
  }

  .actions {
    margin-top: 20px;
  }
}