
.CourseRouter {
  .nav-home {
    display: none;
    margin-bottom: 35px;
    text-align: left;

    @include medium-device {
      display: block;
    }

    button {
      padding: 0px;
      color: $link-color;

      .Icon {
        margin-right: 7px;
      }
    }
  }
}

.Course {
  text-align: center;
  margin-bottom: 60px;

  .scroll-point, .scroll-btn {
    display: none;

    @include medium-device {
      display: block;
    }
  }

  .scroll-btn {
    button {
      margin-bottom: 55px;
      font-size: 0.85em;

      .Icon {
        margin-right: 10px;
      }
    }
  }
}

.Course-main.not-auth {
  .status-message {
    display: none;
  }
}

.CourseLogin, .MagdalaOnlyPrompt, .CourseRegistration-init {
  @include flex-row();
  align-items: center;
  border-left: 5px solid $primary-color;
  padding: 10px 15px;
  margin-top: 65px;
  margin-bottom: 65px;

  @include small-device {
    display: block;
  }

  .message-content {
    margin-right: 40px;

    @include small-device {
      margin-right: 0px;
      margin-bottom: 20px;
    }

    h4 {
      margin-bottom: 7px;
      text-transform: none;
      font-size: 1.1em;
    }

    p {
      font-weight: 400;
    }

    .highlighted {
      color: $info-color;
    }
  
    .magdala-signup-prompt {
      display: inline-block;
      padding: 10px 10px 5px 0;
      color: $disabled-text-color;
      font-size: 0.9em;

      p {
        margin-top: 10px;
        font-weight: 225;
      }

        a, span {
          text-decoration: underline;
          color: $primary-contrast;
          cursor: pointer;
        }
    }

  }
}

.CourseRegistration-started {
  border-left: 5px solid $primary-color;
  padding: 10px 15px;
  margin-top: 65px;
  margin-bottom: 65px;

  .message {
    font-weight: 400;
    font-size: 1em;
    margin-right: 30px;
    color: $darker-text-color;
    margin-bottom: 15px;
  }

  .CourseRegistration-form {
    @include flex-row();

    @include small-device {
      display: block;
    }

    .tracks {
      @include flex-row();
    }

    .MuiIconButton-root.Mui-checked {
      color: $primary-color;
    }
  
    .MuiSvgIcon-root {
      width: 0.75em;
      height: 0.75em;
    }
    
    .MuiTypography-root {
      font-size: 0.95em;
      font-weight: 400;
    }

    button {
      margin-left: 20px;

      @include small-device {
        margin-left: 0px;
        margin-top: 10px;
      }
    }
  }

  .CourseRegistration-help {
    button {
      padding: 0px;
      margin-top: 15px;
      font-size: 0.95em;
      text-transform: none;
      font-weight: 400;
      text-decoration: underline;
      color: $link-color;
    }
  }

  .CourseRegistration-info {
    margin-top: 15px;
    
    p {
      margin-bottom: 15px;
    }

    strong {
      font-weight: 500;
    }
  }
}

.Course-divider {
  display: none;

  @include medium-device {
    display: block;
    text-align: center;
    margin-top: 15px;
  }

  hr {
    width: 100%;
    border: 1px solid $light-divider-color;
  }
}

.CourseTopic-not-available, .CourseAssessment-not-available {
  p {
    font-weight: 500;
    font-size: 1.1em;
  }
}

.Course-content, .CourseTopic-content, .CourseAssessment-content {
  @include flex-row();

  @include medium-device {
    display: block;
  }

  .Course-main, .CourseTopic-main, .CourseAssessment-main {
    text-align: left;
    margin-right: 100px;
    flex: 1;

    @include medium-device {
      margin-right: 0px;
    }

    .completed-margin {
      margin-top: -44px; 
    }
    .incomplete-margin {
      margin-top: 0;
    }
  }

  .Course-sidebar {
    text-align: left;
    width: 325px;

    @include large-device {
      width: 275px;
    }

    @include medium-device {
      width: 100%;
      margin-top: 55px;
    }
  }
}

.CourseStatus {
  margin-top: 65px;

  .CourseStatus-message {
    margin-bottom: 15px;

    .status-text {
      color: $primary-color;
      font-size: 1.05em;
      font-weight: 500;
    }

    .Icon {
      font-size: 0.9em;
      color: #1976d2;

      @include medium-device {
        display: none;
      }
    }
  }

  .CourseStatus-message.not-started {
    .status-text {
      font-size: 1.1em;
      margin-bottom: 20px;
    }
  }

  .CourseStatus-progress {
    height: 10px;
    position: relative;
    margin-bottom: 35px;

    .progress {
      height: 10px;
      background-color: $primary-color;
      position: absolute;
    }

    .background {
      width: 100%;
      height: 10px;
      background-color: $light-border-color;
      position: absolute;
    }
  }

  .CourseStatus-complete {
    @include flex-row();
    align-items: center;
    border-left: 5px solid $primary-color;
    padding: 10px 15px;
    margin-bottom: 65px;

    @include small-device {
      display: block;
    }

    .message-content {
      margin-right: 40px;

      @include small-device {
        margin-right: 0px;
        margin-bottom: 20px;
      }

      h4 {
        margin-bottom: 7px;
        text-transform: none;
        font-size: 1.1em;
      }
  
      p {
        font-weight: 400;
      }
    }

    .Icon {
      margin-right: 5px;
    }
  }
}

.CourseHeading {
  margin-bottom: 30px;

  .CourseHeading-container {
    @include flex-row();

    @include small-device {
      display: block;
    }
  }

  .CourseHeading-feature-container {
    @include small-device {
      @include flex-row();
      margin-bottom: 45px;
    }
  }

  .CourseHeading-feature {
    @include flex-col();
    width: 175px;
    height: 175px;
    margin-right: 20px;
    background-repeat: no-repeat;
    background-size: 100% auto;

    @include small-device {
      max-height: 145px;
    }

    .actions {
      height: 25px;
      width: 100%;
      flex: 1;
      position: relative;

      .favorite {
        font-size: 1.8em;
        position: absolute;
        top: 7px;
        right: 10px;
        cursor: pointer;
      }

      .favorite.not-favorite {
        color: $overlay-light-color;
        opacity: 0.7;
      }

      .favorite.is-favorite {
        color: $secondary-color;

        svg {
          path.fa-secondary {
            color: $overlay-light-color;
            opacity: 0.6;
          }
        }
      }
    }

    .info-bar {
      @include flex-row();
      background-color: $transparent-overlay;
      color: $constrast-light-color;
      align-self: flex-end;
      width: 100%;
      font-size: 90%;
      font-weight: 500;
      text-align: right;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;

      @include tablet-device {
        display: none;
      }

      .type {
        flex: 1;
        padding: 3px 5px;
        font-weight: 500;
      }
    }
  }

  h1 {
    font-weight: 500;
    margin-bottom: 3px;
    color: $primary-color;
  }

  h2 {
    margin-bottom: 15px;
    text-transform: none;
  }

  .highlights {
    @include flex-row();
    margin-top: 20px;
    padding-left: 3px;

    @include large-device {
      font-size: 0.95em;
    }

    @include medium-device {
      font-size: 1em;
    }

    .item {
      display: inline-block;
      margin-right: 20px;
      font-weight: 400;
    }

    .Icon {
      margin-right: 3px;
    }

    .lesson-info {
      cursor: default;

      .Icon.info {
        font-size: 0.9em;
        color: $info-color;

        @include medium-device {
          display: none;
        }
      }
    }
  }

  .status-message {
    @include flex-row();
    margin-top: 10px;
    align-items: center;
    color: $info-color;

    .Icon {
      font-size: 18px;
      width: 20px;
      height: 20px;
      padding: 0px;
      margin-left: 3px;
      margin-right: 5px;
    }

    p {
      font-weight: 500;
    }
  }

  .status-message.icon-none p {
    margin-left: 2px;
    margin-top: 10px;
  }
}

.CourseNextAction, .CourseJoinLive {
  @include flex-row();
  margin-bottom: 65px;
  border-left: 5px solid $primary-color;
  padding: 10px 15px;
  align-items: center;

  @include small-device {
    display: block;
  }

  .next-content {
    margin-right: 40px;

    @include small-device {
      margin-right: 0px;
      margin-bottom: 20px;
    }

    h4 {
      margin-bottom: 7px;
      text-transform: none;
      font-size: 1.1em;
    }

    p {
      font-weight: 400;
    }

    .highlighted {
      color: $info-color;
    }
  }

  .next-action {
    @include small-device {
      margin-top: 20px;
    }
  }

  .waiting {
    font-weight: 400;
    padding: 5px;
    color: $info-color;
    border: 1px solid $info-color;
    border-radius: 3px;
  }
}

.CourseDescription {
  h3 {
    margin-bottom: 10px;
    font-size: 1.3em;
    text-transform: none;
  }

  .summary {
    margin-bottom: 45px;

    p {
      margin-bottom: 10px;
    }
  }

  .instructors {
    @include flex-col();
  }

  .instructor {
    @include flex-row();

    .thumbnail {
      height: 75px;
      width: 75px;
      margin-right: 20px;
    }

    img {
      height: 75px;
      width: 75px;
    }

    h4 {
      margin-bottom: 10px;
      font-family: $primary-font;
      text-transform: none;
    }

    .bio {
      margin-bottom: 10px;
    }

    .more-link {
      font-weight: 400;
    }

    .links {
      margin-top: 5px;

      button {
        padding: 0px;
        margin-right: 10px;

        @include small-device {
          display: block;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.CourseLists {
  margin-bottom: 65px;
  margin-top: 120px;

  .list-heading {
    @include flex-row();
    align-items: center;

    .Icon {
      font-size: 1.1em;
      height: 20px;
      width: 20px;
      margin-right: 10px;
    }

    h5 {
      flex: 1;
      font-size: 1.1em;
      font-family: $primary-font;
      text-transform: none;
    }
  }

  ul {
    margin-left: 0px;
    padding-left: 0px;
    list-style: none;
    margin-bottom: 45px;

    li {
      margin-bottom: 10px;
      font-weight: 400;
    }
  }
}

.CourseNavigation {
  hr {
    width: 100%;
    border: 1px solid $light-divider-color;
  }
  
  .nav-actions {
    @include flex-row();
    justify-content: space-between;
    margin-top: 15px;
  }

  .nav-action {
    width: 45%;
    
    .nav-title {
      font-weight: 400;
    }
  }

  .nav-action.next {
    text-align: right;
  }

  button {
    margin-bottom: 10px;
    font-size: 0.8em;
  }
}

.Course .spacer {
  height: 25px;
}

.CourseOutline {
  .CourseOutline-heading {
    @include flex-row();
    margin-bottom: 15px;

    h3 {
      text-transform: none;
      font-size: 1.3em;
      flex: 1;
    }

    button {
      font-size: 0.8em;
      text-transform: none;
      padding: 3px 8px;
    }
  }

  .CourseOutline-list {
    padding-left: 20px;
    margin-left: 5px;
    margin-top: 25px;
    border-left: 1px solid $med-border-color;
  }

  .lesson, .exam {
    cursor: pointer;
    margin-bottom: 10px;

    .lesson-heading, .exam-heading {
      @include flex-row();
    }

    .Icon {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-left: -28px;
      margin-right: 15px;
      margin-top: 1px;
      background-color: $constrast-light-color;
    }

    h4 {
      font-family: $primary-font;
      text-transform: none;
      flex: 1;
      
      .title1 {
        font-weight: 500;
      }

      .title2, .title3 {
        font-weight: 300;
      }
    }

    h4:hover {
      color: $secondary-color;
    }
  }

  .exam .title1.selected {
    color: $secondary-color;
  }

  .parts {
    padding-top: 10px;
    padding-left: 30px;

    .part {
      @include flex-row();
    }

    .Icon {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 5px;
      margin-top: 1px;
    }

    .Icon.selected {
      color: $secondary-color;
    }

    .selected {
      font-weight: 500;
      color: $primary-color;
      margin-bottom: 7px;
    }

    a, span {
      display: block;
      margin-bottom: 7px;
      font-weight: 400;
    }

    span {
      color: $disabled-text-color;
    }

    .opening {
      font-size: 0.9em;
      margin-bottom: 7px;
      font-weight: 300;
    }
  }
}

.CourseHelp {
  margin-top: 55px;

  h3 {
    margin-bottom: 10px;
    font-size: 1.3em;
    text-transform: none;
  }

  button {
    padding: 0px;
    margin-top: 20px;
    color: $link-color;
  }
}