
.AnnualReport-section.worldwide-family {
  background-image: url('https://instituteofcatholicculture.net/wp-content/uploads/2022/11/ar-gold-decoration.png');
  background-repeat: repeat-y;
  background-size: 300px;

  @include medium-device {
    background-size: 250px;
  }

  @include tablet-device {
    background-size: 200px;
  }

  @include phone-device {
    background: none;
  }
}

.AnnualReport-section.worldwide-family .AnnualReport-content {
  margin-top: -70px;
  padding-top: 70px;
  padding-bottom: 190px;

  .SectionHeader {
    h3 {
      color: $ar-gold;
    }
  }

  .scroll-list {
    position: relative;
    background: linear-gradient(200deg, #eee4cc, $ar-gold);

    @include small-device {
      height: 500px;
    }

    .ScrollingBackground-container {
      height: 600px;

      @include small-device {
        height: 500px;
      }
    }

    .countries-container {
      @include flex-row();

      @include small-device {
        height: 500px;
      }
    }
  
    .countries-list {
      width: 300px;

      @include medium-device {
        width: 250px;
      }

      @include small-device {
        position: absolute;
        top: 0;
        left: 0;
      }

      ul.countries li {
        @include medium-device {
          padding-left: 20px;
        }
      }
    }

    .arrow {
      flex: 1;
      margin-top: 100px;
      overflow: hidden;

      @include medium-device {
        margin-top: 90px;
      }

      @include small-device {
        display: none;
      }

      img {
        height: 10px;
      }
    }
  
    .countries {
      list-style-type: none;
      padding: 0px;
      margin: 0px;
    
      li {
        font-family: $primary-font;
        font-size: 1.3em;
        font-weight: 400;
        color: $constrast-light-color;
        margin-bottom: 20px;
        padding-left: 50px;

        @include small-device {
          opacity: 0.7;
        }
      }
    }
  
    .countries-highlights {
      @include flex-col();
      justify-content: space-between;
      width: 22%;
      padding: 30px;
      padding-top: 55px;

      @include medium-device {
        width: 27%;
      }

      @include small-device {
        width: 100%;
        height: 100%;
        text-align: right;
        padding-top: 0px;
      }

      .NumericFigure {
        @include small-device {
          height: 30%;
        }
      }

      .NumericFigure-container {
        background-color: transparent;
      }
  
      .NumericFigure-inner {
        height: auto;
        width: auto;
        text-align: right;
        align-items: flex-end;
      }

      .number {
        font-family: $alt-heading-font;
        font-weight: 500;
        font-size: 5em;

        @include medium-device {
          font-size: 4em;
        }

        @include small-device {
          color: $ar-dark-blue;
        }
      }

      .description {
        font-weight: 500;
        font-size: 1.2em;

        @include small-device {
          color: $ar-dark-blue;
          max-width: 60%;
        }
      }
    }
  }

  .MapHighlights {
    margin-top: 75px;

    .large-display {
      @include tablet-device {
        display: none;
      }
    }

    .small-display {
      display: none;

      @include tablet-device {
        display: block;
      }
    }
  }

  .MapHighlights-title {
    font-family: $alt-heading-font;
    font-weight: 600;
    font-size: 1.6em;
    margin-bottom: 10px;
  }

  .MapHighlights-navigator {
    .StoryNavigator-nav {
      .title {
        background-color: $ar-light-gold;

        p {
          @include small-phone-device {
            font-size: 1em;
          }
        }
      }
  
      .active .title {
        background-color: $ar-gold;
      }
  
      .nav-item:hover .title {
        background-color: $ar-gold;
      }
    }
  
    .StoryNavigator-cards {
      background-color: $ar-vlight-gold;
  
      h5 {
        color: $ar-dark-blue;
        font-size: 1.45em;
  
        @include tablet-device {
          font-size: 1.2em;
        }
      }
    }

    .StoryCard {
      p {
        font-weight: 400;
      }
    }
  }

  .GeoMap {
    overflow-x: hidden;
    
    h4 {
      font-family: $alt-heading-font;
      font-weight: 600;
      font-size: 1.6em;
      margin-bottom: 10px;
    }

    .GeoMap-svg {
      @include medium-device {
        width: 100%;
        height: inherit;
        aspect-ratio: 1.5;
      }
    }
  }
}

/*
Interstitial
*/

.AnnualReport-section.interstitial.worldwide {
  background-color: $ar-gold;
  @include angle('before', false, 2.0deg);
  @include angle('after', false, 2.0deg);
  z-index: 10;
  padding-bottom: 20px;

  .Quote {
    padding-top: 40px;
    padding-bottom: 20px;
  }
}