.Magdala-Program {
    text-align: center;
  
  .program-cards-intro {
    margin-top: 70px;
  }

    .section-header {
      margin-bottom: 20px;
      letter-spacing: 2px;
      max-width: $free-floating-max-width;
      margin: 0 auto;
      text-align: center;
    }
  
    p.section-description {
      font-size: 1.2em;
      font-weight: 400;
      margin-bottom: 60px;
      max-width: $free-floating-max-width;
      margin: 0 auto;
      text-align: center;
    }

    div.section-description {
      font-size: 1.2em;
      font-weight: 400;
      margin-bottom: 60px;
      max-width: $free-floating-max-width;
      margin: 0 auto;
      text-align: center;
    }
    
    div.section-description-no-divider {
      font-size: 1.2em;
      font-weight: 400;
      max-width: $free-floating-max-width;
      margin: 25px auto 60px;
      text-align: center;
    }
  
    .description {
      margin-bottom: 60px;
    }
  
    .divider-bar {
      margin: 0 auto;
      width: 100px;
      margin-top: 20px;
      margin-bottom: 20px;
      border-bottom: 2px solid $primary-light-color;
    }
  
    .container {
      padding-left: 100px;
      padding-right: 100px;
      text-align: center;
  
      @include large-device {
        padding-left: 60px;
        padding-right: 60px;
      }
  
      @include desktop-device {
        padding-left: 25px;
        padding-right: 25px;
      }
  
      @include phone-device { 
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  
    .container-content {
      max-width: 1240px;
      margin: 0 auto;
      text-align: left;
    }
}

.Magdala-Program-Cards .culture {
    @include flex-col();
    margin-bottom: 60px;
    padding-top: 50px;
  
    .cards {
      @include flex-row();
      justify-content: space-between;
      text-align: center;
      width: 900px;
      margin: 0 auto;
      margin-bottom: 20px;
  
      @include medium-device {
        width: 700px;
      }
  
      @include tablet-device {
        flex-direction: column;
        justify-content: center;
        width: 100%;
      }
    }
  
    .card {
      max-width: 500px;
      cursor: pointer;
  
      @include tablet-device {
        margin: 0 auto;
        margin-bottom: 60px;
      }
    }
  
    .feature-image {
      @include flex-col();
      justify-content: flex-end;
      background-color: $default-feature-bg;
      background-repeat: no-repeat;
      background-size: 100% auto;
      height: 150px;
      width: 250px;
      margin: 0 auto;
      margin-bottom: 10px;
      border-radius: 5px;
  
      @include medium-device() {
        width: 215px;
        height: 125px;
      }
  
      @include tablet-device() {
        height: 150px;
        width: 275px;
      }
    }
  
    .title {
      text-align: center;
      font-size: 1em;
      color: $primary-color;
      margin-bottom: 10px;
    }
    
    .label {
      text-align: center;
      font-size: 1em;
      color: $primary-color;
      margin-bottom: 10px;
      margin-top: -40px;
      color: white;
      background-color: rgba(0,0,0,0.4);
      padding: 4px 0
    }
}

.accordion-flex-container {
    margin-top: 70px; 
    margin-bottom: 45px;
    display: flex; 
    flex-direction: column;
    align-items: center;

    .accordion-container {
        margin-bottom: 50px;
        width: 80%;
    }
    
    .MuiAccordionSummary-root {
        background-color: $primary-alt-color; 
        color: white; 
        border-top: 1px solid white; 
    }

    .MuiAccordionSummary-content {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1.2em;
    }

    .MuiAccordionDetails-root {
        color: $primary-dark; 
        font-weight: 400;
        text-align: left;
    }

    .accordion-icon {
        color: white;
    }
}

.Magdala-Program-Contact-Us {
    padding: 0px 30px 40px 30px;
    margin-bottom: 60px;

    .container-content {
      text-align: center;
    }
  
    button {
      color: white;
      background-color: $primary-alt-color;
      border-color: $constrast-light-color;
      margin: 30px 0;
    }
    
    button:hover {
      background-color: $primary-alt-hover;
      border-color: $constrast-light-color;
    }
  
}