
.Share {
  text-align: center;
  
  .error-heading {
    .Icon {
      margin: 0px;
      margin-right: 20px;

      svg {
        color: $secondary-color;
      }
    }
  }

  .error-message {
    margin-top: 30px;
    font-size: 2em;
    font-weight: 300;
  }
}