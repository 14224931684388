
.Pilgrimage-container {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;

  p {
    margin-bottom: 20px;
  }
}

.Pilgrimage-content {
  text-align: left;
}

.Pilgrimage-heading {
  text-align: center;

  h2 {
    margin-bottom: 10px;
  }

  h3 {
    margin-bottom: 20px;
  }

  .status {
    font-weight: 400;
    color: $info-color;
  }
}

.Pilgrimage-trip-details {
  margin-bottom: 60px;

  .MuiAccordionSummary-content {
    font-weight: 500;
  }
}

