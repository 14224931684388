.AnnualReport-section.heading {
  background-color: $ar-dark-blue;
  @include angle('after', true, 2.0deg);
}

.AnnualReport-section.heading .AnnualReport-content {
  padding-top: 55px;
  margin-bottom: 100px;

  @include breakpoint(480) {
    margin-bottom: 70px;
  }

  .branding {
    text-align: right;
    cursor: pointer;

    img {
      width: 150px;

      @include medium-device {
        width: 125px;
      }
    }
  }

  .title {
    font-family: $alt-heading-font;
    font-style: italic;
    font-size: 5.5em;
    color: $constrast-light-color;
    text-transform: none;

    @include desktop-device {
      font-size: 5em;
    }

    @include medium-device {
      font-size: 4em;
    }

    @include small-device {
      font-size: 3.2em;
    }

    @include phone-device {
      font-size: 3em;
    }

    @include breakpoint(400) {
      font-size: 2.7em;
    }

    @include breakpoint(360) {
      font-size: 2.5em;
    }

    @include small-phone-device {
      font-size: 2.3em;
    }

    .line {
      @include flex-row();
      align-items: center;
      justify-content: right;
      margin-top: -1em;
    }

    .text {
      display: inline-block;
      margin-right: 40px;
    }

    .emphasis {
      display: inline-block;
      font-size: 2.5em;
      font-style: normal;
      color: $ar-gold;
    }
  }

  .subsection {
    @include flex-row();

    .col1 {
      flex: 1;
    }

    .col2 {
      width: 40%;
      text-align: right;
    }

    .welcome-video {
      margin-top: 45px;

      .VideoPopupCard {
        width: 90%;

        @include breakpoint(480) {
          width: 100%;
        }
      }

      .VideoPopupCard-image {
        margin-bottom: 30px;
    
        .Icon {
          font-size: 4em;
        }
      }
    }

    .subtitle {
      margin-top: 40px;
      font-family: $primary-font;
      color: $constrast-light-color;
      font-weight: 300;
      font-size: 2.2em;

      @include medium-device {
        font-size: 1.7em;
      }

      @include breakpoint(480) {
        margin-top: 10px;
        margin-bottom: 80px;
        text-align: center;
      }

      .emphasis {
        font-weight: 500;
      }
    }

    .logo-branding {
      margin-top: 70px;
      
      img {
        width: 88%;
        height: auto;
        margin-bottom: -75px;
      }
    }
  }

  .large-display {
    @include breakpoint(480) {
      display: none;
    }
  }

  .small-display {
    display: none;

    @include breakpoint(480) {
      display: block;
      padding-bottom: 50px;
    }
  }
}