
.AppHeader-secondaryNavMenu.not-authenticated {
  margin: 0px;

  button.login {
    background-color: $primary-light-alt;
  }

  button.login:hover {
    background-color: $primary-light-alt;
  }

  .AppHeader-menuButton {
    button {
      background-color: $tertiary-light-color;
      color: $heading-color;

      @include tablet-device {
        padding: 9px;
        margin-top: 4px;
        margin-bottom: 5px;
      }
    }
  }
}

.AppHeader-navigation.mobile {
  display: none;

  @include desktop-device { 
    display: block;
  }
}

.AppHeader-navigationMenu.menu-mobile {
  .MuiMenuItem-root {
    line-height: inherit;
  }

  .MuiListItemIcon-root {
    min-width: inherit;
    margin-right: 10px;
  }

  .MuiListItemText-root {
    span {
      font-size: 0.9em;
    }

    a {
      color: inherit;
      text-decoration: none;
    }
  }
}