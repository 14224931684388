
.AnnualReport-section.board {
  background-image: url('https://instituteofcatholicculture.net/wp-content/uploads/2022/11/ar-light-blue-decoration.png');
  background-repeat: repeat-y;
  background-size: 300px;

  @include medium-device {
    background-size: 250px;
  }

  @include tablet-device {
    background-size: 200px;
  }

  @include phone-device {
    background: none;
  }
}

.AnnualReport-section.board .AnnualReport-content {
  margin-top: -5px;
  padding-top: 90px;
  padding-bottom: 200px;

  .LeadershipContent {
    h3 {
      text-align: left;
      font-family: $alt-heading-font;
      font-size: 1.8em;
      font-weight: 600;
      margin-bottom: 30px;
      color: $primary-color;
    }
  }

  .Leadership-directors {
    display: grid;
    grid-template-columns: 22% 22% 22% 22%;
    grid-gap: 4%;
    justify-content: inherit;

    @include large-device {
      grid-gap: 2%;
    }

    @include medium-device {
      grid-template-columns: 32% 32% 32%;
      grid-gap: 2%;
    }

    @include tablet-device {
      grid-template-columns: 47% 47%;
      grid-gap: 2%;
    }

    @include small-device {
      display: block;
    }

    .director {
      padding: 0px;
      margin-top: -20px;
      width: 100%;

      @include large-device {
        margin-top: 0px;
      }
    }
  }
}