
.Retreat-container {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;

  p {
    margin-bottom: 20px;
  }
}

.Retreat-content {
  text-align: left;

  strong {
    font-weight: 500;
  }
}

.Retreat-heading {
  text-align: center;

  h2 {
    margin-bottom: 10px;
  }

  h3 {
    margin-bottom: 20px;
  }

  .status {
    font-weight: 400;
    color: $info-color;
  }

  .registration {
    margin-top: 25px;
    margin-bottom: 55px;
  }
}

.Retreat-trip-details {
  margin-bottom: 60px;

  .MuiAccordionSummary-content {
    font-weight: 500;
  }
}

