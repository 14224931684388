
.AccountDeletion {
  text-align: center;

  h1, h3 {
    font-family: $primary-font;
    font-size: 1.25em;
    margin-bottom: 25px;
  }

  .AccountDeletion-content, .AccountConfirmation-content, .AccountDeletion-completed, .AccountDeletion-confirmed {
    margin: 0 auto;
    max-width: 700px;
    text-align: left;
  }

  .AccountConfirmation-email {
    margin-top: 25px;
  }

  .AccountConfirmation-submit {
    margin-top: 35px;
    text-align: center;
  }

  .AccountConfirmation-error {
    margin-top: 25px;
    padding: 10px;
    background-color: $error-color;
    color: $constrast-light-color;
    font-weight: 400;
  }

  .AccountDeletion-completed {
    p {
      font-size: 1.1em;
      font-weight: 400;
    }
  }

  .AccountDeletion-confirmed {
    p {
      font-size: 1.1em;
      font-weight: 500;
    }
  }

  .AccountDeletion-message {
    margin-bottom: 25px;
  }

  .AccountDeletion-action {
    text-align: center;
  }

  .AccountDeletion-submit {
    margin-top: 20px;
  }
}