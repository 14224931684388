
.PopMessage {
  > div {
    @include flex-row();
    text-align: left;
  }

  .MuiSnackbarContent-message {
    flex: 1;

    .actions {
      margin-top: 20px;
      font-weight: 600;
      font-size: 0.9em;
    }

    Button {
      color: $constrast-light-color;
      border-color: $constrast-light-color;
      padding: 2px 10px;
      font-size: 0.9em;
      box-shadow: none;
      border-radius: 2px;
    }
  }

  .MuiSnackbarContent-action {
    width: 52px;

    > button {
      color: $constrast-light-color;
      position: absolute;
      top: 2px;
      right: 2px;
    }
  }
}

.PopMessage.info {
  > div {
    background-color: $info-color;
  }
}

.PopMessage.success {
  > div {
    background-color: $success-color;
  }
}

.PopMessage.warning {
  > div {
    background-color: $warning-color;
  }
}

.PopMessage.error {
  > div {
    background-color: $error-color;
  }
}