
/*
This file contains styles that will be made available for content admins to use from WordPress
*/

.Page.Medium {
  max-width: 1000px;
  margin: 0 auto;
}

.Page.Narrow {
  max-width: 700px;
  margin: 0 auto;
}

.wp-block-columns {
  display: flex;
  justify-content: space-between;

  @include tablet-device {
    display: block;
  }
}

.wp-block-columns.two-columns {
  .wp-block-column {
    width: 48%;

    @include tablet-device {
      width: 100%;
      margin-bottom: 60px;
    }
  }
}

.wp-block-columns.three-columns {
  .wp-block-column {
    width: 32%;

    @include tablet-device {
      width: 100%;
      margin-bottom: 60px;
    }
  }
}

.iccwp-center, .has-text-align-center, .aligncenter {
  text-align: center;
}

.has-text-align-right, .alignright {
  text-align: right;
}

.wp-block-image img {
  max-width: 100%;
  height: auto;
}

.wp-block-image figure.alignright {
  float: right;
  margin-left: 15px;
  margin-bottom: 15px;
}

.wp-block-image figure.alignleft {
  float: left;
  margin-right: 15px;
  margin-bottom: 15px;
}

.has-medium-font-size {
  font-size: 1.25em
}

.has-large-font-size {
  font-size: 2em;
}

hr.iccwp-divider {
  margin:0 auto; 
  border-top: 3px solid $primary-light-color;
  max-width: 500px;

  @include small-device {
    max-width: inherit;
    width: 70%;
  }
}

.iccwp-video {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;

  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.iccwp-button {
  padding: 14px 20px;
  background-color: $primary-color;
  color: $constrast-light-color;
  text-transform: uppercase;
  font-size: 1em;
  text-decoration: none;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: $primary-font;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
}

.iccwp-button:hover {
  background-color: $primary-alt-color;
}

.iccwp-quote {
  max-width: 70%;
  margin: 0 auto;
  margin-top: 45px;
  margin-bottom: 45px;
  font-size: 1.1em;
  font-weight: 400;
  font-style: italic;
  font-family: $heading-font;
}