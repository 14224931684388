
.MyList-none {
  margin-top: 20px;
  margin-bottom: 80px;
  text-align: center;

  h2 {
    font-size: 1.4em;
    letter-spacing: 2px;
    color: $primary-color;
    text-align: center;
    margin-bottom: 40px;
  }

  p {
    font-size: 1.2em;
    font-weight: 400;
  }
}