
.LearnMore {
  text-align: center;
  margin-top: 80px;
  padding-bottom: 80px;
  background-color: $primary-color;

  @include phone-device {
    margin-top: 50px;
  }

  img.logo {
    width: 380px;

    @include phone-device {
      width: 80%;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    text-transform: none;
    margin-bottom: 15px;
  }

  h1, h2 {
    font-size: 1.5em;
  }

  p {
    margin-bottom: 20px;
  }

  .LoadingMessage {
    color: $constrast-light-color;
  }

  .learn-content {
    max-width: 700px;
    margin: 0 auto;
    margin-top: 50px;
    text-align: left;
    background-color: $constrast-light-color;
    padding: 50px;

    @include phone-device {
      .wp-block-image figure {
        float: none;
        text-align: center;
        margin: 0px;
        margin-bottom: 20px;
      }
    }
  }
}

.LearnSignupForm {
  .email, .name {
    label {
      font-size: 1rem;
      color: #545454;
    }

    input {
      font-size: 1rem;
      font-weight: 400;
    }
  }

  .name {
    @include flex-row();
    justify-content: space-between;

    @include small-device {
      display: block;
    }

    > div {
      width: 49%;

      @include small-device {
        width: 100%;
      }
    }
  }

  .privacy {
    margin-top: 15px;
    font-size: 0.9em;
  }

  .error-message {
    margin-top: 15px;
    font-weight: 400;
    color: $error-color;
  }
}

.LearnMoreConfirmation {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;

  p.thanks {
    font-size: 1.8em;
    font-weight: 400;
  }

  p.message {
    margin-top: 30px;
    font-size: 1.2em;
    font-weight: 300;
    text-align: left;
  }

  p.signature {
    margin-top: 30px;
    font-size: 1.2em;
    font-weight: 500;
    text-align: left;
  }
}