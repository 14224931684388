$ar-gold: $primary-light-alt;
$ar-light-gold: $primary-light-color;
$ar-vlight-gold: #f9f4e8;
$ar-green: $complement-color;
$ar-light-green: $complement-alt;
$ar-very-light-green: $complement-light;
$ar-light-blue: $primary-contrast;
$ar-medium-blue: #26436a;
$ar-red: $secondary-color;
$ar-dark-blue: $primary-color;
$ar-tan: $tertiary-color;
$ar-gray: #898179;
$ar-light-gray: #ddd9d4;
$ar-vlight-blue: #dee5ee;
$ar-light-red: #eadada;
$ar-dark-tan: #898179;
$ar-medium-tan: #d4cbb6;
$ar-light-tan: #f9f4e8;

.AnnualReport {
  .LoadingMessage {
    margin-top: 50px;
  }
}


.AnnualReport-content {
  margin: 0 auto;
  max-width: 1024px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: left;
}

.AnnualReport-section {
  text-align: center;

  .SectionHeader {
    h3 {
      font-family: $alt-heading-font;
      font-size: 4em;
      font-style: italic;
      text-transform: none;
      margin-bottom: 15px;

      .emphasis {
        font-style: normal;
        text-transform: uppercase;
        font-weight: 600;
      }

      @include tablet-device {
        font-size: 3em;
      }

      @include breakpoint(375) {
        font-size: 2.5em;
      }
    }

    p {
      width: 70%;
      font-weight: 300;
      margin-bottom: 50px;

      @include tablet-device {
        width: 100%;
      }
    }
  }
}