
.EventDetail {
  text-align: left;

  .EventDetail-event-application-status {
    display: inline-block;
    padding: 10px;
    margin-bottom: 35px;
    background-color: $success-color;
    color: $constrast-light-color;
    font-weight: 500;
  
    p {
      margin-top: 10px;
  
      a, span {
        text-decoration: underline;
        color: $constrast-light-color;
        font-weight: 500;
        cursor: pointer;
      }
    }

    .max-line-width {
      max-width: 625px;
    }
  }
}

.EventDetail-heading {
  margin-bottom: 30px;

  .heading {
    @include flex-row();
  }

  .Feature-container {
    @include flex-col();
    width: 175px;
    height: 175px;
    margin-right: 20px;
    background-repeat: no-repeat;
    background-size: 100% auto;

    @include small-device {
      max-height: 145px;
    }

    .actions {
      height: 25px;
      width: 100%;
      flex: 1;
      position: relative;

      .favorite {
        font-size: 1.8em;
        position: absolute;
        top: 7px;
        right: 10px;
        cursor: pointer;
      }

      .favorite.not-favorite {
        color: $overlay-light-color;
        opacity: 0.7;
      }

      .favorite.is-favorite {
        color: $secondary-color;

        svg {
          path.fa-secondary {
            color: $overlay-light-color;
            opacity: 0.6;
          }
        }
      }
    }

    .info-bar {
      @include flex-row();
      background-color: $transparent-overlay;
      color: $constrast-light-color;
      align-self: flex-end;
      width: 100%;
      font-size: 90%;
      font-weight: 500;
      text-align: right;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;

      @include tablet-device {
        display: none;
      }

      .type {
        flex: 1;
        padding: 3px 5px;
        font-weight: 500;
      }
    }
  }

  h1 {
    font-weight: 500;
    margin-bottom: 3px;
    color: $primary-color;
  }

  h2 {
    margin-bottom: 15px;
    text-transform: none;
  }

  .special-event-status {
    margin-top: 30px;
    font-weight: 500;
    color: $info-color;
  }

  .registration-container {
    margin-top: 45px;
    background-color: $light-bg-color;
    
    @include tablet-device {
      background-color: inherit;
    }
  }
}

.EventResourcesPopup {
  ul.resources {
    max-width: 500px;
    min-width: 300px;
    list-style: none;
    padding: 0;
    margin: 0;
    padding-bottom: 20px;

    li {
      margin-bottom: 10px;
    }

    a {
      text-decoration: none;
    }
  }
}

.EventDetail-sharing {
  margin-bottom: 25px;

  @include small-device {
    text-align: center;
  }

  .content {
    @include flex-row();
    align-items: center;
    margin-bottom: 20px;

    @include tablet-device {
      @include flex-col();
      align-items: flex-start;
    }

    button {
      padding: 0px 10px;
      font-size: 0.95em;
      margin-right: 40px;

      @include tablet-device {
        margin-right: 0px;
        margin-bottom: 20px;
      }
    
      svg {
        font-size: 1em;
        margin-right: 5px;
      }
    }

    .categories {

      strong {
        display: inline-block;
        font-weight: 500;
        margin-right: 10px;
      }
    }
  }

  .share-actions {
    padding: 20px;
    max-width: 700px;

    h4 {
      margin-bottom: 20px;
    }
  }
  
  .share-actions .buttons {
    margin-bottom: 40px;

    button {
      margin-right: 20px;
      font-size: 0.9em;

      @include small-device {
        display: block;
        width: 80%;
        margin: 0 auto;
        margin-bottom: 15px;
      }
    }

    .Icon {
      margin-right: 7px;
    }
  }

  .share-event-link {
    margin-bottom: 45px;

    h4 {
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 10px;
      overflow-wrap: break-word;
      font-weight: 400;
    }

    button {
      color: $darker-text-color;
      font-weight: 400;
      font-size: 0.85em;

      .Icon {
        margin-right: 10px;
      }
    }
  }

  .Form {
    text-align: center;

    .name-fields {
      @include flex-row();
      justify-content: space-between;
      margin-top: 20px;

      @include small-device {
        display: block;
      }

      > div {
        width: 49%;

        @include small-device {
          width: 100%;
        }
      }
    }
  }
}

.EventDetail-participation {
  margin-bottom: 45px;
  font-weight: 400;

  h3 {
    margin-bottom: 10px;
    font-size: 1.3em;
    text-transform: none;
  }

  p {
    font-weight: 400;
  }

  .participation-item {
    @include flex-row();
    margin-bottom: 10px;

    .Icon {
      width: 25px;
    }
  }

  .time-display {
    font-weight: 300;
  }

  .where {
    .link-display {
      display: block;
      font-size: 0.9em;
      padding: 0px;
      margin-top: 5px;
    }
  }

  .location {
    margin-top: 5px;
    background-color: $offset-background-color;
    padding: 10px;
    font-size: 0.9em;

    .room {
      font-weight: 500;
    }

    .maps-url {
      margin-top: 3px;
      font-size: 0.95em;
    }
  }

  .action {
    padding: 4px 16px;
    font-size: 0.9em;
    font-weight: 400;
    text-transform: inherit;
    background-color: $dark-button-color;
    color: $constrast-light-color;
    margin-top: 15px;
  }

  .action:hover {
    background-color: $dark-button-hover;
  }
}

.EventDetail-description {
  h3 {
    margin-bottom: 10px;
    font-size: 1.3em;
    text-transform: none;
  }

  .summary {
    margin-bottom: 45px;

    p {
      margin-bottom: 10px;
    }
  }

  .instructors {
    @include flex-col();
  }

  .instructor {
    @include flex-row();
    margin-bottom: 20px;

    .thumbnail {
      height: 75px;
      width: 75px;
      margin-right: 20px;
    }

    img {
      height: 75px;
      width: 75px;
    }

    h4 {
      margin-bottom: 10px;
      font-family: $primary-font;
      text-transform: none;
    }

    .bio {
      margin-bottom: 10px;
    }

    .links {
      margin-top: 5px;

      button {
        padding: 0px;
        margin-right: 10px;

        @include small-device {
          display: block;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.EventList-container.recommendations {
  margin-top: 70px;

  @include small-device {
    margin-top: 50px;
  }

  h2 {
    font-size: 1.1em;
  }

  .more-link {
    display: none;
  }

  .EventList-row.row-1, .EventList-row.row-2 {
    display: none;
  }

  .EventList-row.size-xsm.row-1, .EventList-row.size-xsm.row-2 {
    display: block;
  }
}
