
.AnnualReport-section.domestic-church.top {
  background-image: url('https://instituteofcatholicculture.net/wp-content/uploads/2022/11/ar-red-decoration.png');
  background-repeat: repeat-y;
  background-size: 300px;

  @include medium-device {
    background-size: 250px;
  }

  @include tablet-device {
    background-size: 200px;
  }

  @include phone-device {
    background: none;
  }
}

.AnnualReport-section.domestic-church.top .AnnualReport-content {
  margin-top: -105px;
  padding-top: 80px;
  padding-bottom: 20px;

  @include large-device {
    margin-top: -115px;
  }

  @include medium-device {
    margin-top: -125px;
  }

  @include small-device {
    margin-top: -135px;
  }

  .SectionHeader {
    margin-bottom: 70px;

    @include medium-device {
      margin-bottom: 60px;
    }

    h3 {
      color: $ar-red;
    }
  }

  .video-container1 {
    margin-bottom: 80px;

    .VideoStoryCard-story .title {
      color: $ar-red;
    }
  }
}

.AnnualReport-section.domestic-church.bottom-carousel {
  background-color: $ar-light-red;
  margin-bottom: 190px;
  padding-top: 50px;
  padding-bottom: 50px;

  h3 {
    font-family: $alt-heading-font;
    font-weight: 600;
    font-size: 1.6em;
    color: $darker-text-color
  }

  .nav-prev, .nav-next {
    .nav-button-bg {
      background-color: $constrast-light-color;
    }

    .Icon {
      color: $ar-red;
    }
  }

  .BasicCarouselCard-container {
    h5 {
      font-weight: 500;
      color: $ar-red;
    }

    p {
      font-weight: 400;
    }
  }
}

/*
Interstitial
*/ 

.AnnualReport-section.interstitial.domestic-church {
  background-color: $ar-red;
  @include angle('before', true, 2.0deg);
  @include angle('after', true, 2.0deg);
  z-index: 10;
}

.AnnualReport-section.interstitial.domestic-church .AnnualReport-content .container {
  padding-bottom: 40px;

  .Quote {
    padding-top: 70px;
  }
}