$primary-font:    'Roboto', sans-serif;
$heading-font:  'PT Serif', serif;

/*
Note: Alt font can only be used on a page where it has been loaded. For performance reasons it is
not loaded by default. If used and not loaded it should fall back on the default heading font.
*/
$alt-heading-font:  'EB Garamond', 'PT Serif', serif;

$h1-default-size: 1.5em;
$h2-default-size: 1.25em;
$h3-default-size: 1.10em;

$free-floating-max-width:   750px;

$primary-color:       #102B4E;
$primary-dark:        #041934;
$primary-alt-color:   #26436A;
$primary-alt-hover:   #3E4F7D;
$primary-light-bg:    #DEE5EE;
$primary-light-color: #E1C585;
$primary-vlight-color:#f9f4e8;
$primary-light-alt:   #D0932C;
$magdala-pri-legacy:  #2e6d77;
$primary-contrast:    #466388;
$secondary-color:     #98272b;
$secondary-hover:     #CB583E;
$tertiary-color:      #D4CBB6;
$tertiary-light-color:#F9F4E8;
$complement-color:    #477366;
$complement-alt:      #7b9989;
$complement-light:    #c9d8cc;
$app-bar-color:       $primary-color;
$heading-color:       #3C3C3C;
$info-color:          #1976d2;
$success-color:       #43A047;
$warning-color:       #FFA000;
$error-color:         #D32F2F;
$notification-color:  #FFBC35;
$promo-color:         #FFBC35;
$close-color:         $secondary-color;
$link-color:          #2498D4;
$success-text-color:  #E3FFE5;
$error-text-color:    #FFFFFF;
$base-text-color:     #545454;
$darker-text-color:   #3C3C3C;
$disabled-text-color:   #9C9C9C;
$pitch-bg-color:          #466388;
$constrast-text-color:      #000000;
$constrast-light-color:     #FFFFFF;
$participation-bg-color:    #3e4f7d;
$invisible-background:      #FFFFFF;
$offset-background-color:   #F1F1F1;
$med-border-color:          #545454;
$light-border-color:        #D0D0D0;
$light-bg-color:            #EFEFEF;
$light-divider-color:       #dedede;
$form-offset-color:         #E1C585;
$form-offset-input-color:   #FBF2DB;
$header-menu-hover:         #FFEABC;
$header-menu-icon:          $primary-contrast;
$header-menu-icon-hover:    #6B83A2;
$header-menu-icon-bg:       $primary-alt-color;
$section-heading-header:    #E1C585;
$section-heading:           #FBF2DB;
$primary-color-overlay:     rgba(77, 131, 200, 0.55);
$transparent-overlay:       rgba(0, 0, 0, 0.6);
$overlay-color:             #000000;  
$overlay-light-color:       #FFFFFF; 
$progress-color:            #FF0000;
$dark-button-color:         #3C3C3C;
$dark-button-hover:         #545454;    
$default-feature-bg:        #CECECE;
$facebook-color:            #3f5992;      
$google-color:              #d05642;


$breakpoint-large: 1200px;
$breakpoint-desktop: 1023px;
$breakpoint-medium: 959px;
$breakpoint-tablet: 767px;
$breakpoint-small: 600px;
$breakpoint-phone: 420px;
$breakpoint-small-phone: 350px;