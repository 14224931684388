
.App.hide-toolbar, .App.page-courses-application, .App.display-content-only {
  .AppToolbar {
    display: none !important;
  }
}

.AppToolbar {
  display: none;
  margin: 0 auto;
  padding: 40px 100px 10px;
  max-width: 1240px;
  text-align: left;

  @include large-device { 
    padding: 40px 60px 10px;
  }

  @include desktop-device {
    padding: 40px 25px 10px;
  }

  @include small-device { 
    padding: 20px 15px 10px
  }

  > div {
    height: 27px;
  }
}

.AppToolbar.explore-tools {
  @include flex-row();
}

.AppToolbar-mainNav {
  @include flex-row();
  flex: 1;

  @include small-device { 
    display: none;
  }

  .menu-item {
    margin-top: 2px;
    padding-bottom: 5px;

    a {
      text-decoration: none;
      color: $base-text-color;
      font-weight: 500;
    }
  }

  .menu-item.active {
    a {
      color: $secondary-color;
    }
  }

  .vertical-divider {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.AppToolbar-actions {
  width: 75px;
  text-align: right;

  @include tablet-device { 
    display: none;
  }

  .menu-item {
    button {
      margin-top: -4px;
      font-size: 1em;
      text-transform: none;
      padding: 3px 10px;
    }
  }
}