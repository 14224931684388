.AnnualReport-section.finances.top {
  background-color: $ar-light-tan;
  @include angle('after', true, 2.0deg);
  z-index: 1;
}

.AnnualReport-section.finances.top .AnnualReport-content {
  padding-top: 100px;

  @include medium-device {
    padding-top: 80px;
  }

  .SectionHeader {
    margin-bottom: 60px;

    h3 {
      color: $ar-dark-tan;
    }
  }

  h4.sub-head1 {
    font-family: $alt-heading-font;
    font-size: 1.8em;
    font-weight: 600;
    margin-bottom: 30px;
    color: $primary-color;
  }

  .stat-cards {
    @include flex-row();
    justify-content: space-between;
    padding-bottom: 50px;

    @include tablet-device {
      flex-direction: column;
      align-items: center;
    }

    .NumericFigure {
      width: 30%;

      @include tablet-device {
        width: 50%;
        margin-bottom: 40px;
      }

      @include small-device {
        width: 90%;
      }

      @include phone-device {
        width: 100%;
      }

      .NumericFigure-inner {
        width: 100%;
        aspect-ratio: 1.7;
        height: auto;

        @include small-device {
          aspect-ratio: inherit;
          height: 209px;
        }
      }

      .number {
        font-family: $alt-heading-font;
        font-weight: 500;
        font-size: 5.5em;
        margin-top: -20px;

        @include medium-device {
          font-size: 4em;
        }

        @include tablet-device {
          font-size: 5.5em;
        }
      }
    }
  }
}

.AnnualReport-section.finances.bottom  {
  background-image: url('https://instituteofcatholicculture.net/wp-content/uploads/2022/11/ar-gold-decoration.png');
  background-repeat: repeat-y;
  background-size: 300px;

  @include medium-device {
    background-size: 250px;
  }

  @include tablet-device {
    background-size: 200px;
  }

  @include phone-device {
    background: none;
  }
}

.AnnualReport-section.finances.bottom .AnnualReport-content {
  padding-top: 100px;
  padding-bottom: 120px;

  h4.sub-head1, h4.sub-head3 {
    font-family: $alt-heading-font;
    font-size: 1.8em;
    font-weight: 600;
    margin-bottom: 30px;
    color: $primary-color;
  }

  h4.sub-head2 {
    font-family: $alt-heading-font;
    font-size: 4.5em;
    font-weight: 400;
    font-style: italic;
    text-transform: none;
    margin-bottom: 30px;
    color: $primary-color;

    @include tablet-device {
      font-size: 4em;
    }
  }

  p.sub-head2-desc {
    width: 100%;
    margin-bottom: 40px;
  }
  
  .PieChart {
    margin-bottom: 100px;

    .PieChart-container {
      @include flex-row();
      align-items: center;
      justify-content: space-between;

      @include tablet-device {
        flex-direction: column;
        justify-content: inherit;
      }
    }

    .PieChart-svg {
      @include tablet-device {
        width: 60%;
        margin-bottom: 50px;
      }

      @include small-device {
        width: 70%;
      }
    }
  
    .PieChart-legend {
      @include tablet-device {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      @include small-device {
        display: block;
        justify-content: inherit;
        width: 70%;
      }

      li {
        padding-top: 4px;
        padding-bottom: 4px;

        @include tablet-device {
          width: 47%;
        }

        @include small-device {
          width: 100%;
        }
      }

      .percentage {
        display: inline-block;
        font-family: $alt-heading-font;
        font-size: 2em;
        font-weight: 600;
        min-width: 70px;
        margin-bottom: 4px;

        @include desktop-device {
          font-size: 1.7em;
        }

        @include medium-device {
          font-size: 1.5em;
        }

        @include tablet-device {
          min-width: 50px;
        }
      }

      .color {
        height: 32px;
        min-width: 35px;
        margin-right: 15px;

        @include medium-device {
          height: 27px;
          min-width: 30px;
        }

        @include tablet-device {
          margin-right: 10px;
        }
      }

      .label {
        font-size: 1.4em;

        @include desktop-device {
          font-size: 1.25em;
        }

        @include tablet-device {
          font-size: 1.05em;
        }
      }
    }
  }

  .ImageQuoteCard {
    margin-bottom: 80px;

    .ImageQuoteCard-content {
      border-color: $ar-medium-tan;
    }

    svg {
      .cls-1 {
        fill: $ar-medium-tan;
      } 
    }

    .Quote {
      .text {
        font-family: $alt-heading-font;
        font-size: 1.3em;
        color: $ar-dark-blue;
      }

      .name {
        font-size: 0.95em;
        color: $ar-dark-blue;
      }
    }
  }

  .sub-head-special-gift {
    font-family: $alt-heading-font;
    font-size: 1.8em;
    color: $ar-dark-blue;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .special-gift-opportunities {
    @include flex-row();
    justify-content: space-between;
    margin-bottom: 50px;

    .special-gift {
      @include flex-col();
      align-items: center;
      width: 18%;

      @include tablet-device {
        width: 22.5%;
      }

      @include small-device {
        width: 30%;
      }

      .amount {
        font-family: $alt-heading-font;
        font-size: 2.2em;
        color: $ar-dark-blue;
        font-weight: 500;
        margin-bottom: 10px;
      }

      .description {
        font-weight: 500;
        font-size: 1.05em;
        text-align: center;
      }
    }

    .special-gift.col4 {
      @include small-device {
        display: none;
      }
    }

    .special-gift.col5 {
      @include tablet-device {
        display: none;
      }
    }
  }

  .accordion-container {
    margin-bottom: 50px;

    .MuiAccordionSummary-root {
      background-color: $ar-medium-tan;
      border-bottom: 2px solid $constrast-light-color;
    }

    .MuiAccordionSummary-content {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1.2em;
    }
  }
}

/*
Interstitial
*/

.AnnualReport-section.interstitial.finances {
  background-color: $ar-light-tan;
  @include angle('before', true, 2.0deg);
  @include angle('after', true, 2.0deg);
}

.AnnualReport-section.interstitial.finances .AnnualReport-content .container {
  .Quote {
    .icon {
      svg {
        path {
          fill: $ar-dark-tan;
        }
      }
    }

    .text, .name {
      color: $ar-dark-tan;
    }
  }

  button {
    border-color: $ar-dark-tan;
    color: $ar-dark-tan;
  }
}