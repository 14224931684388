
.StickyBottomMenu {
  position: sticky;
  z-index: 900;
  bottom: 10px;
  text-align: right;

  .menu-anchor {
    @include flex-row();
    align-items: center;

    button#StickyBottomMenu-button {
      display: block;
    }

    button#StickyBottomMenu-icon-button {
      display: none;
    }

    @include medium-device {
      button#StickyBottomMenu-button {
        display: none;
      }
  
      button#StickyBottomMenu-icon-button {
        display: block;
      }
    }
  }

  .menu-anchor.icon-only {
    button#StickyBottomMenu-button {
      display: none;
    }

    button#StickyBottomMenu-icon-button {
      display: block;
    }
  }

  .menu-anchor.full-button {
    .Icon {
      margin-left: 7px;

      @include medium-device {
        margin-left: inherit;
      }
    }
  }

  button {
    margin-left: 15px;
    box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.15);
  }

  .icon-container {
    height: 28px;
    width: 28px;
  }
}

.StickyBottomMenu-layout-container {
  margin: 0 auto;
  max-width: 1024px;
  padding-left: 15px;
  padding-right: 15px;
}

.StickyBottomMenu-container {
  display: inline-block;
  margin: 0 auto;
}