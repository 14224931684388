
/*
Top Navigation
*/

.AnnualReport-section.welcome {
  .AnnualReport-navigation {
    max-width: 270px;
    margin-right: 10px;
    margin-top: 50px;

    @include medium-device {
      max-width: 60%;
      margin-right: 0px;
      margin-top: 60px;
      margin-bottom: 30px;
    }

    @include small-device {
      max-width: 80%;
    }

    @include phone-device {
      max-width: 100%;
    }

    h3 {
      font-family: $alt-heading-font;
      text-transform: none;
      font-style: italic;
      font-weight: 600;
      font-size: 1.4em;
      margin-bottom: 5px;

      @include medium-device {
        font-size: 1.8em;
      }
    }
  }

  .AnnualReport-navigation .navigation-menu {
    margin-left: 20px;
    margin-top: 0px;
    padding-left: 0px;
    list-style: none;
    max-width: 250px;

    @include medium-device {
      margin-left: 30px;
      max-width: 100%;
    }

    li {
      @include flex-row();
      align-items: center;
      cursor: pointer;
      height: 40px;
      padding-top: 3px;
      padding-bottom: 3px;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 0.95em;

      @include medium-device {
        height: 2.2em;
        font-size: 1.15em;
      }

      @include small-device {
        height: 2.4em;
      }

      @include small-phone-device {
        height: 2.7em;
        font-size: 0.9em;
      }

      span.bar {
        display: inline-block;
        width: 5px;
        min-width: 5px;
        height: 35px;
        margin-right: 10px;
      }
    }

    li.gold {
      span.bar {
        background-color: $ar-gold;
      }
    }

    li.gold:hover {
      background-color: $ar-gold;
      color: $constrast-light-color;
    }
  
    li.green {
      span.bar {
        background-color: $ar-green;
      }
    }

    li.green:hover {
      background-color: $ar-green;
      color: $constrast-light-color;
    }

    li.light-blue {
      span.bar {
        background-color: $ar-light-blue;
      }
    }

    li.light-blue:hover {
      background-color: $ar-light-blue;
      color: $constrast-light-color;
    }
  
    li.red {
      span.bar {
        background-color: $ar-red;
      }
    }
  
    li.red:hover {
      background-color: $ar-red;
      color: $constrast-light-color;
    }
  
    li.dark-blue {
      span.bar {
        background-color: $ar-dark-blue;
      }
    }
  
    li.dark-blue:hover {
      background-color: $ar-dark-blue;
      color: $constrast-light-color;
    }
  
    li.tan {
      span.bar {
        background-color: $ar-tan;
      }
    }
  
    li.tan:hover {
      background-color: $ar-tan;
      color: $constrast-light-color;
    }

    li.gray {
      span.bar {
        background-color: $ar-gray;
      }
    }
  
    li.gray:hover {
      background-color: $ar-gray;
      color: $constrast-light-color;
    }
  }
}


/*
Bottom Navigation
*/

.StickyBottomMenu.AnnualReport-bottom-nav {
  .menu-title {
    color: $primary-color;
  }

  button {
    background-color: $primary-color;
    color: $constrast-light-color;
    border: 1px solid $constrast-light-color;
  }
}

.AnnualReport-bottom-nav.visible {
  display: block;
}

.AnnualReport-bottom-nav.hidden {
  display: none;
}

.StickyBottomMenu-menu-AnnualReport-bottom-nav {
  .MuiMenu-paper {
    max-width: 250px;
  }

  .MuiMenuItem-root {
    text-transform: uppercase;
    font-size: 0.9em;
    overflow: auto;
    overflow-wrap: break-word;
    white-space: inherit;
    height: 50px;
    padding-top: 3px;
    padding-bottom: 3px;

    > div {
      @include flex-row();
      align-items: center;
    }

    span.bar {
      display: inline-block;
      width: 5px;
      min-width: 5px;
      height: 35px;
      margin-right: 10px;
    }
  }

  .MuiMenuItem-root.gold {
    span.bar {
      background-color: $ar-gold;
    }
  }

  .MuiMenuItem-root.gold:hover {
    background-color: $ar-gold;
    color: $constrast-light-color;
  }

  .MuiMenuItem-root.green {
    span.bar {
      background-color: $ar-green;
    }
  }

  .MuiMenuItem-root.green:hover {
    background-color: $ar-green;
    color: $constrast-light-color;
  }

  .MuiMenuItem-root.light-blue {
    span.bar {
      background-color: $ar-light-blue;
    }
  }

  .MuiMenuItem-root.light-blue:hover {
    background-color: $ar-light-blue;
    color: $constrast-light-color;
  }

  .MuiMenuItem-root.red {
    span.bar {
      background-color: $ar-red;
    }
  }

  .MuiMenuItem-root.red:hover {
    background-color: $ar-red;
    color: $constrast-light-color;
  }

  .MuiMenuItem-root.dark-blue {
    span.bar {
      background-color: $ar-dark-blue;
    }
  }

  .MuiMenuItem-root.dark-blue:hover {
    background-color: $ar-dark-blue;
    color: $constrast-light-color;
  }

  .MuiMenuItem-root.tan {
    span.bar {
      background-color: $ar-tan;
    }
  }

  .MuiMenuItem-root.tan:hover {
    background-color: $ar-tan;
    color: $constrast-light-color;
  }

  .MuiMenuItem-root.gray {
    span.bar {
      background-color: $ar-gray;
    }
  }

  .MuiMenuItem-root.gray:hover {
    background-color: $ar-gray;
    color: $constrast-light-color;
  }
}
