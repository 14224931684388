
.GospelReflections {
  @include flex-row();

  @include tablet-device {
    flex-direction: column;
  }
}

.GospelReflections-heading {
  width: 300px;
  padding-right: 50px;
  text-align: left;

  @include tablet-device {
    width: 100%;
    margin-bottom: 40px;
    padding-right: 0px;
  }

  .feature {
    width: 100%;
    margin-bottom: 30px;

    @include tablet-device {
      width: 30%;
      float: left;
      margin-right: 15px;
      margin-bottom: 0px;
    }
  }

  img {
    width: 100%;
  }

  .series-info {
    flex: 1;
  }

  .series-nav {
    margin-top: 20px;

    button {
      font-size: 0.9em;
      padding: 0px;
      text-align: left;
    }
  }
}

.GospelReflections-content {
  text-align: left;
  max-width: 880px;

  h2 {
    margin-bottom: 20px;
  }

  h3 {
    font-family: $primary-font;
    font-size: 1.1em;
    font-weight: 500;
    text-transform: none;
    margin-bottom: 10px;
  }

  .MuiSelect-select {
    min-width: 130px !important;
    font-weight: 400;
    font-size: 0.95em;
    color: $secondary-color;
  }

  .reflection {
    h4 {
      margin-bottom: 5px;
    }
  }

  .actions {
    @include flex-row();
    margin-top: 10px;

    button {
      margin-right: 20px;
      font-size: 0.9em;
      padding: 3px 10px;

      .Icon {
        margin-right: 7px;
      }
    }
  }

  .links {
    margin-top: 60px;

    a {
      font-weight: 400;
    }
  }
}

.GospelReflections-content.single {
  .reflection {
    margin-bottom: 30px;
  }

  .actions {
    margin-top: 30px;
  }
}

.GospelReflections-content.list {
  .reflection {
    margin-top: 40px;
    margin-bottom: 60px;
  }
}