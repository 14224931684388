
.Filter {

  .limit-error {
    margin-bottom: 30px;
    padding: 15px;
    background-color: $warning-color;
    color: $constrast-text-color;
    font-weight: 500;
    font-size: 1.1em;
  }

  .Filter-toolbar {
    margin-bottom: 50px;
    border-bottom: 1px solid $light-border-color;
    padding-bottom: 20px;

    h1 {
      font-size: 1.4em;
      margin-bottom: 10px;
      font-weight: 500;
    }

    h2 {
      font-size: 1.1em;
      font-family: $primary-font;
      text-transform: none;
      margin-bottom: 5px;
    }

    .selected-filters {
      > button {
        margin-top: 20px;
        padding: 3px 20px;
        margin-right: 10px;
        font-size: 0.95em;
      }

      button.search {
        background-color: $dark-button-color;
      }
    }

    .filters {
      > div {
        margin-right: 10px;
        margin-bottom: 7px;
      }
    }

    .results {
      button.search {
        display: none;
      }
    }
  }

  .Filter-list {
    @include flex-row();

    @include desktop-device { 
      flex-wrap: wrap;
    }

    .col1 {
      @include desktop-device { 
        width: 40%;
      }

      @include tablet-device { 
        width: 50%;
      }

      @include small-device { 
        width: 100%;
      }
    }

    .col2 {
      @include desktop-device { 
        width: 35%;
      }

      @include tablet-device { 
        width: 35%;
      }

      @include small-device { 
        width: 100%;
      }
    }

    .col3 {
      @include desktop-device { 
        width: 100%;
      }
    }
  }

  .filter-group {
    margin-bottom: 40px;

    h3 {
      font-size: 1.1em;
      font-weight: 500;
      margin-bottom: 10px;
      color: $primary-color;
    }

    .content {
      width: 250px;

      @include small-device { 
        width: 100%;
      }

      .item {
        height: 32px;
        font-size: 0.95em;
        font-weight: 400;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
      }
    }
  }

  .filter-group.tag {
    .content {
      @include flex-row();
      flex-wrap: wrap;
      width: inherit;
      max-width: 550px;

      @include desktop-device { 
        max-width: 100%;
      }

      .item {
        width: 275px;

        @include desktop-device { 
          width: 40%;
        }

        @include tablet-device { 
          width: 50%;
        }

        @include small-device { 
          width: 100%;
        }
      }
    }
  }

  div.MuiChip-root {
    color: $constrast-light-color;
    height: 28px;
    cursor: inherit;

    span {
      font-weight: 500;
      font-size: 0.95em;
    }

    svg {
      font-size: 1.6em;
      color: $constrast-light-color;
    }
  }

  div.MuiChip-root.type {
    background-color: #1F968F !important;
  }

  div.MuiChip-root.date {
    background-color: #448FB9 !important;
  }

  div.MuiChip-root.category {
    background-color: #A43899 !important;
  }

  div.MuiChip-root.tag {
    background-color: #d0932c !important;
  }

  .EventList-container {
    h2 {
      margin-bottom: 30px;
    }
  }
}