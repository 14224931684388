.Icon {
  display: inline-block;
  margin: 0 auto;

  .size-2x svg.Icon-mui {
    width: 2em;
    height: 2em;
  }

  .size-3x svg.Icon-mui {
    width: 3em;
    height: 3em;
  }

  .avatar {
    background-color: $primary-color;
    color: $constrast-light-color;
  }

  .color-secondary .avatar {
    background-color: $secondary-color;
  }
}
.Icon.inline {
  margin: 0px;
}
.Icon.spacing-padded {
  margin-right: 10px;
}
.Icon.spacing-block {
  min-width: 50px;
}