
.PageBanner {
  height: 300px;
  position: relative;
  overflow: hidden;

  @include small-device {
    height: 260px;
  }

  @include phone-device {
    height: 200px;
  }

  .feature img {
    width: 100%;
    position: relative;
    float: left;
    z-index: 10;
    overflow: hidden;
    left: 0;

    @include large-device {
      width: 120%;
      left: -10%;
    }

    @include desktop-device {
      width: 140%;
      left: -20%;
    }

    @include medium-device {
      width: 160%;
      left: -30%;
    }

    @include tablet-device {
      width: 200%;
      left: -50%;
    }

    @include small-device {
      width: 260%;
      left: -80%;
    }
  }

  .tagline {
    z-index: 100;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);

    h1, h2 {
      text-transform: inherit;
    }

    h1 {
      color: $constrast-light-color;
      font-size: 2.3em;
      font-weight: 500;
      margin-top: 120px;
      font-family: $heading-font;

      @include large-device {
        font-size: 2em;
      }

      @include desktop-device {
        font-size: 1.7em;
      }

      @include small-device {
        margin-top: 100px;
        font-size: 1.3em;
      }

      @include phone-device {
        margin-top: 90px;
      }
    }

    h2 {
      color: $constrast-light-color;
      font-size: 1.6em;
      font-weight: 400;
      font-style: italic;
      margin-top: 5px;
      font-family: $heading-font;

      @include large-device {
        margin-left: 60px;
      }

      @include desktop-device {
        font-size: 1.2em;
        margin-left: 25px;
      }

      @include medium-device {
        margin-left: 0px;
        font-size: 1.1em;
      }

      @include small-device {
        margin-left: 0px;
        padding: 0px 5px;
        font-size: 1em;
      }
    }
  }
}

.PageBanner.hidden {
  display: none;
}