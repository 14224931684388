
.StandardSystemPopup {
  h2.title {
    @include flex-row();
    align-items: center;
    font-size: 1.3em;

    span {
      display: inline-block;
      flex: 1;
      text-transform: none;
    }

    button {
      width: 40px;
      height: 40px;
      padding: 7px;
      font-size: inherit;

      span {
        display: inherit;
      }

      svg {
        font-size: 1.55rem;
      }
    }
  }

  .content-main {
    margin-bottom: 30px;

    h2 {
      text-transform: none;
      margin-bottom: 10px;
    }

    .type-standard h2 {
      display: none;
    }

    .action {
      display: none;
    }
  }

  .content-actions {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    padding: 3px 15px;
    border-top: 1px solid $light-border-color;

    button {
      margin: 0 auto;
      color: $primary-color;
    }
  }

  .MediaContent {
    h2 {
      margin-top: 20px;
      margin-bottom: 10px;
    }

    .media {
      min-width: 400px;

      @include small-device {
        min-width: inherit;
      }
    }
  }
}

.StandardSystemPopup.no-title {
  .title {
    padding: 0px;
  }
}


.FullPageSystemPopup {

  .MuiPaper-root {
    background-color: rgba(16, 43, 78, 0.9);
    text-align: center;
  }

  .content {
    height: 100%;

    @include medium-device {
      height: inherit;
    }
  }

  .content-heading {
    height: 47px;
    text-align: right;

    .close-icon {
      display: inline-block;
      cursor: pointer;
    }

    .Icon {
      color: $constrast-light-color;
      font-size: 2.5em;
    }
  }

  .content-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    @include medium-device {
      display: block;
      height: inherit;
    }
  }

  .content-main {
    margin: 0 auto;
    margin-top: -47px;
    max-width: 1000px;

    @include medium-device {
      margin: 0 auto;
      margin-top: 30px;
    }

    h1, h2, h3, h4, h5, p {
      color: $constrast-light-color;
    }

    p {
      font-weight: 400;
    }

    .type-standard {
      h2 {
        margin-bottom: 30px;
        font-size: 1.8em;
      }

      p {
        margin-bottom: 20px;
        font-size: 1.2em;
      }
    }
  }

  .content-actions, .action {
    margin-top: 30px;

    button.default {
      color: $constrast-light-color;
      border: 1px solid $constrast-light-color;
    }

    button.link {
      color: $constrast-light-color;
      background-color: $primary-light-alt;
      padding: 8px 20px;
    }
  }
}

.StickySystemPopup {
  position: fixed;
  z-index: 9999;
  bottom: 10px;
  right: 20px;
  height: 400px;
  width: 300px;
  box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.15);
  background-color: $constrast-light-color;

  @include small-device {
    display: none;
  }

  .close {
    text-align: right;
    height: 26px;
    margin-top: -26px;

    @include tablet-device {
      height: 31px;
      margin-top: -31px;
    }

    button {
      box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.15);
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: inherit;
      padding: 0px;

      svg {
        color: $constrast-light-color;
        font-size: 1.1em;
	      width: 0.85em;
        height: 0.85em;
        
        @include tablet-device {
          font-size: 1.2em;
	        width: 0.95em;
          height: 0.95em;
        }
      }
    }
  }

  .content-main {
    height: 355px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: left;

    h2 {
      text-transform: none;
      margin-bottom: 10px;
    }

    p {
      font-weight: 400;
      color: $base-text-color;
      margin-bottom: 20px;
    }

    .action {
      display: none;
    }
  }

  .content-actions {
    border-top: 1px solid $light-border-color;

    button {
      font-size: 0.9em;

      .MuiButton-label {
        color: $primary-alt-color;
      }
    }
  }

  .media {
    margin-bottom: 10px;
  }
}

.FullPageSystemPopup .content.type-media {
  .MediaContent-body {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;

    @include medium-device {
      display: block;
    }
  }

  .media {
    width: 480px;

    @include medium-device {
      margin: 0 auto;
      margin-bottom: 30px;
    }

    @include small-device {
      width: 80%;
    }

    @include phone-device {
      width: 90%;
    }

    img {
      object-fit: cover;
    }
  }

  .main {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding-left: 30px;

    @include medium-device {
      width: 480px;
      margin: 0 auto;
      text-align: center;
      padding-left: 0px;
    }

    @include small-device {
      width: 80%;
    }

    @include phone-device {
      width: 90%;
    }

    .action {
      text-align: left;
      margin-top: 0px;

      @include medium-device {
        text-align: center;
      }
    }
  }

  h2 {
    color: $constrast-light-color;
    font-weight: 500;
    margin-bottom: 10px;
    font-family: $heading-font;
    font-size: 1.6em;
    text-transform: inherit;
  }

  h2.without-desc {
    margin-bottom: 25px;
  }

  p {
    color: $constrast-light-color;
    font-weight: 400;
    margin-bottom: 35px;
    font-family: $primary-font;
  }

  .content-actions {
    display: none;
  }
}

.StandardSystemPopup .DonationContent,
.StickySystemPopup .DonationContent,
.FullPageSystemPopup .DonationContent {
  .donation {
    text-align: left;
    margin-top: 30px;

    .frequency, .amount {
      margin-bottom: 10px;
    }

    .action {
      text-align: center;
    }

    h4 {
      font-size: 1em;
      font-family: $primary-font;
      text-transform: inherit;
      font-weight: 500;
      margin-bottom: 5px;
    }

    .MuiFormGroup-root {
      display: flex;
      flex-direction: row;
      margin-left: 3px;
    }

    label  {
      margin-bottom: 5px;

      .MuiButtonBase-root {
        padding: 7px;
      }

      .MuiTypography-root {
        font-size: 1em;
        font-family: $primary-font;
      }

      .MuiSvgIcon-root {
        font-size: .7em;
      }
    }

    .other-amount {
      display: block;

      label {
        margin-bottom: 0px;
      }

      .MuiFormControlLabel-root {
        margin-right: 0px;
      }

      .MuiFormControlLabel-label {
        display: none;
      }
    }

    input[type=text] {
      font-size: 0.85em;
      font-family: $primary-font;
      width: 115px;
    }
  }

  .donation.no-frequency {
    height: 170px;

    .frequency {
      display: none;
    }
  }
}

.StickySystemPopup .DonationContent {

  .MuiFormGroup-root {
    color: $darker-text-color;
  }
}

.FullPageSystemPopup .DonationContent { 
  .DonationContent-body {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;

    @include tablet-device {
      display: block;
    }
  }

  .main {
    flex: 1;
    min-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 0px;

    @include desktop-device {
      min-width: inherit;
    }

    @include tablet-device {
      padding-left: 15px;
      padding-right: 15px;
      text-align: center;
      margin: 0 auto;
      width: 80%;
    }

    @include phone-device {
      width: 90%;
    }

    h2 {
      text-transform: none;
      font-size: 1.8em;
      margin-bottom: 20px;

      @include phone-device {
        font-size: 1.5em;
      }
    }

    p {
      font-size: 1.1em;

      @include phone-device {
        font-size: 1em;
      }
    }
  }

  .donation {
    width: 290px;
    height: 240px;
    background-color: $light-bg-color;
    padding: 15px;
    margin-right: 30px;
    margin-top: 0px;
    text-align: left;

    @include tablet-device {
      margin: 0 auto;
      margin-top: 30px;
    }

    @include small-phone-device {
      width: 240px;
      height: 265px;
    }

    h4 {
      color: $darker-text-color;
    }
  }
}

.FullPageSystemPopup .content.type-donation {
  .content-body {
    margin-top: -47px;
    
    @include medium-device {
      margin-top: 0px;
    }
  }

  .content-actions {
    display: none;
  }
}

.StandardSystemPopup .type-standard.text-center, .StickySystemPopup .type-standard.text-center,
.StandardSystemPopup .type-media.text-center, .StandardSystemPopup .type-donation.text-center,
.FullPageSystemPopup .type-media.text-center, .FullPageSystemPopup .type-donation.text-center,
.StickySystemPopup .type-media.text-center, .StickySystemPopup .type-donation.text-center {
  h2, p, .main .action {
    text-align: center;
  }
}