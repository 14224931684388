
.MemberList {
  padding: 15px;
  max-width: 225px;
}

.MemberList.trial {
  .notification {
    margin-top: 20px;
  }

  hr {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .join-prompt {
    text-align: center;

    h3 {
      color: $primary-color;
      font-family: $primary-font;
      font-size: 1em;
      font-weight: 500;
      margin-bottom: 5px;
    }

    button {
      margin-top: 20px;
      width: 80%;
    }
  }
}

.MemberList-user {
  padding-bottom: 15px;

  button {
    box-shadow: none;
    width: 100%;
  }

  .name {
    margin-top: 15px;
    font-weight: 500;
  }
}

.MemberList-donate {
  display: none;
  text-align: center;

  @include desktop-device {
    display: block;
  }
}

.MemberList-profile, .MemberList-actions {
  margin: 10px 0px;

  ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;

    li {
      font-size: 1.05em;
      margin-bottom: 3px;
    }

    li.more {
      font-weight: 500;
    }
  }

  h3 {
    font-size: 1.05em;
    font-family: $primary-font;
    font-weight: 400;
    text-transform: none;
    margin-bottom: 10px;
  }

  a {
    text-decoration: none;
    font-weight: 400;
  }
}