
.ExploreList {
  padding: 10px;
}

.ExploreList-primary {

  button {
    display: block;
    width: 220px;
    margin-bottom: 15px;
    font-family: $heading-font;
    color: $constrast-light-color;
    background-color: $primary-color;
    font-size: 0.9em;
  }

  button:hover {
    background-color: $primary-alt-hover;
  }

  .menu-item {
    @include flex-row();
    width: 200px;
    height: 90px;
    background-color: #C4C4C4; /*TODO REPLACE W/IMAGE*/
    margin-bottom: 15px;
    cursor: pointer;

    .title {
      background-color: $transparent-overlay;
      color: $constrast-light-color;
      align-self: flex-end;
      width: 100%;
      padding: 3px;
      font-weight: 500;
    }
  }
}

.ExploreList-secondary {
  margin: 15px 0px;

  .search {
    display: none;

    @include tablet-device {
      display: block;
    }
  }
}

.ExploreList-categories {
  margin: 15px 0px;

  h3 {
    font-size: 1em;
    font-family: $primary-font;
    font-weight: 500;
    text-transform: none;
    margin-bottom: 10px;
  }
}

.ExploreList-secondary, .ExploreList-categories {
  ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;

    li {
      font-size: 1.05em;
      margin-bottom: 3px;
    }

    li.more a {
      color: $heading-color;
      font-weight: 500;
    }

    li.more a:hover {
      color: $secondary-color;
    }
  }

  .menu-item {
    .Icon {
      width: 20px;
      margin-right: 10px;
      text-align: center;
    }
  }

  a {
    text-decoration: none;
    font-weight: 400;
  }
}