
.EventMedia {
  margin-top: 20px;
  margin-bottom: 30px;

  .media-actions {
    .status-message {
      margin-bottom: 10px;
      color: $info-color;
      font-weight: 500;
      font-size: 1.1em;
    }

    button {
      margin-right: 10px;
      text-transform: none;
      font-weight: 400;
  
      @include small-device {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: inherit;
        width: 80%;
        margin-bottom: 10px;
        padding: 10px;
      }
  
      .Icon {
        margin: inherit;
        margin-right: 7px;
        margin-bottom: 0px;
      }
  
      .MuiButton-label {
        display: inline-block;
      }
    }
  
    button.audio, button.resources {
      border-color: $dark-button-color;
      color: $dark-button-color;
    }
  }
}

.EventQuestionEmbed {
  position: relative;

  .loading {
    text-align: center;
  }

  .responsive-iframe-container {
    position: inherit;
    height: 600px;
  }
}

.EventAccessDialog {
  .MuiDialogActions-root {
    margin-bottom: 45px;

    button {
      font-size: 0.9em;
      padding: 3px 14px;
    }
  }
}

.TrialAccessDenied {
  p {
    font-weight: 500;
    color: $info-color;
  }

  button {
    margin-top: 10px;
  }
}