
.CourseTopic {
  text-align: center;
  margin-bottom: 60px;

  .CourseNavigation {
    margin-top: 100px;
  }

  .loading {
    width: 100%;
    text-align: center;
  }

  .status-message {
    display: inline-block;
    margin-top: 5px;
    padding: 10px;
    background-color: $notification-color;
    color: $constrast-text-color;
    font-weight: 500;
  
    @include small-device {
      font-size: 0.85em;
    }
  }
}

.CourseTopic-heading {
  margin-bottom: 45px;

  h1 {
    font-weight: 500;
    margin-bottom: 3px;
    color: $primary-color;
  }

  h2 {
    margin-bottom: 15px;
    text-transform: none;
  }
}

.CourseVideo {
  .video {
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;

    iframe {
      border: 0;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.CourseTaskList {
  margin-bottom: 45px;

  .CourseTaskList-instructions {
    font-weight: 500;
    margin-bottom: 25px;
  }

  .items {
    .item {
      @include flex-row();
      margin-bottom: 5px;
    }

    .action {
      @include flex-row();
      align-items: center;
      justify-content: center;
      width: 45px;
      border: 1px solid $light-border-color;

      label {
        margin: 0 auto;
      }

      button {
        padding: 0px;
        background: none;
        min-width: 45px;
      }

      .Icon {
        font-size: 1.6em;
        padding: 0px;
        margin: 0px;
      }
    }

    .item.selected .action {
      background-color: $primary-color;
      border: 1px solid $primary-color;

      svg {
        color: $constrast-light-color;
      }
    }

    .item.not-selected .action { 
      svg {
        margin-bottom: 2px;
        color: rgba(1,1,1,0); 
        border: 1px solid rgba(16,43,78,0.7); 
        border-radius: 3px;
        height: 17px;
        width: 17px;
      }
    }

    .MuiCheckbox-colorSecondary.Mui-checked {
      color: $primary-color;
    }

    .text {
      flex: 1;
      @include flex-row();
      align-items: center;
      padding: 5px;
      padding-left: 15px;
      font-size: 1.1em;
      font-weight: 400;
      border: 1px solid $light-border-color;
      border-left: 0px;
    }
  }
}

.CourseInfo-instructions {
  padding: 10px;
  background-color: $primary-light-color;
  font-weight: 400;
}

