.FormWrapper {
  padding: 20px;
  box-sizing: border-box;
  margin: 0 auto;

  h1, h2 {
    font-size: 1.25em;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}


.Form {
  div.FormField-control,
  label.SwitchField-label {
    margin-top: 20px;
  }

  div.FormField-control.full,
  label.SwitchField-label.full,
  div.MuiFormControl-root.full {
    width: 100%;
  }

  div.FormField-control.first,
  label.SwitchField-label.first {
    margin-top: 0px;
  }

  .Form-buttons {
    margin-top: 20px;

    .Form-submitButton {
      margin-right: 10px;
    }

    .Form-submitMessage {
      margin-top: 10px;
    }
  }  
}

.MuiFormHelperText-root.FormField-error {
  color: $error-color;
}

div.MuiFormControl-root {
  margin-top: 20px;
}
