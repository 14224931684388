
/*
Map Highlights and Geo Map
*/

.GeoMap {
  text-align: center;

  h4 {
    text-align: left;
    color: $primary-color;
    font-size: 1.5em;
  }

  .country {
    fill: #c1c2c5;
    stroke: #9c9ea1;
  }

  .country.color-fill {
    fill: $primary-color;
  }

  .map-pin {
    cursor: pointer;
    background-color: red;
  }
}

.GeoMap-svg {
  margin: 0 auto;
  height: 600px;
  width: 900px;
}

.MapHighlights {
  .MapHighlights-sections {
    margin-top: 50px;

    .title {
      @include flex-row();
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: $primary-light-alt;

      h5 {
        @include flex-row();
        align-items: center;
      }

      img {
        width: 25px;
        margin-right: 15px;
      }

      span {
        display: inline-block;
        font-family: $primary-font;
        font-weight: 500;
        font-size: 1.4em;
        color: $constrast-light-color;
        text-transform: uppercase;
      }
    }

    .details {
      @include flex-row();
      align-items: flex-start;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 40px;
      padding-bottom: 40px;
      background-color: $primary-vlight-color;

      img {
        width: 300px;
        margin-right: 20px;
      }

      p {
        font-weight: 400;
      }
    }

    .section.show {
      transition: opacity 0.5s ease-in;
      opacity: 1;
      height: auto;
    }

    .section.hide {
      opacity: 0;
      height: 0;
      overflow: hidden;
    }
  }
}



/*
Number Comparison
*/

.NumberComparison {
  display: inline-block;

  .NumberComparison-title {
    text-align: center;
    margin-bottom: 10px;

    span {
      display: inline-block;
      margin: 0 auto;
      font-family: $heading-font;
      text-transform: uppercase;
      font-size: 1.5em;
      text-align: center;
      width: 170px;
    }
  }
}

.NumberComparison-container {
  .number-container {
    @include flex-col();
    justify-content: center;
    text-align: center;
    width: 220px;
    height: 180px;
    color: $constrast-light-color;
  }

  .number {
    margin-top: -15px;
  }

  .top-number {
    background-color: $primary-color;
  }

  .bottom-number {
    background-color: $primary-contrast;
  }

  .number {
    font-family: $heading-font;
    font-size: 3.75em;
  }

  .description {
    font-family: $primary-font;
    font-size: 1em;
    font-weight: 500;
    padding-left: 40px;
    padding-right: 40px;
  }

  .comparison-divider {
    margin-top: -10px;
    text-align: center;
    background-color: $primary-color;

    .arrow-up {
      margin: 0 auto;
      width: 34px;
      height: 17px;
      background-image:
        linear-gradient(to bottom right, transparent 50%, $primary-contrast 0),
        linear-gradient(to top right, $primary-contrast 50%, transparent 0);
      background-size: 50% 100%;
      background-repeat: no-repeat;
      background-position: left, right;
    }
  }
}


/*
Numeric Figure
*/

.NumericFigure {
  display: inline-block;
}

.NumericFigure-container {
  background-color: $primary-color;
  background-position: center center;
  background-repeat: no-repeat;
}

.NumericFigure-inner {
  @include flex-col();
  justify-content: center;
  text-align: center;
  width: 495px;
  height: 209px;
  color: $constrast-light-color;

  .number {
    font-family: $heading-font;
    font-size: 3em;
    margin-bottom: 5px;
  }

  .description {
    font-family: $primary-font;
    font-size: 1em;
    font-weight: 500;
  }
}

.NumericFigure-container.image-bg {
  background-position: center center;
  background-repeat: no-repeat;
}

.NumericFigure-container.image-bg .NumericFigure-inner {
  background-color: $primary-color-overlay;
}


/*
One Value Donut Chart
*/

.OneValueDonutChart {
  display: inline-block;
  background-color: $complement-color;
  padding: 20px;
  max-width: 240px;

  .OneValueDonutChart-container {
    position: relative;
  }

  h4 {
    font-family: $heading-font;
    font-size: 1.3em;
    text-transform: uppercase;
    color: $constrast-light-color;
    text-align: center;
    margin-bottom: 25px;
  }

  .description {
    text-align: center;
    margin-top: 25px;

    p {
      margin: 0 auto;
      width: 200px;
      font-family: $primary-font;
      font-size: 0.95em;
      font-weight: 500;
      color: $constrast-light-color;
    }
  }

  .OneValueDonutChart-svg {
    height: 200px;
    width: 200px;
  }

  .OneValueDonutChart-percentage {
    position: absolute;
    z-index: 1000;
    top: 113px;
    left: 47px;
    font-family: $heading-font;
    font-size: 3.5em;
    color: $constrast-light-color;
    transition: all .5s;
    opacity: 0;
  }

  .OneValueDonutChart-container.percentage-visible .OneValueDonutChart-percentage {
    opacity: 1;
  }
}


/*
Pie Chart
*/

.PieChart {
  padding: 20px;

  .PieChart-container {
    @include flex-row();
    align-items: center;
    justify-content: space-between;
  }

  h4 {
    font-family: $heading-font;
    font-size: 1.3em;
    text-transform: uppercase;
    color: $constrast-text-color;
    text-align: left;
    margin-bottom: 25px;
  }

  .PieChart-svg {
    width: 45%;
    height: auto;

    .percentage {
      font-family: $heading-font;
      font-size: 3em;
    }
  }

  .PieChart-legend {
    align-items: center;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    width: 45%;

    li {
      @include flex-row();
      align-items: center;
      padding-top: 3px;
      padding-bottom: 3px;
    }

    .percentage {
      display: inline-block;
      font-family: $heading-font;
      font-size: 1.2em;
      width: 50px;
    }

    .color {
      display: inline-block;
      height: 20px;
      width: 20px;
      margin-right: 10px;
    }

    .label {
      display: inline-block;
      font-family: $primary-font;
      font-weight: 400;
      font-size: 1.1em;
    }
  }
}
