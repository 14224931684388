
.Search {
  text-align: center;

  h2 {
    text-align: center;
    font-size: 1.4em;
    margin-bottom: 30px;

    .terms {
      color: $secondary-color;
    }
  }

  .Search-field {
    display: none;
    width: 100%;
    margin-bottom: 50px;
    padding: 2px 4px;
    align-items: center;
    background-color: $constrast-light-color;
    color: $constrast-text-color;

    @include tablet-device { 
      @include flex-row();
    }
  
    .search-field {
      flex: 1;
      margin-left: 8px;
      font-size: 1em;
      font-weight: 400;
    }

    .search-icon {
      padding: 6px;
    }
  }

  .Search-results {
    text-align: left;
  }
}